import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../base_svg-icon/component';

import './styles/style.scss';

class DefaultCheckbox extends Component {
  render() {
    const { label, id, hasError, checked, disabled, ...inputProps } = this.props;
    delete inputProps.checked; // do not pass checked as attribute to the input, since it will then become readonly, we use defaultChecked to set the value.

    return (
      <div className={`default-checkbox checkbox ${hasError ? 'checkbox--has-error' : ''}`}>
        <div className="mock-checkbox">
          <div className={`checkbox-icon ${checked ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
            <Icon name="ico-check-modern"/>
          </div>
          <input
            id={id}
            checked={checked}
            className={`checkbox__input ${hasError ? 'checkbox--has-error__input' : ''}`}
            type="checkbox"
            disabled={disabled}
            {...inputProps}
          />
        </div>

        {label && (
          <label className="checkbox__label" htmlFor={id}>{label}</label>
        )}
      </div>
    );
  }
}

DefaultCheckbox.propTypes = {
  id: PropTypes.string,
  groupId: PropTypes.string,
  checked: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default DefaultCheckbox;
