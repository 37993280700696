import React from 'react';
import PropTypes from 'prop-types';
import NavigationSection from '../../../ui_navigation-section/component';
import { MenuItemModel } from '@utils/models';
import { getDevice } from '@utils';
import './styles/styles.scss';

const Menu = ({ item, toggle, closeMobileMenu, show }) => {
  const menuItem = new MenuItemModel(item);
  const isSingleItem = ![
    ...menuItem.otherLinkLists,
    ...menuItem.mediaBlocks,
  ].filter(Boolean).length;

  const renderLinkList = (list, id) => {
    return (
      <div key={id} className={'nav-mega-menu-section left'}>
        <NavigationSection
          type="list"
          title={list.title}
          url={list.url}
          links={list.links}
          onClickEvent={closeMobileMenu}
        />
      </div>
    );
  };

  const renderMediaBlock = (mediablock, index) => {
    return (
      <div key={index} className="nav-mega-menu-section right">
        <NavigationSection
          type="media"
          title={mediablock.title}
          url={mediablock.url}
          image={mediablock.image}
          linkText={mediablock.linkText}
          onClickEvent={closeMobileMenu}
        />
      </div>
    );
  };

  const handleLeave = () => {
    const { isMobile, isTablet } = getDevice();
    if (!isMobile && !isTablet) {
      toggle(null);
    }
  };

  return (
    <div
      className={`nav-mega-menu ${show ? 'visible' : ''} ${isSingleItem ? 'single-nav-item' : ''}`}
      onMouseEnter={() => toggle(item.url)}
      onMouseLeave={handleLeave}
    >
      {!!menuItem.mainLinkList && renderLinkList(menuItem.mainLinkList, 'main')}
      {menuItem.otherLinkLists.map((list, index) => renderLinkList(list, index))}
      {menuItem.mediaBlocks.map((mediaBlock, index) => renderMediaBlock(mediaBlock, index))}
    </div>
  );
};

Menu.propTypes = {
  item: PropTypes.object,
  toggle: PropTypes.func,
  closeMobileMenu: PropTypes.func,
  show: PropTypes.bool,
};


export default Menu;
