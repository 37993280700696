import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormInput from './_form-input/component';
import { Button } from 'audamatic-ui';
import { scrollTo } from '@utils';

import './styles/style.scss';

class GenericForm extends Component {
  constructor(props) {
    super(props);

    this.wrapOnDiv = this.wrapOnDiv.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.renderSections = this.renderSections.bind(this);
    this.renderField = this.renderField.bind(this);
    this.renderComponent = this.renderComponent.bind(this);
    this.renderButton = this.renderButton.bind(this);
  }

  componentDidUpdate() {
    const newInvalidFields = Object.keys(this.props.formErrors);
    if (!!newInvalidFields.length) {
      const invalidField = newInvalidFields.find(key => !!this.props.formErrors[key]);
      if (invalidField) {
        this.scrollToInvalidField(`#${invalidField}`);
      }
    }
  }

  get updateFormDataState() {
    const emptyFunc = () => {};
    return this.props.updateFormDataState || emptyFunc;
  }

  get formErrors() {
    return this.props.formErrors || {};
  }

  /**
   * Handles the submit event on the address form and submits
   * the formData values if the form is valid
   *
   * @param {Event} e
   */
  handleSubmit(e) {
    e.preventDefault();
    if (this.validateForm()) {
      this.props.submitFormData();
    }
    this.form.parentNode.classList.add('should-validate');
  }

  /**
   * Returns true or false based on the form checkValidity method
   * Triggers Hotjar
   */
  validateForm() {
    const isValid = this.form.checkValidity();
    if (!isValid) {
      this.scrollToInvalidField('input:invalid, select:invalid');
      if (!!window.hj) {
        hj('trigger', 'formerrors');
      }
    }
    return isValid;
  }

  /**
   * Jump to the first invalid field
   * @param {String} selector
   */
  scrollToInvalidField(selector) {
    if (!this.form || !selector) {
      return false;
    }

    const invalidFieldElement = this.form.querySelector(selector);
    if (!invalidFieldElement) {
      return false;
    }
    const elmOffset = invalidFieldElement && invalidFieldElement.getBoundingClientRect();
    const elmOffsetTop = elmOffset ? elmOffset.top : 0;
    const offsetBuffer = 140;

    if (elmOffsetTop < offsetBuffer) {
      const offset = window.pageYOffset + elmOffsetTop;
      const totalOffset = offset - offsetBuffer;
      scrollTo({ top: totalOffset });
    }
  }

  /**
   * Renders a form input passing props down to FormInput sub-component
   *
   * @param {Object} field
   * @param {Integer} i
   */
  renderField(field, i) {
    const { name, className, helpText = '', sucessMessage, onKeyUp = () => { }, onKeyDown = () => { }, htmlAttributes, Component } = field;

    if (Component) {
      return Component;
    }

    return (
      <FormInput
        key={i}
        name={name}
        className={className || 'col-md-8 input-container'}
        updateFormDataState={this.updateFormDataState}
        onInvalid={this.onInvalid}
        error={this.formErrors[name]}
        helpText={helpText}
        sucessMessage={sucessMessage}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        htmlAttributes={htmlAttributes}
        {...field}
      />
    );
  }

  /**
   * Renders an embedded component
   *
   * @param {Object} child
   */
  renderComponent(child, i) {
    const { Component, componentProps = {} } = child;
    return  (
      <Component
        key={i}
        store={this.props.store || {}}
        updateFormDataState={this.updateFormDataState}
        formData={componentProps.formData || this.props.formData}
        {...this.props}
        {...componentProps}
      />
    );
  }

  /**
   * Wraps a block on div, setting to it the provided className
   *
   * @param {Component} El
   */
  wrapOnDiv(div, i, section) {
    return (
      <div key={i} className={section.className}>
        {this.renderSections(div)}
      </div>
    );
  }

  /**
   * Renders a button component
   *
   * @param {Object} button
   * @param {Integer} i
   */
  renderButton(button, i) {
    let onClickFn = () => false;
    if (typeof button.onClick === 'function') {
      onClickFn = button.onClick;
    }

    return (
      <Button
        key={i}
        variant={button.variant}
        size="large"
        type={button.type}
        iconRight={button.reverseIconDirection ? button.icon : '' }
        iconLeft={button.reverseIconDirection ? '' : button.icon}
        onClick={onClickFn}
        disabled={button.disabled}
        loading={button.loading}
        fullWidth={button.fullWidth}
        data-test-id={button.dataTestId}
      >
        {button.text}
      </Button>
    );
  }

  /**
   * Given a config object, maps the proper render function
   * based on the config block type.
   *
   * @param {Object} section
   */
  getRenderFunction(section) {
    const renderFnMap = {
      fields: this.renderField,
      components: this.renderComponent,
      buttons: this.renderButton,
      div: this.wrapOnDiv,
      link: this.renderLink,
      default: () => {},
    };
    const renderFn = section.type || 'default';
    return renderFnMap[renderFn];
  }

  /**
   * Pass through all the sections of the config object
   * and passes it to the proper render function.
   *
   * @param {Object} section
   * @param {Integer} i
   */
  renderSections(section) {
    const renderFn = this.getRenderFunction(section);
    return section.children.filter(Boolean).map((child, i) => renderFn(child, i, section));
  }


  render() {
    const { formConfig, isChild, pending } = this.props;


    return (
      !isChild ? (
        <div className="form-container">
          <form
            name={this.props.name}
            ref={el => this.form = el}
            onSubmit={this.handleSubmit}
            noValidate
            className={pending ? 'pending' : ''}
          >
            {this.props.children}
            {formConfig.map(this.renderSections)}
          </form>
        </div>
      ) : (
        <div className="fieldsContainer">
          {formConfig.map(this.renderSections)}
        </div>
      )
    );
  }
}

GenericForm.propTypes = {
  /**
   * A string that uniquely identifies the generated form
   */
  name: PropTypes.string,
  /**
   * An object with the keys for the controlled inputs of the form
   */
  formData: PropTypes.object,
  /**
   * An array of objects with the configuration blocks that
   * compose the form.
   */
  formConfig: PropTypes.arrayOf(PropTypes.object),
  /**
   * A boolean flag that determines if the generic form
   * should be displayed wrapped on a form element.
   */
  isChild: PropTypes.bool,
  /**
   * A function provided to Generic form to be used as callback
   * to update the formData state entry when handling onChange events.
   */
  updateFormDataState: PropTypes.func,
  /**
   * Whether the form is processing.
   * The pending state should prevent further interaction
   */
  pending: PropTypes.bool,
  /**
   * A function provided to Generic form to handle the form submit.
   */
  submitFormData: PropTypes.func,
  children: PropTypes.any,
  store: PropTypes.object,
  formErrors: PropTypes.object,
};

export default GenericForm;
