class CategoryModel {
  constructor(props, path) {
    const [data = {}] = props;
    const { categoryData = {} } = data;
    const cmsData = data?.componentData;
    const [categoryPage = {}] = cmsData || [];
    Object.assign(this, categoryData);

    this.subCategories = categoryData.categories !== null ? categoryData.categories : [];
    const breadcrumbs = (categoryData.breadcrumbs || []).map((crumb, index) => {
      const isLast = categoryData.breadcrumbs.length === (index + 1);
      const url = isLast ? undefined : crumb.url;

      return ({ ...crumb, url });
    });

    this.breadcrumbs = breadcrumbs;
    // Mutate title for 'ebooks' and 'engelse boeken'
    let headerTitle;
    let isBook = false;
    const genre = breadcrumbs.length >= 2 ? breadcrumbs[1]?.name : '';
    const titleEqualToGenre = (breadcrumbs[breadcrumbs.length - 1]?.name === genre);
    if (['Engelse Boeken', 'eBooks'].includes(genre) && !titleEqualToGenre) {
      headerTitle = `${breadcrumbs[breadcrumbs.length - 1]?.name} ${genre}`;
    }
    if (['Boeken', 'Engelse Boeken', 'eBooks'].includes(genre)) {
      isBook = true;
    }

    this.headerTitle = headerTitle;
    this.isBook = isBook;
    const categoryUrlPath = path?.split('/')[1];
    this.categoryUrlPath = categoryUrlPath;

    // Meta data
    const { meta = {}, name: fallbackTitle = '', description: fallbackDescription = '' } = categoryData;
    const { noIndex, noFollow, pageTitle = '', pageDescription } = meta ?? {};
    const { title, description, image, robots } = categoryPage || {};

    this.robots = robots || [(noIndex && 'noindex'), (noFollow && 'nofollow')].filter(Boolean).join(',');
    this.pageTitle = title || pageTitle || fallbackTitle;
    this.pageDescription = description || pageDescription || fallbackDescription?.slice(0, 200) || '';
    this.pageImage = image;

    // Components. If components include a dynamicHeader, exclude it to place it at the top of the page.
    let components = categoryPage.components || [];
    const customComponent = components.find(component => component.layout === 'custom-layout') || {};
    const dynamicPageHeader = customComponent?.components?.find(component => component['component-type'] === 'DynamicPageHeader');
    if (dynamicPageHeader) {
      const filteredComponents = components.filter(component => component.layout !== 'custom-layout');

      components = filteredComponents;
      this.dynamicHeaderComponent = {

        layout: 'custom-layout',
        stick: true,
        components: [
          { ...dynamicPageHeader,
            // When custom title or subtitle are added in cms - show those. Otherwise show genre as title
            title: dynamicPageHeader?.title ? dynamicPageHeader?.title : headerTitle,
            subtitle: dynamicPageHeader?.subtitle ? dynamicPageHeader?.subtitle : null,
          },
          { 'component-type': 'breadcrumb', items: breadcrumbs },
        ],
      };
    }

    // mutate component when it's a default category component
    this.components = components.map((component) => {
      const { title, url } = component;
      if (['/bestverkocht', '/net-verschenen', '/alvast-reserveren'].includes(url)) {
        return {
          ...component,
          title: title ? `${title} ${categoryData.name}` : '',
          url: url + path,
        };
      }

      return component;
    });
    this.hasComponents = !!components.length;
  }
}

export default CategoryModel;
