import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../base_svg-icon/component';
import TypeSet from '../../base_typeset/component';
import Link from '../../base_link/component';

import './styles.scss';

const SectionTitle = ({ url, title, onClickEvent, collapsible, toggleSection, expanded }) => {
  const classNames = [
    'section-title',
    expanded && 'expanded',
    collapsible && 'collapsible',
  ].filter(Boolean).join(' ');

  if (url) {
    return (
      <div className={classNames} onClick={toggleSection}>
        {title && (
          <>
            <Link to={url} onClick={onClickEvent}>
              <TypeSet scale="0" tight bold>{title}</TypeSet>
              <Icon className="link-caret" name="ico-arrow-right"/>
            </Link>
            <Icon className="list-caret" name="ico-arrow-down"/>
          </>
        )}
      </div>
    );
  }
  return (
    <div className={classNames} onClick={toggleSection}>
      {title && (
        <>
          <TypeSet scale="0" tight bold>{title}</TypeSet>
          <Icon className="list-caret" name="ico-arrow-down"/>
        </>
      )}
    </div>
  );
};

SectionTitle.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  onClickEvent: PropTypes.func,
  collapsible: PropTypes.bool,
  toggleSection: PropTypes.func,
  expanded: PropTypes.bool,
};

export default SectionTitle;
