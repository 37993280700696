import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../base_svg-icon/component';
import { IS_B2B } from '@config';
import Restapi from '@utils/api';
import Text from '@components/ui_text/component';

import './styles.scss';

const PurchaseDiscountPercentage = ({ product }) => {
  const { sku, purchaseDiscountPercentage } = product;
  const [showDiscount, setShowDiscount] = useState(false);
  const [discount, setdiscount] = useState('');

  if (!IS_B2B || !product.isOrderable || product.isCBProduct || product.isCBBuitenlandsProduct) {
    return null;
  }

  useEffect(() => {
    setShowDiscount(false);
    setdiscount('');
  }, [product]);

  const restapi = new Restapi();

  const getDiscount = () => {
    setShowDiscount(true);
    if (!!purchaseDiscountPercentage || (purchaseDiscountPercentage === 0)) {
      setdiscount(`${purchaseDiscountPercentage}%`);
    } else {
      restapi.purchaseDiscount(sku)
        .then((response) => {
          setdiscount(`${response.discountPercentage}%`);
        })
        .catch(() => {
          setdiscount(<Text>PurchaseDiscount.discount-unknown</Text>);
        });
    }
  };

  const toggleDiscount = () => {
    if (showDiscount) {
      setShowDiscount(false);
    } else {
      getDiscount();
    }
  };

  return (
    <div className="dicount-percentage" onClick={toggleDiscount}>
      <Icon name="ico-arrow-right" size={100}/>
      {showDiscount ? (
        <div className="toggle-opened">
          <Text>PurchaseDiscount.your-discount</Text>: {discount}
        </div>
      ) : (
        <div className="toggle-closed">
          <Text>PurchaseDiscount.ask-to-display-discount</Text>
        </div>
      )}
    </div>
  );
};

PurchaseDiscountPercentage.propTypes = {
  product: PropTypes.object,
};
export default PurchaseDiscountPercentage;
