import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { isBrowser } from '@utils';
import { t } from '@utils/i18n';
import { AppContext } from '../../ssr/shared/application-context';

/**
 * Component to retrieve localized texts. Why a component and not just a function?
 * Because all localised texts are fetched asynchronous, so the function might run
 * before the texts are available. This component listens for the custom storedTexts
 * event and rerenders.
 * @param {string} children
 * @param {object} replacements
 * @param {boolean} html
 */
const Text = ({ children, replacements, html = false, callback }) => {
  // The useApp hook is throwing errors here so therefore we are still using useContext
  const appCtx = useContext(AppContext);
  const defaultTexts = isBrowser ? window.i18n : appCtx.i18n;
  const [texts, setTexts] = useState(defaultTexts);

  const updateTexts = (e) => {
    setTexts(e.detail);
    if (callback) {
      callback(e.detail[children] || '');
    }
  };

  useEffect(() => {
    window.addEventListener('storedTexts', updateTexts);

    return () => {
      window.removeEventListener('storedTexts', updateTexts);
    };
  }, []);

  // Don't bother rendering
  if (!texts) {
    return null;
  }

  const value = t(children, { texts, replacements, html });

  // Render a '?' instead of an empty string
  if (!value && !PRODUCTION) {
    const markedStyle = { display: 'contents', color: 'red' };
    return <span title={children} style={markedStyle}>?</span>;
  }

  if (html) {
    return <span style={{ display: 'contents' }} dangerouslySetInnerHTML={value} />;
  }

  return <>{value}</>;
};

Text.propTypes = {
  children: PropTypes.string.isRequired,
  replacements: PropTypes.object,
  html: PropTypes.bool,
  callback: PropTypes.func,
};

export default Text;
