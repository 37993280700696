import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LinkList from './_link-list/component';
import MediaBlock from './_media-block/component';
import SectionTitle from './_section-title/component';

const NavigationSection = ({ type, ...rest }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleSection = () => {
    setExpanded(expanded => !expanded);
  };

  const map = {
    list: LinkList,
    media: MediaBlock,
  };

  const Component = map[type];
  return (
    <>
      <SectionTitle {...rest} toggleSection={toggleSection} expanded={expanded}/>
      <Component expanded={expanded} {...rest}/>
    </>
  );
};

NavigationSection.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  onClickEvent: PropTypes.func,
};

export default NavigationSection;
