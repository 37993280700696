import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sidepanel from './_sidepanel/component';
import Popup from './_popup/component';
import store from '@utils/store';

class Modal extends Component {
  constructor(props) {
    super(props);
    props.initModal(this);
    this.state = {
      location: '',
      style: {},
      persist: false,
      toggled: false,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  get variant() {
    return this.state.variant;
  }

  /**
   * toggle the scroll when the modal is open
   */
  toggleScroll(disable) {
    document.documentElement.style.overflowY = disable ? 'hidden' : '';
  }

  /**
   * open one of the modals
   *
   * // if there is no component
   * // the sidepanel can be filled with text
   * // attrs.text
   */
  openModal(variant = '', location = '', attrs = {}, toggled = true) {
    const persist = this.attrs && this.attrs.persist;
    if (!attrs.component) {
      if (attrs.text) {
        attrs.component = <p>{attrs.text}</p>;
      }
    }

    this.setState({
      variant,
      location,
      persist,
      toggled,
      ...attrs,
    });

    this.toggleScroll(toggled);
  }

  /**
   * remove the component from the modal. So it will reset its component
   */
  removeComponentFromModal() {
    const animationDuration = this.variant === 'popup' ? 0 : 300;
    setTimeout(() => {
      this.setState({ component: null, title: '', classNames: '' });
    }, animationDuration);
  }

  /**
   * close the modal
   */
  closeModal(e) {
    e && e.preventDefault();
    this.setState({ toggled: false });
    this.toggleScroll();
    if (!this.state.persist) {
      this.removeComponentFromModal();
    }
    store.events.publish('closeModal');
  }


  getModalVariant() {
    const transMap = {
      default: Sidepanel,
      sidepanel: Sidepanel,
      popup: Popup,
    };
    return transMap[this.state.variant] || transMap.default;
  }

  render() {
    const ModalVariant = this.getModalVariant();

    return (
      <ModalVariant {...this.state} closeModal={this.closeModal}/>
    );
  }
}

Modal.propTypes = {
  /**
   * This function is returning the modal instance to pass it to the rest of the components
   */
  initModal: PropTypes.func,
};

export default Modal;

