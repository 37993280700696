import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getFeature } from '@utils';
import Restapi from '@utils/api';
import Text from '@text';
import { t } from '@utils/i18n';

const SiteRating = ({ textKey }) => {
  const { url, attr, isActive } = getFeature('reviewRating');

  useEffect(() => {
    if (isActive) {
      getRating();
    }
  }, []);

  const [rating, setRating] = useState();
  const getRating = () => {
    const restapi = new Restapi();
    restapi.rating(url)
      .then((res) => {
        const ratingNumber = res[attr];
        if (ratingNumber) {
          return setRating(ratingNumber);
        }
        setRating(null);
      })
      .catch(() => {
        setRating(null);
      });
  };

  if (!isActive) {
    return null;
  }

  if (rating) {
    return (<Text replacements={{ rating }}>{textKey}</Text>);
  } else if (rating === null) {
    // Show default loc. text if failed to get rating
    return (<Text replacements={{ rating: t('rating.default') || ' ' }}>{textKey}</Text>);
  }

  // Show label without rating as pending state
  return (<Text replacements={{ rating: ' ' }}>{textKey}</Text>);
};

SiteRating.propTypes = {
  textKey: PropTypes.string,
};

export default SiteRating;
