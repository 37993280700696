import React from 'react';
import { useAuth } from '@utils/authentication/AuthProvider';
import Text from '@text';

import './styles.scss';

const AccountBar = () => {
  const auth = useAuth();

  let credentials = '';
  if (auth.user) {
    const { company = '', addressLine1 = '', addressLine2 = '', city = '' } = auth.user.address;
    const address = `${addressLine1} ${addressLine2}`;
    const fullAddress = [city, address].filter(Boolean).join(', ');
    credentials = [company, fullAddress].filter(Boolean).join(' | ');
  }

  return (
    <>
      <div className="account-bar">
        <div className="container">
          <div className="inner">
            <div className="account">
              <div className="bullit"></div>
              {credentials}
            </div>
            <div className="log-out">
              <a onClick={() => auth.logout({ reload: true })}>
                <Text>account.logout.link</Text>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountBar;
