import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { IS_B2B } from '@config';
import { appStorage } from '@utils';
import { useApp } from '../../ssr/shared/application-context';
import { TOKEN_STORAGE } from '@constants';

const RequireAuth = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  const appCtx = useApp();
  const storedAuthToken = appStorage.getItem(TOKEN_STORAGE);

  if (IS_B2B && (!auth.user || !storedAuthToken)) {
    const currentPage = location.pathname + location.search;
    appCtx.setPreviousUrl(currentPage);
    return <Redirect to="/login" />;
  }

  return children;
};

RequireAuth.propTypes = {
  children: PropTypes.node,
};

export default RequireAuth;
