import React from 'react';
import loadableComponent from '@loadable/component';

const Loading = () => {
  return (
    <div title="loadable-component"/>
  );
};

const loadable = (loader, Fallback) => loadableComponent(loader, { fallback: Fallback ? <Fallback/> : <Loading/> });

export default loadable;
