import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { isBrowser } from '@utils';
import { SEO, LOCALE } from '@config';

const { organization = {}, defaultTitle, defaultDescription, defaultImagePath, fbAppId, appendix, twitterHandle = '' } = SEO;

const MetaTags  = () => {
  let host = organization.url;
  if (isBrowser) {
    host = location.href;
  }
  return (
    <>
      <Helmet>
        <title>{defaultTitle} {appendix}</title>
        <meta name="title" content={defaultTitle} />
        <meta name="description" content={defaultDescription} />

        <meta property="og:type" content="website"/>
        <meta property="og:url" content={host}/>
        <meta property="og:title" content={defaultTitle}/>
        <meta property="og:description" content={defaultDescription}/>
        <meta property="og:image" content={defaultImagePath}/>
        <meta property="og:locale" content={LOCALE} />

        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:url" content={host}/>
        <meta name="twitter:site" content={twitterHandle} />
        <meta name="twitter:title" content={defaultTitle}/>
        <meta name="twitter:description" content={defaultDescription}/>
        <meta name="twitter:image" content={defaultImagePath}/>

        {fbAppId && <meta property="fb:app_id" content={fbAppId}/>}

        <meta name="robots" content="index, follow" />
      </Helmet>
    </>
  );
};

MetaTags.propTypes = {
  host: PropTypes.string,
};

export default MetaTags;
