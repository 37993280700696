import React from 'react';
import PropTypes from 'prop-types';
import './styles/styles.scss';

const Row = (props) => {
  const { children, className = '', rowRef, ...rest } = props;

  return (
    <div
      ref={rowRef}
      className={`row ${className}`}
      {...rest}
    >{children}</div>
  );
};
Row.propTypes = {
  className: PropTypes.string,
  rowRef: PropTypes.object,
  children: PropTypes.node,
};

const Col = (props) => {
  let { children, className = '', xs, sm, md, lg, xsOffset, smOffset, mdOffset, lgOffset, colRef, ...rest } = props;

  if (xs) {
    className = `${className} col-xs-${xs}`;
  }
  if (sm) {
    className = `${className} col-sm-${sm}`;
  }
  if (md) {
    className = `${className} col-md-${md}`;
  }
  if (lg) {
    className = `${className} col-lg-${lg}`;
  }

  // Offset
  if (xsOffset) {
    className = `${className} col-xs-offset-${xsOffset}`;
  }
  if (smOffset) {
    className = `${className} col-sm-offset-${smOffset}`;
  }
  if (mdOffset) {
    className = `${className} col-md-offset-${mdOffset}`;
  }
  if (lgOffset) {
    className = `${className} col-lg-offset-${lgOffset}`;
  }

  return (
    <div
      className={className}
      ref={colRef}
      {...rest}
    >{children}</div>
  );
};
Col.propTypes = {
  className: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xsOffset: PropTypes.number,
  smOffset: PropTypes.number,
  mdOffset: PropTypes.number,
  lgOffset: PropTypes.number,
  children: PropTypes.node,
  colRef: PropTypes.object,
};

export { Row, Col };
