import { useRef, useEffect, useCallback, useState } from 'react';
import { t } from '@utils/i18n';
import { isBrowser, log } from '.';

/**
 * Hook to retrieve localized texts. It listens for the custom storedTexts
 * event and re-assigns a value.
 * @param {string} children
 * @param {object} replacements
 * @param {boolean} html
 */
export const useText = (key = '', options = {}) => {
  const [text, setText] = useState(t(key, options));

  const updateText = () => {
    setText(t(key, options));
  };

  useEffect(() => {
    window.addEventListener('storedTexts', updateText);

    return () => {
      window.removeEventListener('storedTexts', updateText);
    };
  }, []);

  return text;
};

const defaultElement = isBrowser ? window : null;
export const useEventListener = (eventName, handler, element = defaultElement) => {
  // Create a ref that stores handler
  const savedHandler = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      const isSupported = element && element.addEventListener;
      if (!isSupported) {
        return;
      }

      const eventListener = event => savedHandler?.current?.(event);
      element.addEventListener(eventName, eventListener);

      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element]
  );
};

export const useStateCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // mutable ref to store current callback

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store passed callback to ref
    setState(state);
  }, []);

  useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);
  return [state, setStateCallback];
};

/**
 * A custom useEffect hook that only triggers on updates, not on initial mount.
 *
 * @param {Function} effect
 * @param {Array} dependencies
 */
export const useUpdateEffect = (effect, dependencies = []) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return effect();
    }
  }, dependencies);
};

export const useIntersect = ({ root = null, rootMargin, threshold = 0 }) => {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);


  const intersectionObserver = isBrowser ? (
    new window.IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      rootMargin,
      threshold,
    })
  ) : null;

  const observer = useRef(intersectionObserver);

  useEffect(
    () => {
      const { current: currentObserver } = observer || {};
      if (currentObserver) {
        currentObserver.disconnect();

        if (node) {
          currentObserver.observe(node);
        }
      }

      return () => {
        if (currentObserver) {
          currentObserver.disconnect();
        }
      };
    },
    [node]
  );

  return [setNode, entry];
};

// Custom hook to be able to save a previous value
export const usePreviousValue = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

/**
 * Helper hook for useEffectDebugger
 */
const _usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => ref.current = value);
  return ref.current;
};

/**
 * Use this debugger hook to find out which dependency is triggering a re-render
 * in a useEffect Hook. Simply replace 'useEffect' with 'useEffectDebugger'.
 *
 * see: https://stackoverflow.com/questions/55187563/determine-which-dependency-array-variable-caused-useeffect-hook-to-fire
 *
 * @param {function} effectHook
 * @param {array} dependencies
 * @param {array} dependencyNames
 */
export const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
  const previousDeps = _usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    log('[use-effect-debugger] ', changedDeps);
  }

  useEffect(effectHook, dependencies);
};
