import { getParametersFromUrl } from '.';

/**
 * All incoming traffic is monitored by Google analytics, but we
 * need to store traffic variables to the order in Intershop as well.
 * https://audaxdigitaal.atlassian.net/wiki/spaces/ECI/pages/1088754998/2.15.5+Traffic+channel+allocation+for+orders+customers+and+contracts
 */

/**
 * Check traffic to see if local storage is already set
 * If it is, check if it needs to be updated, otherwise set it
 */
const checkTraffic = () => {
  // hint if there is a query string at all
  if (window.location.href.indexOf('?') === -1) {
    return;
  }

  // if there is no ('ga_source' or 'ga_adwordstag') return
  const trafficData = getTrafficData();
  if (!(!!trafficData.ga_source || !!trafficData.ga_adwordstag)) {
    return;
  }

  // set or update is the same
  setPartnerData(trafficData);
};

/**
 * Read partner data from local storage
 * @returns {Object}
 */
const getPartnerData = () => {
  const key = 'partnerData';
  const itemString = localStorage.getItem(key);
  if (!itemString) {
    return null;
  }
  const item = JSON.parse(itemString);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.trafficData;
};

/**
 * Set Partner data in local storage with all channel data
 * Exiration date should be 30 days later.
 */
const setPartnerData = (trafficData) => {
  const nrOfDaysFromNow = 30;
  const date = new Date();
  const expiry = date.setDate(date.getDate() + nrOfDaysFromNow);
  localStorage.setItem('partnerData', JSON.stringify({ trafficData, expiry }));
};

/**
 * Get all traffic data based on url and referrer
 * @returns {Object}
 */
const getTrafficData = () => {
  const {
    utm_source: ga_source = '',
    utm_medium: ga_medium = '',
    utm_campaign: ga_campaign = '',
    utm_content: ga_content = '',
    utm_term: ga_term = '',
    gclid: ga_adwordstag = '',
    PartnerUUID: partnerUuid = '',
  } = getParametersFromUrl(window.location.href);

  // Check if the referrer is a search engine, if so, use url as source
  const referrer = document.referrer.split('.');
  let isSeoPartner = false;
  const seoPartners = ['google', 'yahoo', 'bing'];
  seoPartners.forEach((partner) => {
    if (referrer.includes(partner)) {
      isSeoPartner = true;
    }
  });

  return {
    ga_source: ga_source || (!!isSeoPartner ? document.referrer : ''),
    ga_medium,
    ga_campaign,
    ga_content,
    ga_term,
    ga_adwordstag,
    partnerUuid,
    partner_date_time: getDateTime(),
  };
};

/**
 * Get the current date and time in the required
 * format for the partner data (YYYY-MM-DD HH:mm)
 * @returns {String}
 */
const getDateTime = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth is zerobased
  const day = date.getDate();
  const hours = date.getHours();
  const mins = date.getMinutes();
  return `${year}-${month}-${day} ${hours}:${mins > 9 ? mins : `0${mins}`}`;
};

export {
  checkTraffic,
  getPartnerData,
};
