const getCampaignroutes = () => ([
  '/aanbiedingen',
  '/sale-aanbiedingen',
  '/nationale-voorleesdagen',
  '/winacties',
  '/outlet',
  '/kortingscodes',
  '/moederdag',
  '/week-zonder-vlees',
  '/gids',
  '/boekenweek',
  '/valentijn',
  '/kerst',
  '/blackfridaydeals',
  '/singlesday',
  '/sint',
  '/kinderboekenweek',
  '/spannendeboekenweken',
  '/cybermonday',
  '/disney',
  '/zomer',
  '/romanweken',
  '/educatieve-kinderboeken',
  '/filmhuis',
  '/knack',
  '/trooper',
  '/vakantie',
  '/businessAM',
  '/humo',
  '/acties',
  '/backtoschool',
  '/booktok',
  '/examenbundel-samengevat',
  '/folders',
  '/schoolbieb',
  '/schoolspullen',
  '/schoolagenda',
  '/stichting-jarige-job',
  '/thrillerweken',
  '/vaderdag',
  '/zomerlezen',
  '/ns-publieksprijs',
]);

export default getCampaignroutes;
