import React from 'react';
import Link from '../base_link/component';
import SiteRatingText from '../ui_site-rating-text/component';
import { getFeature } from '@utils';
import './styles/styles.scss';

const SiteRating = () => {
  const { link = '', isActive } = getFeature('reviewRating');

  if (!isActive) {
    return null;
  }

  return (
    <div className="site-rating-container">
      <Link
        className="div"
        rel="nofollow noopener noreferrer"
        target="_blank"
        href={link}
      >
        <SiteRatingText textKey="site-rating.text"/>
      </Link>
    </div>
  );
};


export default SiteRating;
