
import React from 'react';
import { createRoot } from 'react-dom/client';
import Symbols from '../images/icons/symbol-defs.svg';

/**
 * Create a div, append that div as the first child of the body
 * and render the Symbols svg into that div.
 */
const appendIconSprite = () => {
  const div = document.createElement('div');
  div.id = 'symbols-definitions';

  if (!document.getElementById(div.id)) {
    const { body } = document;
    body.insertBefore(div, body.firstChild);

    const root = createRoot(div);
    root.render(<Symbols />);
  }
};

export default appendIconSprite;

