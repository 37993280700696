import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../base_svg-icon/component';
import './styles/style.scss';

/**
 * Renders a styled checkbox with an accompanying label
 * @class StyledCheckbox
 */
const StyledCheckbox = (props) => {
  const { label, hasError, checked, count, groupId, id, disabled, ...inputProps } = props;
  delete inputProps.checked; // do not pass checked as attribute to the input, since it will then become readonly, we use defaultChecked to set the value.
  delete inputProps.onChange; // do not pass onChanged as we will handle this as onClick to prevent race conditions

  const handleOnClick = (e) => {
    if (props.onChange) {
      props.onChange(e.target.checked);
    }
  };

  const testId = `selectItem-${id.replace(/[^a-zA-Z0-9]/g, ' ').split(/\s+/)
    .join('-')
    .toLowerCase()}`;

  return (
    <>
      <div className={`styled-checkbox checkbox ${hasError ? 'checkbox--has-error' : ''}`}>
        <div className={`checkbox-icon ${checked ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
          <Icon name="ico-check-modern"/>
        </div>
        <input
          id={id + groupId}
          data-test-id={testId}
          checked={checked}
          className={`checkbox__input ${hasError ? 'checkbox--has-error__input' : ''}`}
          onClick={handleOnClick}
          onChange={() => {}}
          type="checkbox"
          {...inputProps}
        />
        <label className="checkbox__label" htmlFor={id + groupId}>{label}</label>
      </div>
      {count && <span>({count})</span>}
    </>
  );
};

StyledCheckbox.propTypes = {
  id: PropTypes.string,
  groupId: PropTypes.string,
  count: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  checked: PropTypes.bool,
  hasError: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default StyledCheckbox;
