import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import Application from '../shared/Application';
import reportWebVitals from '@utils/reportWebVitals';
import { log } from '@utils';

/*
 * Importing the css output file of audamatic-ui
 * Contains the css from the used tailwind classes
 */
import 'audamatic-ui/dist/nopreflight.css';

const root = createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <HelmetProvider>
      <Application isClient/>
    </HelmetProvider>
  </BrowserRouter>
);

const logAllVitals = false; // set to true to see all vitals in the console
if (LOCAL && logAllVitals) {
  reportWebVitals(log);
}
