export const getGenesysConfig = ({
  deploymentKey,
  orgGuid,
  targetAddress,
  navigator,
  document,
  location,
  domain,
}) => ({
  widgets: {
    main: {
      theme: 'light',
      lang: 'nl',
      i18n: { // https://apps.mypurecloud.com/widgets/9.0/i18n/widgets-en.i18n.json
        nl: {
          webchat: {
            // Localized strings
            ChatTitle: 'Chat met ons',
            ChatStarted: 'De chat is begonnen.',
            ChatEnded: 'Bedankt en een fijne dag!',
            ChatFailed: 'Er is iets misgegaan. Probeer het nog een keertje.',
            ChatFormSubmit: 'Verder',
            ChatFormClose: 'Sluiten',
            ChatInputPlaceholder: 'Je bericht...',
            ChatInputSend: 'Verzenden',
            ChatEndQuestion: 'Weet je zeker dat je wil stoppen?',
            ChatEndCancel: 'Nee',
            ChatEndConfirm: 'Ja',
            ConfirmCloseWindow: 'Weet je zeker dat je wil afsluiten?',
            ConfirmCloseCancel: 'Nee',
            ConfirmCloseConfirm: 'Ja',
            AgentNameDefault: 'Klantenservice',
            AgentConnected: '<%Agent%> neemt deel aan het gesprek',
            AgentDisconnected: '<%Agent%> heeft het gesprek verlaten',
            AgentUnavailable: 'Sorry, er is nu niemand beschikbaar. Probeer het straks nog een keertje.',
            EndConfirmBody: 'Weet je zeker dat je wil stoppen?',
            EndConfirmAccept: 'Ja',
            EndConfirmReject: 'Nee',
            SurveyOfferQuestion: 'Mogen we je een paar korte vragen stellen om onze service te verbeteren?',
            ShowSurveyAccept: 'Ja',
            ShowSurveyReject: 'Nee',
            AgentTyping: '<%Agent%> is aan het typen...',
            SupervisorConnected: '<%Agent%> neemt deel aan het gesprek',
            SupervisorDisconnected: '<%Agent%> heeft het gesprek verlaten',
            StartFailed: 'Sorry, er is iets misgegaan. Check alvast of je alle velden hebt ingevuld en of je internetverbinding goed is. Probeer het dan nog eens.',
            ChatUnavailable: 'Er kan nu geen chat gestart worden. Probeer het later nog eens.',
            CriticalFault: 'De chat is plotseling gestopt, sorry hiervoor.',
            MessageFailed: 'Je bericht is niet verzonden, wil je het nog een keertje proberen?',
            RestoreFailed: 'Het is niet gelukt om de chat te hervatten. Sorry!',
            DayLabels: [
              'zo',
              'ma',
              'di',
              'wo',
              'do',
              'vr',
              'za',
            ],
            MonthLabels: [
              'Jan',
              'Feb',
              'Mrt',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sept',
              'Okt',
              'Nov',
              'Dec',
            ],
            todayLabel: 'Vandaag',

            // Customer Defined Strings - Match & Replace messages received from chat server
            SYS0001: 'Je wordt zo snel mogelijk geholpen.',
          },
        },
      },
      downloadGoogleFont: false,
    },
    webchat: {
      transport: {
        type: 'purecloud-v2-sockets',
        dataURL: 'https://api.mypurecloud.de',
        deploymentKey,
        orgGuid,
        interactionData: {
          routing: {
            targetType: 'QUEUE',
            targetAddress,
            priority: 2,
          },
        },
      },
      userData: {
        customField2Label: 'Domein',
        customField2: domain,
        customField3Label: 'URL',
        customField3: location?.href || 'Onbekend',
        customField4Label: 'Browser info',
        customField4: navigator?.userAgent || 'Onbekend',
        customField5Label: 'Browser taal',
        customField5: navigator?.language || 'Onbekend',
        customField6Label: 'Touchscreen',
        customField6: !!(navigator?.maxTouchPoints || 'ontouchstart' in document?.documentElement) ? 'Ja' : 'Nee',
      },
    },
  },
});

export const advancedConfig = ({ firstName = '', lastName = '', email = '' }) => ({
  form: {
    autoSubmit: false,
    firstname: [firstName, lastName].filter(Boolean).join(' '),
    email,
  },
  formJSON: {
    wrapper: '<div></div>',
    inputs: [
      {
        id: 'cx_webchat_form_firstname',
        name: 'firstname',
        maxlength: '100',
        label: 'Naam',
        wrapper: '<div>{label}</div><div class=\'purecloud-input\'>{input}</div>',
      },
      {
        id: 'cx_webchat_form_email',
        name: 'email',
        maxlength: '100',
        label: 'E-mailadres',
        wrapper: '<div>{label}</div><div class=\'purecloud-input\'>{input}</div>',
      },
      // Custom Fields
      {
        id: 'custom_field_1',
        name: 'customField1',
        maxlength: '300',
        label: 'Je vraag',
        type: 'textarea',
        wrapper: '<div>{label}</div><div class=\'purecloud-input\'>{input}</div>',
      },
    ],
  },
});
