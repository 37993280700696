import { miniMustache, isBrowser } from '.';
import { TEXT_VARS } from '@config';
import { BUILD_VERSION } from '@constants';
import { log } from '@utils';
import Api from './api';

/**
 * Store data on window (until all sites run as a SPA)
 * @param {object} texts
 */
const _storeTexts = (texts = {}) => {
  window.i18n = texts;

  // detail is required attr for CustomEvent
  const event = new CustomEvent('storedTexts', { detail: texts });
  window.dispatchEvent(event);
};

/**
 * Fetch all localised texts of read them from
 * sessionstorage if it's available.
 * @param {object} store
 */
export const initLocalization = (store) => {
  if (!isBrowser) {
    return;
  }

  // Check if texts have already been fetched this session and for this version
  const storedTexts = sessionStorage.getItem('i18n');
  const textVersion = sessionStorage.getItem('i18n-version');
  if (storedTexts && textVersion === BUILD_VERSION) {
    return _storeTexts(JSON.parse(storedTexts));
  }

  const api = new Api();

  api.getLocalisedTexts()
    .then((res) => {
      const texts = constructTexts(res.localizations);

      // handle storage of fetched texts
      _storeTexts(texts, store);

      // Store in sessionstorage for next request
      sessionStorage.setItem('i18n', JSON.stringify(texts));
      sessionStorage.setItem('i18n-version', BUILD_VERSION);
    });
};

/**
 * Function to retrieve localized text
 * @param {string} key corresponds with an existing loc. texts
 * @param {*} options
 * @returns {string}
 */
export const t = (key = '', { texts, replacements = {}, html } = {}) => {
  // variable i18n is only available on the server
  const storedTexts = (isBrowser ? (texts || window.i18n) : {}) || {};
  let value = storedTexts ? (storedTexts[key] || '') : '';
  if (!value && !PRODUCTION) {
    log(`Could not find a translation for: '${key}'`);
  }

  const hasReplacements = !!Object.values(replacements).length;
  if (html && !hasReplacements) {
    return { __html: value };
  }

  if (hasReplacements) {
    value = miniMustache({ string: value, replacements, html });
  }
  return value;
};

/**
 * Check localised texts for any channel specific text variables
 * placeholder and inject them.
 *
 * @param {array} localisedTexts array
 * @returns localisedTexts object
 */
export const constructTexts = (localisedTexts = []) => {
  const texts = {};
  localisedTexts.forEach((text = {}) => {
    const { key, localizedValue = '' } = text;

    // early exit if it doesn't contain channel variable
    const regex = /\(\(([^\(\)]*)\)\)/g; // match ((placeholder))
    if (!regex.test(localizedValue)) {
      return texts[key] = localizedValue;
    }

    const constructedText = localizedValue.replace(regex, (match, matchVal) => {
      const replaceText = TEXT_VARS[matchVal];
      return replaceText || match;
    });

    return texts[key] = constructedText;
  });

  return texts;
};
