import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Product } from '@utils/product';
import PriceBlock from '../../../../base_price-block/component';
import { ProductImage } from 'audamatic-ui';
import Icon from '../../../../base_svg-icon/component';
import './styles.scss';

class Suggestion extends PureComponent {
  getHighlightedText(text, highlight) {
    if (highlight === '*') {
      return text;
    }
    /*
     * Special characters will break the regex so we have to escape them first
     * Matches any special character and replaces it with \ that special character ($& returns matched substring)
     */
    const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const re = new RegExp(`(${escapedHighlight})`, 'gi');
    const parts = text.split(re);

    return (
      <span>
        {parts.map((part, i) => {
          if (!highlight) {
            return (<span key={i} dangerouslySetInnerHTML={{ __html: part }}/>);
          }

          const className = part.toLowerCase() === highlight.toLowerCase() ? 'highlight' : '';
          return (
            <span key={i} className={className}><span dangerouslySetInnerHTML = {{ __html: part }}></span></span>
          );
        })}
      </span>
    );
  }

  renderSearchTerm() {
    const { item, searchTerm } = this.props;
    return (
      <div className={'wrapper search-term'}>
        <div className={'title'}>{this.getHighlightedText(item.value, searchTerm)}</div>
      </div>
    );
  }

  renderProduct() {
    const { searchTerm, item, store } = this.props;
    const product = new Product(item);
    const category = [product.type, product.genre].filter(i => typeof (i) === 'string').join(' - ');
    return (
      <div className={'wrapper'}>
        <div className="product-image-wrapper">
          <ProductImage
            product={product}
            maxHeight={85}
          />
        </div>
        <div className={'product-information'}>
          <div className={'title'}>{this.getHighlightedText(product.name, searchTerm)}</div>
          <div className={'producer'}>{product.getPersonNames()}</div>
          <div className={'bottom-wrapper'}>
            <div className={'category'}>{category}</div>
            <PriceBlock store={store} product={product} size="small" onePrice/>
          </div>
        </div>
      </div>
    );
  }

  renderPerson() {
    const { item, searchTerm } = this.props;
    return (
      <div className="wrapper">
        <Icon name="ico-user-alt" size={200} />
        <div>
          <div className={'title'}>{this.getHighlightedText(item.name, searchTerm)}</div>
          <div className={'subtitle'}>{item.role}</div>
        </div>
      </div>
    );
  }

  renderCategory() {
    const { item, searchTerm } = this.props;
    return (
      <div className="wrapper category">
        <Icon name="menu" />
        <div>
          <div className={'title'}>{this.getHighlightedText(item.name, searchTerm)} </div>
          <div className="subtitle"><span className="grey">in </span><span className="blue">{item.parent}</span></div>
        </div>
      </div>
    );
  }

  renderItem() {
    switch (this.props.type) {
    case 'searchTerms':
      return this.renderSearchTerm();
    case 'persons':
      return this.renderPerson();
    case 'categories':
      return this.renderCategory();
    case 'products':
      return this.renderProduct();
    default:
      return null;
    }
  }

  render() {
    const { type, handleSuggestionMouseClick, handleSuggestionMouseHover, index, scopedIndex, searchTerm, highlight } = this.props;
    return (
      <div
        onMouseOver={() => handleSuggestionMouseHover(index)}
        onMouseOut={() => handleSuggestionMouseHover(index)}
        className={`suggestion ${highlight ? 'active' : ''}`}
        onClick={() => handleSuggestionMouseClick({ type, index, scopedIndex, searchTerm })}
        data-item-type={type}
        data-item-index={index}
        data-item-scoped-index={scopedIndex}
      >
        {this.renderItem()}
      </div>
    );
  }
}

Suggestion.propTypes = {
  type: PropTypes.string,
  item: PropTypes.object,
  searchTerm: PropTypes.string,
  highlight: PropTypes.bool,
  index: PropTypes.number,
  scopedIndex: PropTypes.number,
  handleSuggestionMouseHover: PropTypes.func,
  handleSuggestionMouseClick: PropTypes.func,
  store: PropTypes.object,
};

export default Suggestion;
