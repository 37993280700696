
import loadable from '../ssr/shared/loadable/component';
import CmsApi from './api/Cms';
import { PRIMARY_CATEGORIES } from '../constants';
import { getSkuFromUrl } from '@utils';

const ProductDetailPage = loadable(() => import('../ssr/shared/pages/ProductDetailPage'));
const CategoryPage = loadable(() => import('../ssr/shared/pages/CategoryPage'));

/**
 * Get route component based on path.
 * Can be either a PDP or Genre page
 *
 * @param {string} path
 * @returns {Component} correct page component
 */
export const getRouteComponent = (path = '') => {
  const regex = /\d{5,}/g;
  const matches = path.match(regex) || [];

  // If path contains at least 5 digits, treat as PDP
  if (!!matches.length) {
    return ProductDetailPage;
  }
  return CategoryPage;
};

/**
 * Get routeId component based on path.
 * Can be either a 'ProductPage' or 'GenrePage'
 *
 * @param {string} path
 * @returns {strinng} correct page component
 */
export const getRouteId = (path = '') => {
  const regex = /\d{5,}/g;
  const matches = path.match(regex) || [];

  // If path contains at least 5 digits, treat as PDP
  if (!!matches.length) {
    return 'ProductPage';
  }
  return 'GenrePage';
};


/**
 * Get relevant information on the category, based on the pathname
 *
 * @param {string} path
 * @returns {object}
 */
export const categoryIdentifiers = (pathname) => {
  let path = pathname;
  path = path.endsWith('/') ? path.slice(0, -1) : path;
  path = path.startsWith('/') ? path.substring(1) : path;
  const parts = path.split('/');

  return {
    mainCategory: parts[0],
    isLevel3: parts.length >= 3,
  };
};


/**
 * TODO: Prevent app from fetching 2 components
 * @param {string} apiUrl
 * @param {string} componentId
 * @returns {boolean}
 */
/*
 * const checkIfComponentExists = async (apiUrl, componentId) => {
 *   const api = new CmsApi({ apiUrl });
 *   const response = await api.componentExists({ id: componentId });
 *   const length = response.headers?.get('content-length');
 *   const exists = length > 2;
 *   return exists;
 * };
 */

/**
 * Fetch required data for the category:
 * - Always fetch category data (title, description, etc.)
 * - If is not a level 3 category, then also fecth a CMS component
 *
 * @param {string} path
 * @param {string} apiUrl
 * @returns {array}
 */
export const fetchCategoryRequests = ({ path = '', apiUrl, includeComponent }) => {
  const { mainCategory, isLevel3 } = categoryIdentifiers(path);

  const api = new CmsApi({ apiUrl });

  let shouldIncludeComponent = includeComponent;
  if (includeComponent) {
    const isPrimaryCategory = PRIMARY_CATEGORIES.includes(mainCategory);
    shouldIncludeComponent = isPrimaryCategory && !isLevel3;
  }

  return [api.fetchCategoryByPath({ path, includeComponent: shouldIncludeComponent })];
};

/**
 * Check whether to request data for the PDP or for the Category Page
 * based on the pathname. If tthe pathname includes at least 5 digits,
 * we assume that it is a PDP, else a Category page.
 *
 * @param {string} path
 * @param {string} apiUrl
 * @returns {array}
 */
export const handleFetchForProductOrGenre = (path = '', apiUrl) => {
  const regex = /\d{5,}/g;
  const matches = path.match(regex) || [];

  // If path contains at least 5 digits, treat as PDP
  if (!!matches.length) {
    const api = new CmsApi({ apiUrl });
    const sku = getSkuFromUrl(path);
    return [api.fetchProduct({ sku })];
  }

  return fetchCategoryRequests({ path, apiUrl, includeComponent: true });
};

export const fetchTop = (path = '', apiUrl) => {
  const api = new CmsApi({ apiUrl });
  return [api.fetchCategoryByPath({ path, includeComponent: true, isTopPage: true })];
};
