import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from './_svg/svg';
import { ICON_MAP } from '@config';

import './styles/styles.scss';

class Icon extends Component {
/*
 * map over all the keys in the classes object
 * @ returns an array of the keys where the value for the key is not null
 */
  transformationClasses = () => {
    const {
      size,
      direction,
      icoAfterChildren,
      className,
      transform,
      children,
    } = this.props;

    const classArray = {
      [`flip-${transform?.flip}`]: !!transform?.flip && transform?.flip !== '',
      [`${className}`]: !!className && className !== '',
      'has-children': !!children && children !== '',
      'ico-after-children': icoAfterChildren,
      [`ico-${direction}`]: direction,
      [`ico-${size}`]: typeof size !== 'undefined' && size !== null,
    };

    return Object.keys(classArray).map(key => (classArray[key] ? key : null))
      .filter(key => key);
  };

  get classes() {
    return this.transformationClasses().join(' ');
  }

  get children() {
    if (typeof this.props.children === 'string' && this.props.children !== '') {
      return  <div className={`hoc-html-wrapper`} dangerouslySetInnerHTML={{ __html: this.props.children }}/>;
    }
    return this.props.children ? this.props.children : null;
  }

  get iconName() {
    return ICON_MAP[this.props.name] || this.props.name;
  }

  render() {
    if (!this.props.name) {
      return null;
    }

    return (
      <div className={`ico-container adx-ico ${this.iconName} ${this.classes}`}>
        <SVG id={this.iconName} />
        {this.children}
      </div>
    );
  }
}
Icon.defaultProps = {
  icoAfterChildren: false,
  name: null,
  direction: 'row',
  className: null,
  size: 100,
  children: null,
  transform: null,
};


Icon.propTypes = {
  icoAfterChildren: PropTypes.bool,
  name: PropTypes.string,
  direction: PropTypes.oneOf([
    'row', 'column',
  ]),
  className: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.oneOf([87, 100, 150, 200, 250, 300]),
    PropTypes.oneOf(['87', '100', '150', '200', '250', '300']),
  ]), // Percentage of 1em (250 = 2.5x baseline)
  children: PropTypes.any,
  transform: PropTypes.oneOf([
    { flip: PropTypes.string },
    { rotate: PropTypes.string },
  ]),
};

export default Icon;
