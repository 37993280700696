import React from 'react';
import CompanyLogo from '@active-channel/images/header-logo.svg';
import Link from '@components/base_link/component';
import { Badge, Icon } from 'audamatic-ui';
import PropTypes from 'prop-types';
import Container from '@components/ui_container/component';
import Text from '@components/ui_text/component';
import { HEADER } from '@config';
import './styles.scss';

const MinimalisticHeader = (props) => {
  const { store } = props;
  const { checkoutUrl = '/winkelmand' } = HEADER;
  const badgeNumber = store.getBasketItemsCount();

  return (
    <header className="minimalistic-header">
      <Container>
        <div className="flex-container">
          <Link to="/" className="company-logo desktop-logo">
            <CompanyLogo />
          </Link>
          <Link to={checkoutUrl} className="shoppingcart">
            <div className="shoppingbag">
              <Icon name="ShoppingBag" size="md"/>
              <div className="badge-container">
                {badgeNumber > 0 && (
                  <Badge number={badgeNumber} size="tiny"/>
                )}
              </div>
            </div>
            <Text>shopping_cart.detailed.view_cart.link</Text>
          </Link>
        </div>
      </Container>
    </header>
  );
};

MinimalisticHeader.propTypes = {
  store: PropTypes.object,
};
export default MinimalisticHeader;
