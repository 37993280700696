class pubsub {
  constructor() {
    this.events = {};
  }

  /*
   * Add a listener for an event
   * if the event is fired then these listeners are called
   */
  subscribe(event, listener) {
    // if there is no event, then create one
    if (!this.events[event]) {
      this.events[event] = [];
    }

    // add listener to event
    this.events[event].push(listener);
  }

  /*
   * fire, go, start, do
   * The event is fired
   * if there are subscribers to that event then they are executed
   */
  publish(event, data) {
    // there are no litners so leave now
    if (!this.events[event] || this.events[event].length < 1) {
      return;
    }

    // send event to all listners
    this.events[event].forEach(listener => listener(data || {}));
    return true;
  }
}

export default pubsub;
