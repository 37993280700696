class BlogModel {
  constructor(props = []) {
    const blog = props.length ? props[0] : {};
    this.components = blog.components || [];
    this.title = blog.title || '';
    this.description = blog.description || '';
    this.robots = blog.robots || '';
    this.image = blog.image || '';
    Object.assign(this, blog);
  }
}

export default BlogModel;
