import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StyledCheckBox from './_styled-checkbox/component';
import DefaultCheckBox from './_default-checkbox/component';

import './styles/style.scss';

class Checkbox extends Component {
  get component() {
    const componentsMap = {
      styled: StyledCheckBox,
      default: DefaultCheckBox,
    };
    const { variant } = this.props;
    return componentsMap[variant || 'default'];
  }

  render() {
    const Component = this.component;
    return (
      <div className="checkbox-wrapper">
        <Component {...this.props}/>
      </div>
    );
  }
}

Checkbox.propTypes = {
  variant: PropTypes.oneOf(['default', 'styled']),
  hasError: PropTypes.bool,
};

export default Checkbox;
