import React from 'react';
import Container from '../../ui_container/component';
import Link from '../../base_link/component';

import './styles.scss';

import { FOOTER, NAME } from '@config';
const { legalLinks = [] } = FOOTER;

const LegalBar = () => {
  const year =  new Date().getFullYear();

  return (
    <div className="footer-legal-bar">
      <Container>
        <div className="footer-flex">
          <span className="footer-copy">&copy; {year} {NAME}</span>
          <div className="footer-legal-links">
            {legalLinks.map((link, index) => (
              <Link key={index} to={link.url}>{link.label}</Link>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LegalBar;
