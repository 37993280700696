import React from 'react';
import PropTypes from 'prop-types';
import Container from '../../ui_container/component';
import NavigationSection from '../../ui_navigation-section/component';

import './styles.scss';

const SectionBar = ({ sections }) => {
  return (
    <div className="footer-section-bar">
      <Container>
        <div className="footer-navigation-sections">
          {sections.map((section, index) => (
            <div key={index} className="footer-section">
              <NavigationSection
                key={index}
                type="list"
                title={section.title}
                url={section.url}
                links={section.components}
                last={index === (sections.length - 1)}
                collapsible
              />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

SectionBar.propTypes = {
  sections: PropTypes.array,
};

export default SectionBar;
