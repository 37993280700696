import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Error from './Error';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: this.props.hasError || false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Error />
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  hasError: PropTypes.bool,
};

export default ErrorBoundary;
