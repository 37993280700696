import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Menu from './_menu/component';
import Link from '../../base_link/component';
import Icon from '../../base_svg-icon/component';
import { getDevice, isHtmlLink } from '@utils';
import { useEventListener } from '@utils/hooks';

import './styles/styles.scss';

const Navigation = ({ items = [], showMobileMenu, toggleMobileMenu, additionalLinks }) => {
  const history = useHistory();
  const mainItems = items.filter(item => !item.excluded);
  const excludedItems = items.filter(item => !!item.excluded);

  // Close the mobile menu on resize to prevent unintended layouts
  const handleResize = () => {
    if (showMobileMenu) {
      closeMobileMenu();
    }
  };
  useEventListener('resize', handleResize);

  const getItemByUrl = (url) => {
    return items.find(item => item.url === url) || {};
  };

  /**
   * Toggle the mega menu on desktop and the secondary panel on mobile
   * by setting a selected enu item, whcih contains the sub components.
   * Triggered on hover and by handleItemClick
   */
  const [selectedItem, setSelectedItem] = useState(null);
  const toggleMenuItem = (url) => {
    if (url) {
      const item = getItemByUrl(url);
      if (item.components?.length) {
        setSelectedItem(item);
      }
    } else {
      setSelectedItem(null);
    }
  };

  /**
   * Navigate back in mobile menu (subcategories -> categories)
   */
  const navigateBack = () => {
    setShowSubmenu(false);
  };

  const closeMobileMenu = () => {
    toggleMobileMenu({ forceClose: true });
    setShowSubmenu(false);
    toggleMenuItem(null);
  };

  /**
   * Determine which action to take when clicking on a menu item.
   * If it doesn't have any sections (components), then simply navigate to url.
   * Otherwise show mega menu or secondary panel on mobile menu.
   */
  const [showSubmenu, setShowSubmenu] = useState();
  const handleItemClick = (url) => {
    const { isMobile, isTablet } = getDevice();
    if (isMobile || isTablet) {
      const item = getItemByUrl(url);
      if (!!item.components && !!item.components.length) {
        setSelectedItem(item);
        setShowSubmenu(true);
      } else {
        !isHtmlLink(item.url) ? history.push(item.url || '/') : location.href = item.url || '/';
        closeMobileMenu();
      }
    } else {
      toggleMenuItem(null);
    }
  };

  /**
   * Ignore the onMouseLeave event when the mobile menu is shown
   */
  const handleMouseLeave = () => {
    if (!showMobileMenu) {
      toggleMenuItem(null);
    }
  };

  const renderMobileMenuHeader = ({ title, nested = false, tuckable }) => {
    return (
      <div className={`mobile-menu-header tuckable ${nested ? 'nested' : ''}  ${(tuckable && showSubmenu) ? 'tucked-away' : ''}`}>
        {title}
        <div className="close-menu menu-header-icon" onClick={closeMobileMenu}>
          <Icon name="ico-error" size={150}/>
        </div>
        {nested && (
          <div className="nav-back menu-header-icon" onClick={navigateBack}>
            <Icon name="ico-arrow-left" size={150}/>
          </div>
        )}
      </div>
    );
  };

  const renderItem = (item, index) => {
    const { highlighted, excluded, title, url = '', components = [] } = item;
    const hasMenu = !!components.length;
    const active = selectedItem?.url === url;
    const classNames = [
      highlighted && 'highlighted',
      excluded && 'excluded',
      hasMenu && 'has-menu',
      active && 'active',
    ].filter(Boolean).join(' ');
    const element = url.split('/')[1];

    if (!url) {
      return null;
    }

    return (
      <li
        key={index}
        className={classNames}
        onMouseEnter={() => toggleMenuItem(url)}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleItemClick(url)}
        data-element={element}
      >
        <Link to={url}>
          {title}
          {hasMenu && (
            <div className="menu-caret">
              <Icon name="ico-arrow-down" size={87}/>
            </div>
          )}
          <div />
        </Link>
      </li>
    );
  };

  return (
    <>
      <div
        className={`mobile-backdrop ${(showMobileMenu) ? 'visible' : ''}`}
        onClick={closeMobileMenu}
      />
      <div className={`main-navigation ${showMobileMenu ? 'visible' : ''}`}>
        {renderMobileMenuHeader({ title: 'Menu', tuckable: true })}
        <nav className="container">
          <ul className={`tuckable ${showSubmenu ? 'tucked-away' : ''}`}>
            <div className="main-nav-items" data-test-id="component-mainNavigation">
              {mainItems.map((item, index) => renderItem(item, index))}
            </div>
            <div className="exluded-nav-items">
              {excludedItems.map((item, index) => renderItem(item, index))}
              {additionalLinks.map(link => (
                <li key={link.url} className="excluded-mobile-item" onClick={closeMobileMenu}>
                  <Link to={link.url}>{link.title}</Link>
                </li>
              ))}
            </div>
          </ul>

          <div className={`nav-menu ${!!selectedItem ? 'active' : ''} ${showSubmenu ? 'visible' : ''}`}>
            {renderMobileMenuHeader({ title: selectedItem?.title, nested: true })}
            <div className="container">
              {mainItems.map((item, index) => {
                const isActive = selectedItem && (selectedItem['component-id'] === item['component-id']);
                return (
                  <Menu
                    key={index}
                    item={item}
                    toggle={toggleMenuItem}
                    closeMobileMenu={closeMobileMenu}
                    show={isActive}
                  />
                );
              })}
            </div>
          </div>

        </nav>
      </div>
    </>
  );
};

Navigation.propTypes = {
  items: PropTypes.array,
  open: PropTypes.bool,
  toggleMobileMenu: PropTypes.func,
  showMobileMenu: PropTypes.bool,
  additionalLinks: PropTypes.array,
};

export default Navigation;
