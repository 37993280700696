import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconBar from './_icon-bar/component';
import LegalBar from './_legal-bar/component';
import NewsletterBar from './_newsletter-bar/component';
import SectionBar from './_section-bar/component';
import CmsApi from '@utils/api/Cms';
import { FooterModel } from '@utils/models';
import { isBrowser } from '@utils';
import { IS_B2B } from '@config';

import './styles.scss';

const Footer = (props) => {
  const { minimalistic = false } = props;
  const [items, setItems] = useState(props.items || props.staticContext?.layout?.footer.components || []);
  useEffect(() => {
    if (isBrowser) {
      if (window.__LAYOUT_DATA__) {
        const { footer = {} } = window.__LAYOUT_DATA__ || [];
        if (footer['component-id']) {
          setItems(footer.components);
        } else if (!minimalistic) {
          fetchFooter();
        }
        delete window.__LAYOUT_DATA__.footer;
      } else {
        if (!props.items) {
          fetchFooter();
        }
      }
    }
  }, []);

  /**
   * Fetch content component from the api
   */
  const fetchFooter = () => {
    const api = new CmsApi();
    return api.fetchFooterData()
      .then((layoutData) => {
        const footer = layoutData?.[0]?.components;
        setItems(footer);
      });
  };

  const CMSData = new FooterModel(items);

  const renderB2BFooter = () => {
    return (
      <footer className="decoupled-footer">
        <div className="border-line" />
        <SectionBar sections={CMSData.sections}/>
        <LegalBar />
      </footer>
    );
  };

  const renderB2CFooter = () => {
    return (
      <footer className="decoupled-footer">
        <NewsletterBar />
        <SectionBar sections={CMSData.sections}/>
        <IconBar />
        <LegalBar />
      </footer>
    );
  };

  const renderB2CMinimalisticFooter = () => {
    return (
      <footer className="decoupled-footer">
        <div className="border-line" />
        <IconBar />
        <LegalBar />
      </footer>
    );
  };

  if (IS_B2B) {
    return renderB2BFooter();
  }
  if (minimalistic) {
    return renderB2CMinimalisticFooter();
  }
  return renderB2CFooter();
};

Footer.propTypes = {
  staticContext: PropTypes.object,
  items: PropTypes.array,
  minimalistic: PropTypes.bool,
};


export default Footer;
