import Restapi from './api';
import Pubsub from './pubsub.js';
import { getLineItems } from './basket.js';
import {
  promotionGoogleTag,
  promotionListGoogleTag,
  productListingGoogleTag,
} from './analytics';
import { URLS, ISH_ID } from '@config';
import { isBrowser, log } from '.';

const restapi = new Restapi();

const modal = {};

/**
 * set all the found products to the store
 *
 * In HTML
 * <script data-productid="9789048839063" type="application/json">{"thaData":"For this product"}</script>
 *
 *
 */
const crawlForProducts = () => {
  const productElements = document.querySelectorAll(`script[data-productid]`);
  [...productElements].forEach((element) => {
    const sku = element.getAttribute('data-productid');
    // asume the fist found sku is the default sku
    if (!store.getDefaultSku()) {
      store.setDefaultSku(sku);
    }
    if (!store.hasProduct({ sku })) {
      try {
        const product = JSON.parse(element.innerHTML);
        // store the product in the store
        store.setProduct({ sku, product });
      } catch (e) {
        log('The product was not valid json ', sku);
      }
    }
  });
};


const getStore = () => {
  const cartUrl = '/winkelmand';
  const loginUrl = '/inloggen';
  const myEnvUrl = URLS.myAccount;
  const logoutUrl = '/logout';
  // main page url
  const startURL = `/`;
  // Credit payment page url
  const creditPaymentUrl = `/INTERSHOP/web/WFS/${ISH_ID}/nl_NL/-/EUR/ViewContent-Start?PageletEntryPointID=pg_credit-payment&`;

  /**
   * async basketloader
   *
   * Only load the basket once, if the promise is still pending it doens not load again
   */
  let basketloader;

  /**
   * Load a third party async script
   *
   * you can use it in different ways:
   *
   * The script is loaded and sets a promise
   * store.loadScriptAsync({key:'myLib',uri:'//mylib.com'});
   * ... later
   * store.externalScripts['myLib'].then(doSomeMethod)
   *
   * Do it at once
   * store.loadScriptAsync({key:'myLib',uri:'//mylib.com'}).then(doSomeMethod);
   *
   * Use the uri as key
   * store.loadScriptAsync({uri:'//mylib.com'}).then(doSomeMethod);
   *
   * Only listen to the promise to be fullfilled
   * store.loadScriptAsync({key:'myLib'}).then(doSomeMethod);
   *
   * It returns a promise.
   * @param key
   * @param uri
   * @param defer
   * @param async
   * @returns {*}
   */
  const loadScriptAsync = (props) => {
    if (typeof document === 'undefined') {
      return;
    }

    let { key, uri, defer = false, async = true } = props;
    if (!key) {
      key = uri;
    }
    if (!!key && !uri) {
      return store.externalScripts[key];
    }
    if (!store.externalScripts[key]) {
      const promise =  new Promise((resolve, reject) => {
        const scriptTag = document.createElement('script');

        scriptTag.src = uri;
        scriptTag.defer = defer;
        scriptTag.async = async;

        scriptTag.onload = resolve;
        scriptTag.onreadystatechange = resolve;
        scriptTag.onerror = reject;

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
      });
      store.externalScripts[key] = promise;
    }
    return store.externalScripts[key];
  };

  return {
    modal,
    _basket: {},
    _products: {}, // store the products that are used for components key = sku
    headerHeight: 0, // this is the fallback, should be updated by base sticky header
    _defaultSku: undefined, // this stores the sku of the actove product (most likeley)
    setDefaultSku: sku => store._defaultSku = sku,
    getDefaultSku: () => store._defaultSku,
    getBasket: () => store._basket,
    getBasketItems: () => getLineItems(store._basket),
    getBasketProduct: (sku) => {
      const items = store.getBasketItems();
      return items.find(product => product.sku === sku) || {};
    },
    /**
     * This uses the Quantity attr of the lineitems
     * This is not the same as getBasketItems.length
     */
    getBasketItemsCount: () => {
      let count = 0;
      const lineItems = store.getBasketItems();
      lineItems.forEach((lineItem) => {
        if (lineItem.quantity) {
        // if quantity is present then add quantity.value to count
          count += lineItem.quantity.value || 0;
        } else {
        // if quantity is not present just add 1
          count++;
        }
      });
      return count;
    },

    getBasketTotals: () => {
      return store._basket.totals || {};
    },

    getBasketTotalsShipping: () => {
      return (store.getBasketTotals().shippingTotal ? store.getBasketTotals().shippingTotal.value : 0);
    },

    getBasketTotal: () => {
      return (store.getBasketTotals().basketTotal ? store.getBasketTotals().basketTotal.value : 0);
    },

    deliveryDateIsExpired: () => {
      const { deliveryDatesExpired } = store._basket;
      const newExpireDate = new Date(deliveryDatesExpired);
      return new Date() > newExpireDate;
    },
    setBasket: (basket) => {
      store._basket = basket;
      return basket;
    },
    loadBasket: ({ refresh = true, origin = 'store' } = {}) => {
    // basketloader is declared outside this class
      if (!basketloader || refresh) {
        basketloader = new Promise((resolve) => {
          restapi.getActiveBasket({ origin })
            .then((basket) => {
              store.setBasket(basket);
              return store.getBasket();
            })
            .then(resolve);
        });
      }
      return basketloader;
    },
    getProduct: ({ sku }) => store._products[sku ? sku : store.getDefaultSku()] || null,
    setProduct: ({ sku, product }) => store._products[sku] = product,
    hasProduct: ({ sku }) => (sku in store._products),
    storedProducts: () => store._products,

    /*
     * repace the array of sku's in the products of srcObject
     * it is a reference so the src object is changed
     */
    productsFill: ({ srcObj, sku }) => {
    // For grids with items
      if (srcObj?.items) {
        srcObj.items.forEach((item, i, arr) => {
          if (item.product && store.hasProduct({ sku: item.product })) {
            const storedProduct = store.getProduct({ sku: item.product });
            delete item.product;
            arr[i] = { ...storedProduct, ...item };
          }
        });
      }

      // if there is a sku then the product attributes are stored in the product attribute
      if (sku && srcObj) {
        srcObj.product = store.getProduct({ sku });
      }

      if (srcObj && srcObj.products && Array.isArray(srcObj.products)) {
        srcObj.products.forEach((product, i, arr) => {
          if (typeof product === 'string' && store.hasProduct({ sku: product })) {
            arr[i] = store.getProduct({ sku: product });
          }
        });
      }

      if (srcObj?.productSelection && Array.isArray(srcObj.productSelection)) {
        srcObj.productSelection.forEach((product, i, arr) => {
          if (typeof product === 'string' && store.hasProduct({ sku: product })) {
            arr[i] = store.getProduct({ sku: product });
          }
        });
      }

      // go recursice
      Object.keys(srcObj)
        .forEach((deeperObjKey) => {
          const deeperObj = srcObj[deeperObjKey];
          if (deeperObj && deeperObj.constructor === Object) {
            store.productsFill({ srcObj: deeperObj });
          }
        });
    },


    events: isBrowser ? new Pubsub() : { subscribe: () => {}, publish: () => {} },
    update: (key, value) => {
      store[key] = value;
    },
    urls: {
      cart: cartUrl,
      login: loginUrl,
      myEnv: myEnvUrl,
      logout: logoutUrl,
      start: startURL,
      creditPayment: creditPaymentUrl,
    },
    apiKeys: {
      captcha: {
        prod: '6LeG57MUAAAAALM6BEesG2uRCHUoSzfeGq1-2c5Y',
        acc: '6LeG57MUAAAAALM6BEesG2uRCHUoSzfeGq1-2c5Y',
        test: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        dev: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      },
      books: {
        prod: 'AIzaSyCE5feXmJiTUV3q1i2QoSuOL6Oa3qtVtkY',
        acc: 'AIzaSyCE5feXmJiTUV3q1i2QoSuOL6Oa3qtVtkY',
        test: 'AIzaSyCE5feXmJiTUV3q1i2QoSuOL6Oa3qtVtkY',
        dev: 'AIzaSyCE5feXmJiTUV3q1i2QoSuOL6Oa3qtVtkY',
      },
    },
    currencySign: '€',
    campaignLocalStorageKey: 'campaignCode',
    pushToDataLayer: event => new Promise((resolve) => {
      window.dataLayer = window.dataLayer || [];
      resolve(window.dataLayer.push(event));
    }),
    _analyticsPromotionsList: [],
    registerAnalyticsPromo: promotion => store._analyticsPromotionsList.push(promotion),
    trigerOnloadAnalyticsTags: () => {
      const promosList = [];
      const productList = [];
      let promoIndex = 0;
      Promise.all(store._analyticsPromotionsList)
        .then((components) => {
          for (const el of components.entries()) {
            if (el.events && el.events.indexOf('promotionList') !== -1) {
              promoIndex++;
              promosList.push(promotionGoogleTag(el.component, promoIndex));
            }
            if (el.events && el.events.indexOf('productList') !== -1) {
              productList.push(el.component);
            }
          }
          !!promosList.length && store.pushToDataLayer(promotionListGoogleTag(promosList));
          !!productList.length && store.pushToDataLayer(productListingGoogleTag({ productList }));
        });
    },
    externalScripts: {},
    loadScriptAsync,
  };
};

const store = getStore();

if (isBrowser) {
  crawlForProducts();
}

export default store;
