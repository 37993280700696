import * as React from 'react';
import PropTypes from 'prop-types';
import './styles/styles.scss';

const Typeset = (props) => {
  const { testId } = props;
  const El = props.tag;
  const properties = { ...props };
  const generateClass = (prop, value) => {
    const map = {
      type: `ts-${value}`,
      bold: `ts-${prop}`,
      italic: `ts-${prop}`,
      tight: `ts-${prop}`,
      secondary: `ts-${prop}`,
      color: `text-color-${value}`,
      scale: `scale-${value}`,
      scaleSm: `scale-sm-${value}`,
      scaleMd: `scale-md-${value}`,
      scaleLg: `scale-lg-${value}`,
      collapse: `ts-collapsed`,
    };
    return value ? map[prop] : '';
  };
  const classes = Object.entries(properties).map(([prop, val]) => {
    return generateClass(prop, val);
  })
    .filter(Boolean)
    .join(' ');

  return (
    <El className={classes} data-test-id={testId}>
      {props.children}
    </El>
  );
};

export default Typeset;


Typeset.propTypes = {
  tag: PropTypes.string,
  type: PropTypes.string,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  tight: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.string,
  scale: PropTypes.string,
  scaleSm: PropTypes.string,
  scaleMd: PropTypes.string,
  scaleLg: PropTypes.string,
  testId: PropTypes.string,
};

Typeset.defaultProps = {
  tag: 'span',
  bold: false,
  italic: false,
  tight: false,
  scale: '1',
};
