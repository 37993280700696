import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { isHtmlLink, isBrowser } from '@utils';
import './styles.scss';

/**
 * The Link component is used for navigation within the application.
 * When build as a single page application, we need react-router's Link
 * and for the regular assets we need the good ol' <a/>.
 *
 * NOTE:
 * - The 'href' prop can be used, but should be phased out in favor of 'to'.
 */

const Link = forwardRef((props, ref) => {
  const { href, to, shortcut, testID, rel, ...rest } = props;
  let link = to || href || '/';
  // If link links to current host or localhost, make path relative
  if (isBrowser && link.includes('://')){
    const { host, pathname } = new URL(link);
    if (host === location?.host || LOCAL) {
      link = pathname;
    }
  }

  return (
    !isHtmlLink(link) ? (
      <NavLink
        data-test-id={testID}
        to={shortcut || link}
        {...rest}
        innerRef={ref}
        activeClassName="is-active"
        rel={rel}
        exact
      >{props.children}</NavLink>
    ) : (
      <a data-test-id={testID} href={link} rel={rel} {...rest} ref={ref}>{props.children}</a>
    )
  );
});

Link.propTypes = {
  to: PropTypes.string, // preferred
  href: PropTypes.string,
  shortcut: PropTypes.string,
  children: PropTypes.any,
  exact: PropTypes.bool,
  testID: PropTypes.string,
  rel: PropTypes.string,
};

Link.displayName = `routeLink`;

export default Link;
