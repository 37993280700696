import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Link from '../../base_link/component';
import SearchBar from './_search-bar/component';
import Action from './_action/component';
import AccountMenu from './_acount-menu/component';
import MiniBasket from './_mini-basket/component';
import Icon from '../../base_svg-icon/component';
import NotificationBar from '../_notification-bar/component';
import HeaderContext from '../HeaderContext';
import { isBrowser, appStorage, checkIfTwoArraysAreEqual } from '@utils';
import { useEventListener } from '@utils/hooks';
import Text from '@text';
import { HEADER, IS_B2B } from '@config';
import { useAuth } from '@utils/authentication/AuthProvider';
import { useApp } from '../../../ssr/shared/application-context';
import { USER_STORAGE, GUEST_USER_STORAGE, TOKEN_STORAGE } from '@constants';

if (isBrowser) {
  require('intersection-observer');
}

import CompanyLogo from '@active-channel/images/header-logo.svg';

import './styles.scss';

const Masthead = () => {
  const ctx = useContext(HeaderContext);
  const { store, toggleMobileMenu } = ctx;
  const appCtx = useApp();
  const { setPreviousUrl } = appCtx;
  const history = useHistory();
  const auth = useAuth();
  const stacked = !!HEADER.components.includes('accountBar');

  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const toggleAccountMenu = () => {
    setShowAccountMenu(showAccountMenu => !showAccountMenu);
  };

  useEffect(() => {
    setObserver();
    store.loadBasket({ refresh: true, origin: 'header:masthead' })
      .then(setBasketValues);
  }, []);

  useEffect(() => setHeight());

  const mastHeadRef = useRef(null);
  const placeholderHeadRef = useRef(null);

  const setHeight = () => {
    let mastheadHeight = mastHeadRef.current.clientHeight || 0;
    // catch exceptions
    if (mastheadHeight > 150) {
      mastheadHeight = 100;
    }
    const stackedHeight = stacked ? 37 : 0;
    placeholderHeadRef.current.style.height = `${mastheadHeight + stackedHeight}px`;
  };

  useEventListener('resize', setHeight);

  const setPlaceholderHeight = (entries = [{}]) => {
    const { isIntersecting } = entries[0];
    if (isIntersecting) {
      mastHeadRef.current.classList.remove('fixed-masthead');
    } else {
      mastHeadRef.current.classList.add('fixed-masthead');
    }
  };

  const setObserver = () => {
    const observer = new IntersectionObserver(setPlaceholderHeight, { threshold: 1 });
    observer.observe(placeholderHeadRef.current);
  };

  const [loggedIn, setLoggedIn] = useState(!!auth.user);
  const [username, setUsername] = useState(auth.user?.firstName || '');
  const [basketCount, setBasketCount] = useState(null);
  const setBasketValues = (updatedBasket) => {
    if (!updatedBasket) {
      return;
    }

    const { user, usergroups, guestCheckoutSelected, shippingBuckets } = updatedBasket;

    /*
     * If we log the user in as part of guest account we do not want customers to know
     * So in the frontend we pretend they are not logged in
     */
    if (!guestCheckoutSelected) {
      setUsername(user?.firstName || '');
      setLoggedIn(!!user);
    }

    /*
     * If customer deletes basket when being logged in as guest account
     * we want to make sure we reset the authentication token and preferred store
     * so when they enter checkout with new items they are not already in the guest checkout flow
     */
    if (guestCheckoutSelected && !shippingBuckets) {
      localStorage.removeItem(TOKEN_STORAGE);
      localStorage.removeItem(GUEST_USER_STORAGE);
      localStorage.setItem('guestCheckoutSelected', false);
    }

    // Log user out if the B2B basket is ever fetched without a user
    if (IS_B2B && !user) {
      auth.logout();
    }

    if (user) {
      if (!sessionStorage.getItem('activeSession')) {
        sessionStorage.setItem('activeSession', 'true');
        setTimeout(() => store.events.publish('updatedWishlist'), 1000);
      }
    }

    /*
     * Update the usergroups of the user in the appstorage with the newly fetched usergroups for B2B.
     * To make sure that changes that were made in the backoffice are always represented in the frontend.
     * Without the user having to logout and login again.
     */
    if (IS_B2B && user) {
      const userData = appStorage.getItem(USER_STORAGE);
      const userDataJson = JSON.parse(userData);

      /*
       * Compare the stored usergroups and the ones coming from backoffice
       * When not the same update the data in localstorage
       */
      const userGroupsAreTheSame = checkIfTwoArraysAreEqual(userDataJson?.usergroups, usergroups);
      if (!userGroupsAreTheSame) {
        const newUserData = {
          ...userDataJson,
          usergroups,
        };
        appStorage.setItem(USER_STORAGE, JSON.stringify(newUserData));
      }
    }

    const numberOfItemsInBasket = store.getBasketItemsCount();
    if (numberOfItemsInBasket !== basketCount) {
      setBasketCount(numberOfItemsInBasket);
    }
  };

  store.events.subscribe('updateBasket', () => {
    const basket = store.getBasket();
    setBasketValues(basket);
  });


  const {
    wishListUrl = '/wishlist',
    checkoutUrl = '/winkelmand',
    loginUrl = '/inloggen',
  } = HEADER;

  // Save the page the user is on before login in the appcontext so we can use it to redirect to
  const navigateToLoginPage = () => {
    const currentPage = window.location.pathname + window.location.search;
    setPreviousUrl(currentPage);
    history.push(loginUrl);
  };

  const preTitle = IS_B2B ? (auth.user?.address?.company || '') : (
    <>
      <Text>header.account.greeting</Text> <span data-test-id="userName">{username}</span>
    </>
  );

  return (
    <div ref={placeholderHeadRef} className="masthead-container">
      <div ref={mastHeadRef} className={`masthead ${stacked ? 'stacked' : ''}`}>
        <NotificationBar key="not-bar" className="mobile" />
        <div className="container">
          <div className="masthead-flex">
            <Link to="/" className="company-logo desktop-logo">
              <CompanyLogo />
            </Link>
            <div className="masthead-search">
              <button className="masthead-menu-button" onClick={toggleMobileMenu}>
                <Icon name="ico-menu" size={150} />
                <span><Text>header.mobile.menu.text</Text></span>
              </button>
              <SearchBar store={store} />
            </div>
            <div className="masthead-actions">
              {loggedIn ? (
                <Action
                  icon="ico-user-alt"
                  showIcon={false}
                  title={(<Text>account.login_status.myaccount.link</Text>)}
                  preTitle={preTitle}
                  onMouseEnter={toggleAccountMenu}
                  onMouseLeave={toggleAccountMenu}
                  onClick={toggleAccountMenu}
                  hasChildren
                  testId="userStatusLoggedIn"
                >
                  {showAccountMenu && (
                    <AccountMenu />
                  )}
                </Action>
              ) : (
                <Action
                  icon="ico-user-alt"
                  showIcon={false}
                  title={(<Text>account.login.link</Text>)}
                  url={loginUrl}
                  testId="userStatusLoggedOut"
                  onClick={navigateToLoginPage}
                />
              )}

              <Action
                icon="ico-heart-outline"
                title={(<Text>myenv.wishlist.title</Text>)}
                url={wishListUrl}
                testId="masthead-wishlist"
              />

              <Action
                icon="ico-cart"
                title={(<Text>shopping_cart.detailed.view_cart.link</Text>)}
                url={checkoutUrl}
                badgeNumber={basketCount}
              >
                <MiniBasket />
              </Action>
            </div>
            <Link to="/" className="company-logo">
              <CompanyLogo />
            </Link>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Masthead;
