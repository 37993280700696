import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'debounce';
import Suggestion from './_suggestion/component';
import { Button } from 'audamatic-ui';
import Icon from '../../../base_svg-icon/component';
import Restapi from '@utils/api';
import { genericGoogleTag } from '@utils/analytics';
import { getDevice, getParametersFromUrl } from '@utils';
import { routerHOC } from '@utils/hoc';
import Text from '@text';
import { t } from '@utils/i18n';
import { SEARCHPAGE_ID } from '@constants';

import './styles.scss';

/**
 * This component is rendered in the header of the website.
 * It shows a filtered subset of products based on the keyword
 * entered by the user on the search form input.
 * Note: moved this file to this dir. Could use a refactor.
 */
class SearchSuggestions extends Component {
  constructor(props) {
    super(props);

    // The minimum amount of characters that need to be entered before the search is executed.
    this.minimumSearchCharacterLength = 3;
    this.urlParams = getParametersFromUrl();

    this.state = {
      searchTerm: this.urlParams?.SearchTerm || '',
      fetching: false,
      focused: false,
      drawerOpen: false,
      isMouseOver: false,
      selectedResult: null,
      searchResults: {},
      lastPressedKey: null,
      initialValue: '',
      submitted: false,
      mobileSearchOpen: false,
      isMobile: false,
      isTablet: false,
      isDesktop: true,
      maxResults: {
        searchTerms: 6,
        persons: 3,
        categories: 2,
        products: 2,
      },
      placeholder: t('header.search.box.placeholder'),
    };

    this.itemIndex = 0;
    this.restapi = new Restapi();
    this.searchInput = createRef();
    this.searchHeader = createRef();
  }

  /**
   * we'll detect if we should render a mobile view for the search.
   */
  componentDidMount() {
    this.setMaxResults();
    this.setScreenSize();
    window.addEventListener('resize', this.setScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreenSize);
  }

  setScreenSize = () => {
    const { isMobile, isTablet } = getDevice();
    const isDesktop = !(isMobile || isTablet);
    this.setState(prevState => ({ ...prevState, isMobile, isTablet, isDesktop }));
  };

  autocompleteSearch = debounce(() => {
    const { searchTerm } = this.state;

    this.restapi
      .getSearchSuggestions(searchTerm)
      .then(this.updateResults)
      .catch(this.handleRestApiError);
  }, 200);

  /**
   * Determine the number of search suggestions to show based on
   * the height of the window.
   */
  setMaxResults = () => {
    let maxResults;
    const height = window.innerHeight;
    if (height > 800) {
      maxResults = { searchTerms: 5, persons: 2, categories: 2, products: 2 };
    } else if (height > 650) {
      maxResults = { searchTerms: 4, persons: 2, categories: 1, products: 2 };
    } else if (height > 450) {
      maxResults = { searchTerms: 2, persons: 1, categories: 1, products: 2 };
    } else {
      maxResults = { searchTerms: 2, persons: 1, categories: 1, products: 0 }; // landscape mobile
    }
    this.setState({ maxResults });
  };

  /**
   * Method called to handle the rest errors for the search suggestions.
   *
   * @param {Object} response
   */
  handleRestApiError = (response) => {
    if (response.status === 204) {
      this.setState({
        fetching: false,
        searchResults: {},
        drawerOpen: false,
      });
    }
  };

  /**
   * Handles the onfocus and onblur events from the search input and updates the state
   *
   * @param {Event} e
   */
  handleInputFocus = (e) => {
    const focused = e.type === 'focus';
    const newState = { focused };

    if (!this.state.isDesktop && !this.state.mobileSearchOpen) {
      newState.mobileSearchOpen = true;
    }

    this.setState(
      {
        ...newState,
      },
      () => {
        if (this.state.focused) {
          document.addEventListener('keydown', this.handleKeyDown);
        } else {
          document.removeEventListener('keydown', this.handleKeyDown);
        }
      },
    );
  };

  /**
   * Event handler that sets the selected search suggestion
   * based on mouse over event
   *
   * @param {number} i
   * @returns {this} this
   */
  handleSuggestionMouseHover = i => this.setState({ selectedResult: i });

  /**
   * Event handler that sets the hover state of the suggestions drawer
   * for both mouseEnter e mouseLeave events
   *
   * @param {MouseEvent} e
   */
  handleDrawerMouseEvent = (e) => {
    this.setState({
      isMouseOver: e.type === 'mouseenter',
    });
  };

  /**
   * Event handler that updates the current search term and search
   * suggestions drawer based on the search input text change events
   *
   * @param {Event} e
   */
  handleSearch = (e) => {
    const { value } = e?.target || {};
    const hasValidValue = !!value && value !== '' && value.length >= this.minimumSearchCharacterLength;

    this.setState(prevState => (
      {
        searchTerm: value,
        initialValue: value,
        fetching: !!hasValidValue,
        drawerOpen: !!hasValidValue,
        searchResults: !!hasValidValue ? prevState.searchResults : {},
      }),
    !!hasValidValue ? this.autocompleteSearch : null);
  };

  /**
   * Event handler that sets the select result and current search term
   * state based on the keyDown keyUp and Enter keyboard events
   * Down will wrap from bottom to top
   * Up will not wrap from top to bottom but instead unselect, otherwise there is no escape back to search
   *
   * @param {KeyboardEvent} e
   */
  handleKeyDown = (e) => {
    const { searchResults, selectedResult, searchTerm } = this.state;

    let newSelection = selectedResult;
    let newSearchTerm = searchTerm;

    if (e.key === 'Enter') {
      this.handleEnter(e);
    } else {
      switch (e.key) {
      case 'ArrowDown':
        newSelection = this.handleArrowDown(e, searchResults, selectedResult);
        break;

      case 'ArrowUp':
        newSelection = this.handleArrowUp(e, searchResults, selectedResult);
        break;

      default:
        newSearchTerm = searchTerm;
        newSelection = selectedResult;
        break;
      }

      this.setState(
        {
          selectedResult: !!searchResults ? newSelection : null,
          searchTerm: newSearchTerm,
          lastPressedKey: e.key,
        },
        this.submitForm,
      );
    }
  };

  /**
   * When the selection reaches the bottom of the list, it will wrap back around to the top again
   *
   * @param {KeyboardEvent} e
   * @param {string[]} searchResults
   * @param {?number} selectedResult
   * @returns {number}
   */
  handleArrowDown(e, searchResults, selectedResult) {
    e.preventDefault();
    let newSelection = selectedResult;

    if (!searchResults) {
      return newSelection;
    }

    if (selectedResult === null) {
      newSelection = -1;
    }

    newSelection++;

    if (newSelection > this.countTotalResults()) {
      newSelection = 1;
    }

    return newSelection;
  }

  /**
   * When the selection reaches the top, it will nullify the selectedResult.
   *
   * @param {KeyboardEvent} e
   * @param {string[]} searchResults
   * @param {?number} selectedResult
   * @returns {number} newSelection
   */
  handleArrowUp(e, searchResults, selectedResult) {
    e.preventDefault();

    let newSelection = selectedResult;

    if (!searchResults) {
      return newSelection;
    }

    if (selectedResult === null) {
      newSelection = 0;
    }

    newSelection--;

    if (newSelection < 0) {
      newSelection = null;
    }

    return newSelection;
  }

  /**
   * When an empty search string is detected, we'll pre fill it with a '*',
   * this is to prevent the search engine from throwing an error.
   * Otherwise adjust the search term to the selected search result.
   * If there is no selected search result then we return the current search string as is.
   *
   * @param {KeyboardEvent} e - the keyboard event
   */
  handleEnter(e) {
    e.preventDefault();
    this.submitOrRedirect();
  }

  /**
   * This will check if there is an item selected from the suggest box
   * - if nothing is selected, it will use the current search input as term and submit the form
   * - Otherwise
   *   - It will trigger the GTM to record the search results and then
   *     - if it's a searchTerm it will update the state and submit the form
   *     - if it's a product, it will check of it has an url and if so, redirect to that, otherwise,
   *       it will use the name of the product as search term and submit the form.
   */
  submitOrRedirect = () => {
    const currentSelection = this.getCurrentSelection();
    const { searchTerm } = this.state;

    let newSearchTerm = searchTerm.trim();

    if (currentSelection !== null) {
      this.gtmTrigger(currentSelection.type, currentSelection.scopedIndex);
      const item = this.state.searchResults[currentSelection.type][currentSelection.scopedIndex];

      if (typeof (item) === 'string') {
        newSearchTerm = item;
      } else {
        if (!!item.url) {
          return this.goToResults({ url: item.url, redirect: true });
        }
        newSearchTerm = item.name;
      }
    } else if (currentSelection === null && !searchTerm.trim()) {
      newSearchTerm = '*';
    }


    // fallthrough, update searchTerm and submit.
    this.setState({
      selectedResult: null,
      searchTerm: newSearchTerm,
    }, this.goToResults);
  };

  goToResults = ({ url, redirect } = {}) => {
    const { store, history } = this.props;
    const { searchTerm = '*' } = this.state;
    const search = searchTerm || '*';
    // encode the search term to be used in the url
    const encodedSearch = encodeURIComponent(search);

    this.dismissMobileSearchOverlay();

    this.searchInput.current.blur();
    if (redirect) {
      const link = new URL(url);
      return history.push(link.pathname);
    }
    const isSearchPage = document.body.id === SEARCHPAGE_ID;
    if (isSearchPage) {
      store.events.publish('newSearch', { search });
      return history.replace(`/zoeken/${encodedSearch}`);
    }
    return history.push(`/zoeken/${encodedSearch}`);
  };

  /**
   * Takes care of submitting the form when its either detecting "enter" or if called explicitly with 'true'
   *
   * @param {boolean} [force]
   */
  submitForm = (force) => {
    const { lastPressedKey, searchTerm } = this.state;

    if (lastPressedKey === 'Enter' || !!force) {
      this.setState((prevState) => {
        const search = !searchTerm.trim() ? '*' : prevState.searchTerm;
        return ({
          searchTerm: search,
        });
      }, () => {
        this.gtmTrigger();
        this.goToResults();
      });
    }
  };

  /**
   * This will get the information of the current selected item from the suggestion box
   * This information can be used to get the item from the state.searchResults
   * If there is no selection, it will return null
   *
   * @example
   * const currentSelection = this.getCurrentSelection();
   * const currentSelectedItem = this.state.searchResults[currentSelection.type][currentSelection.scopedIndex];
   *
   *
   * @typedef {?Object} selectedElement
   * @property {number} scopedIndex
   * @property {number} index
   * @property {string} type
   *
   * @returns {?selectedElement}
   */
  getCurrentSelection() {
    const elements = document.getElementsByClassName('suggestion active');
    if (!elements.length) {
      return null;
    }

    return {
      type: elements[0].getAttribute('data-item-type'),
      index: parseInt(elements[0].getAttribute('data-item-index'), 10),
      scopedIndex: parseInt(elements[0].getAttribute('data-item-scoped-index'), 10),
    };
  }


  /**
   * Triggers the google tag manager to hydrate it with search suggestions statistics
   *
   * @param {string} type - the item type
   * @param {number} scopedIndex - the index of the item within its group
   */
  gtmTrigger = (type = 'searchTerm', scopedIndex = 0) => {
    const { searchResults = {}, selectedResult, initialValue } = this.state;
    const data = {
      input: initialValue,
      position: !!selectedResult ? selectedResult : null,
      products: !!searchResults.products ? searchResults.products.map(p => p.name) : null,
      categories: !!searchResults.categories ? searchResults.categories.map(c => c.name) : null,
      persons: !!searchResults.persons ? searchResults.persons.map(p => p.name) : null,
      searchTerms: !!searchResults.searchTerms ? searchResults.searchTerms : null,
      selectedType: type,
      selectedPosition: scopedIndex + 1, // requested by SEO to use 1 based instead of 0 based position
      totalSuggestions: this.countTotalResults(),
    };
    this.props.store.pushToDataLayer(
      genericGoogleTag('searchSuggestion', data),
    );
  };

  /**
   * Function invoked by the search suggestion api promise resolution.
   * It populates the state with the search results
   *
   * @param {Object} response - response of the search api
   */
  updateResults = (response) => {
    this.setState({
      fetching: false,
      searchResults: this.trimResults(response),
    });
  };

  /**
   * We will apply a budget to the search results.
   * In the constructor you can set the maximum amount of entries to show for the different search results.
   * We are using a dual pane for rendering, on the left, only searchTerms will be shown.
   * On the right we will show both Category and Person results.
   * Together they have a budget which we can divide between them.
   * For example if we have less persons than the max allowed, we can show more categories and vice versa.
   *
   * @param searchResults
   * @returns {{persons: Array, searchTerms: Array, categories: Array, products: Array}}
   */
  trimResults = (searchResults) => {
    const { categories = [], persons = [], searchTerms = [], productSuggests = [] } = searchResults;
    const { maxResults } = this.state;
    let maxResultsForCategories = maxResults.categories;
    let maxResultsForPersons = maxResults.persons;

    if (persons.length < maxResults.persons) {
      const leftOver = maxResultsForPersons - persons.length;
      maxResultsForCategories += leftOver;
    }

    if (categories.length < maxResults.categories) {
      const leftOver = maxResultsForCategories - categories.length;
      maxResultsForPersons += leftOver;
    }

    return {
      categories: categories ? categories.slice(0, maxResultsForCategories) : [],
      persons: persons ? persons.slice(0, maxResultsForPersons) : [],
      products: productSuggests ? productSuggests.slice(0, maxResults.products) : [],
      searchTerms: searchTerms ? searchTerms.slice(0, maxResults.searchTerms) : [],
    };
  };

  /**
   * Assembles the classes for the wrapper component around the input field. this is to optimise the mobile view.
   *
   * @returns {string}
   */
  getWrapperCssClasses = () => {
    const cssClasses = ['suggestions-box-wrapper'];

    if (this.state.mobileSearchOpen) {
      cssClasses.push('open');
    }

    return cssClasses.join(' ');
  };

  /**
   * Determines if it should render input field prefixes for mobile view
   *
   * @returns {?HTMLElement[]}
   */
  maybeRenderMobileSearchInputPrefixElements() {
    if (this.state.isDesktop || !this.state.mobileSearchOpen) {
      return null;
    }

    return (
      <a href="#" onClick={this.dismissMobileSearchOverlay}>
        <Icon name="ico-arrow-circle-left" size={150} />
        <span>{this.state.isTablet && t('header.searchbox.label.back')}</span>
      </a>
    );
  }

  /**
   * Determines if it should render input field suffixes for mobile view
   *
   * @returns {JSX.Element}
   */
  maybeRenderMobileSearchInputSuffixElements() {
    if (this.state.isDesktop || !this.state.mobileSearchOpen) {
      return null;
    }

    return (
      <Fragment>
        <Button variant="primary" size="small" onClick={this.submitOrRedirect}>
          <Text>header.search.box.mobile.searchButtonText</Text>
        </Button>
      </Fragment>
    );
  }

  /**
   * takes care of cleaning up state and closing the mobile search overlay
   */
  dismissMobileSearchOverlay = () => {
    this.setState({
      searchResults: {},
      mobileSearchOpen: false,
    });
  };

  /**
   * Resets the search state back to default and puts focus back on the search input.
   */
  resetSearch = () => {
    this.setState({ searchTerm: '', searchResults: {} });
    this.searchInput.current.focus();
  };

  /**
   * counts the total entries across all the categories of the searchResults
   *
   * @returns {number}
   */
  countTotalResults = () => {
    const result = this.state.searchResults;
    const keys = Object.keys(result);

    if (!keys.length) {
      return 0;
    }

    return keys
      .map(k => result[k].length)
      .reduce((a, b) => a + b);
  };

  /**
   * If we have no persons or categories in the results, we only render search terms.
   * Otherwise we render a split pane with on the left searchTerms and on the right persons + categories.
   *
   * @returns {HTMLElement}
   */
  renderSplitOrSinglePane = () => {
    const { persons, categories } = this.state.searchResults;
    return (persons?.length + categories?.length === 0) ? this.renderSinglePane() : this.renderSplitPane();
  };

  /**
   * Just rendering the single pane searchTerm results
   *
   * @returns {HTMLElement}
   */
  renderSinglePane = () => {
    return (
      <div className={'search-results-group-wrapper'}>
        {this.renderResults('searchTerms')}
      </div>
    );
  };

  /**
   * Rendering the searchTerms on the left, Categories and Persons on the right.
   *
   * @returns {HTMLElement}
   */
  renderSplitPane = () => {
    return (
      <div className={'search-results-group-wrapper'}>
        <div className={'search-results-group-terms'}>
          {this.renderResults('searchTerms')}
        </div>
        <div className={'search-results-group-other'}>
          {['persons', 'categories'].map((type) => {
            return this.renderResults(type);
          })}
        </div>
      </div>
    );
  };

  /**
   * checks if there are any search suggestions, and if so, renders them out.
   *
   * @returns {JSX.Element}
   */
  maybeRenderSearchResults = () => {
    this.itemIndex = -1;
    let totalResults = 0;

    if ((!this.state.focused && !this.state.isMouseOver && !this.state.isMobile) || this.state.searchTerm === '') {
      return null;
    }

    Object.keys(this.state.searchResults)
      .forEach((key) => {
        totalResults += this.state.searchResults[key].length;
      });

    if (totalResults === 0) {
      return null;
    }

    return (
      <div
        className={`suggestions-box ${this.state.mobileSearchOpen ? 'open' : 'closed'}`}
        onMouseEnter={this.handleDrawerMouseEvent}
        onMouseLeave={this.handleDrawerMouseEvent}
      >
        <div className={'search-results-group'}>
          {this.renderSplitOrSinglePane()}
          {this.state.searchResults.products && this.state.searchResults.products.length > 0 && (
            <div className={'search-results-group-products'}>
              {this.renderResults('products')}
            </div>
          )}
        </div>
      </div>
    );
  };

  /**
   * renders the search results of a specific type
   *
   * @param {string} type - the type of suggestion, can be person, product, category or searchTerm
   * @returns {JSX.Element}
   */
  renderResults(type) {
    const { searchResults, searchTerm, selectedResult } = this.state;
    const { store } = this.props;
    return (
      <div className={`suggestion-type ${type}`} key={`suggestion-type-${type}`}>
        {searchResults[type] && searchResults[type].map((item, scopedIndex) => {
          this.itemIndex++;
          const formattedItem = (typeof (item) === 'string') ? { value: item } : item;
          return (
            <>
              <Suggestion
                key={`suggestion-${this.itemIndex}`}
                index={this.itemIndex}
                scopedIndex={scopedIndex}
                item={formattedItem}
                type={type}
                searchTerm={searchTerm}
                highlight={selectedResult === this.itemIndex}
                handleSuggestionMouseHover={this.handleSuggestionMouseHover}
                handleSuggestionMouseClick={this.submitOrRedirect}
                store={store}
              />
            </>
          );
        })}
      </div>
    );
  }

  setPlaceholderText = (placeholder = '') => {
    this.setState({ placeholder });
  };

  /**
   * Renders the search element.
   *
   * @returns {HTMLElement[]}
   */
  render() {
    const display = (this.state.searchTerm && this.state.searchTerm.length) ? 'flex' : 'none';
    return (
      <div className="header-search-bar">
        <div className={this.getWrapperCssClasses()}>
          <div className="search-header" ref={this.searchHeader}>
            {this.maybeRenderMobileSearchInputPrefixElements()}
            <div className="search-box-and-remove">
              <input
                ref={this.searchInput}
                className="form-search_query form-control"
                name="SearchTerm"
                type="text"
                autoComplete="off"
                data-test-id="input-searchTermSuggestion"
                value={this.state.searchTerm}
                onChange={this.handleSearch}
                onFocus={this.handleInputFocus}
                onBlur={this.handleInputFocus}
                placeholder={this.state.placeholder}
                maxLength="60"
              />
              <span style={{ display: 'none' }}>
                <Text callback={this.setPlaceholderText}>header.search.box.placeholder</Text>
              </span>
              <div
                onClick={this.resetSearch}
                className="search-clear"
                style={{ display }}
              >
                <Icon name="ico-error" />
              </div>
              <div className="search-icon" onClick={this.submitOrRedirect}>
                <Icon size={150} name="ico-search" />
              </div>
            </div>
            {this.maybeRenderMobileSearchInputSuffixElements()}
          </div>
          {this.maybeRenderSearchResults()}
        </div>
      </div>
    );
  }
}

SearchSuggestions.propTypes = {
  store: PropTypes.object,
  history: PropTypes.object,
};

export default routerHOC(SearchSuggestions);
