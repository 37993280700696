import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LazyImage from './_LazyImage.jsx';

class Image extends PureComponent {
  render() {
    const { src, style = {}, lazy = false, ...rest } = this.props;
    if (!src) {
      return null;
    }

    return (
      <LazyImage
        src={src}
        style={style}
        lazy={lazy}
        {...rest}
      />
    );
  }
}

Image.propTypes = {
  lazy: PropTypes.bool,
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.object,
};

export default Image;
