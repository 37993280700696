import React, { useState } from 'react';
import Container from '../../ui_container/component';
import { Button } from 'audamatic-ui';
import GenericForm from '../../sec_generic_form/component';
import Typeset from '../../base_typeset/component';
import Restapi from '@utils/api';
import Text from '@text';
import { t } from '@utils/i18n';
import { validateEmail } from '@utils';

import './styles.scss';

const NewsletterBar = () => {
  const handleInput = (arg, value) => {
    setEmail(value);
  };

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const formConfig = [
    {
      type: 'fields',
      children: [
        {
          name: 'newsletter',
          label: '',
          clientSideErrorMsg: '',
          helpText: '',
          hideValid: true,
          stacked: false,
          className: `input-container col-xs-12`,
          large: true,
          errorMessages: {},
          htmlAttributes: {
            value: email,
            autoComplete: 'email',
            placeholder: t('newsletter.email.field.placeholder'),
            type: 'email',
          },
        },
      ],
    },
  ];

  const [text, setText] = useState('');
  const [subscribed, setSubscribed] = useState();
  const [loading, setLoading] = useState(false);
  const subscribeToNewsletter = () => {
    const validEmail = validateEmail(email);
    if (!validEmail) {
      return setError(t('tba.form.error.invalid.RegisterUser_invalid_domain'));
    }

    setLoading(true);
    setError(null);

    const api = new Restapi();
    api.checkEmailForSubscription(email)
      .then((response) => {
        setLoading(false);
        setSubscribed(true);

        // The only indicator of the status is the localizedMessage -> TNZ
        setText(response.localizedMessage);
      })
      .catch((err) => {
        setLoading(false);
        setError(t('newsletter.email.field.validationerror'));
      });
  };

  return (
    <div className="footer-newsletter-bar">
      <Container>
        <div className="newsletter-intro">
          <Typeset tag="div" bold><Text>newsletter.header.title</Text></Typeset>
          <Typeset tag="div" tight>
            {text || (<Text>newsletter.header.message</Text>)}</Typeset>
        </div>
        <div className="newsletter-form" style={{ opacity: subscribed ? 0 : 1 }}>
          <GenericForm
            name="newsletterForm"
            formConfig={formConfig}
            isChild={true}
            updateFormDataState={handleInput}
            formErrors={{}}
          />
          <Button loading={loading} variant="primary" size="large" onClick={subscribeToNewsletter}>
            <Text>newsletter.register.button.label</Text>
          </Button>
        </div>
        <div className="newsetter-form-error">{error}</div>
      </Container>
    </div>
  );
};

export default NewsletterBar;
