import React from 'react';
import Issue from '../ui_issue/component';
import { Button } from 'audamatic-ui';

const Error = () => {
  const reload = () => {
    location.href = '/';
  };

  return (
    <div className="container">
      <Issue
        title="Helaas, er gaat iets mis."
        subtitle="Probeer de pagina te herladen of neem contact op met onze klantenservice."
      >
        <Button variant="primary" size="large" onClick={reload}>Naar homepagina</Button>
      </Issue>
    </div>
  );
};
export default Error;
