import React from 'react';
import PropTypes from 'prop-types';
import Typeset from '../base_typeset/component';
import Link from '../base_link/component';

import { Col } from '../util_grid/grid.js';

import Smiley from './assets/smiley-sad.svg';

import './styles/styles.scss';

const Issue = ({ title, subtitle, children, showLogo }) => {
  return (
    <div className="row-centered">
      <div className="col-centered">
        <div className="issue-wrapper">
          <Col xs={12} sm={2}>
            <Smiley/>
          </Col>
          <Col xs={12} sm={10}>
            {showLogo && (
              <Link to="/">
                <div className="channel-logo"/>
              </Link>
            )}
            <Typeset tag="h3">{title}</Typeset>
            {subtitle && (
              <Typeset tag="p">{subtitle}</Typeset>
            )}
            {children}
          </Col>
        </div>
      </div>
    </div>
  );
};

Issue.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  showLogo: PropTypes.bool,
};

export default Issue;
