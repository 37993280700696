import queryStringUtil from 'query-string';
import jump from 'jump.js';
import { FEATURES, LOCALE, PRICE_NOTATION_LOCALE } from '@config';

/**
 * Check if the string can be parsed as JSON
 *
 * @param {string} str
 * @returns {boolean}
 */
function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const parseSearchParameter = (searchParameter = '') => {
  if (!!searchParameter) {
    const importantParameters = searchParameter.split('&').splice(2, 100);
    if (!!importantParameters.length) {
      return importantParameters.join('&');
    }
  }
  return '';
};

/**
 * Always return the number with two decimals
 * format it with the browser local language settings
 *
 * @param {number|string} number - Representing a price
 * @param {string} locale - The locale used for formatting
 * @returns {string} representing a currency without euro
 */
function numFormatToCurrency(number, locale = PRICE_NOTATION_LOCALE) {
  if (number === 'N/A') {
    return '0.00';
  }

  let nr = number;
  // cohort it to a number
  if (!(+nr || +nr === 0)) {
    const n = typeof nr === 'string' ? nr.replace(',', '.') : nr; // replace comma with dot
    nr = parseFloat(n);
  }
  return (+nr).toLocaleString(
    locale, // setting to undefined will use browser locale
    { minimumFractionDigits: 2, maximumFractionDigits: 2 },
  );
}

/**
 * Sanitize dutch zipcode (postcode)
 *
 * @param {string} rawPostal
 * @returns {string} clean postal code
 */
function postcodeCleanNL(rawPostal) {
  const cleanPostal = rawPostal.replace(/[^0-9a-z]+/gi, '');
  let validatedPostal = '';
  let stringLength = Math.min(4, cleanPostal.length);
  // numbers
  for (let i = 0; i < stringLength; i++) {
    if (isNaN(parseInt(cleanPostal.charAt(i), 10))) {
      break;
    } else {
      validatedPostal += cleanPostal.charAt(i);
    }
  }

  // chars
  if (validatedPostal.length === 4 && cleanPostal.length > 4) {
    stringLength = Math.min(6, cleanPostal.length);
    for (let i = 4; i < stringLength; i++) {
      /*
       * is char
       * cleanPostal.charAt(i)
       */
      const char = cleanPostal.charAt(i);
      if (/[a-z]/i.test(char)) {
        validatedPostal += char.toUpperCase();
      } else {
        break;
      }
    }
  }
  return validatedPostal;
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

function parseJSON(response) {
  return response.json();
}

/**
 * determine the background color.
 * Give a light text color if the background is dark.
 * Otherwise, give a dark text color
 *
 *  @param(hexColor)
 *  @returns {string}
 */
function getTextColor(hexColor) {
  if (!!hexColor) {
    const r = parseInt(hexColor.substr(1, 2), 16);
    const g = parseInt(hexColor.substr(3, 2), 16);
    const b = parseInt(hexColor.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    // Return new color if to dark, else return the original
    return (yiq < 40) ? 'light-on-dark' : 'dark-on-light';
  }
  return 'dark-on-light';
}

/**
 * Get some value.
 * - Or get it from the attribute name from the DOM element
 * If json=true then this should be stringified json, parse this
 * else just get the value
 * - Or get the attribute from the obj (obj.objAttrName)
 * - Or return the default value
 *
 * @param {Object} ojb
 * @param {HTMLElement} obj.elm - the dom element to get the attributes from
 * @param {string} obj.attr - The attribute to get from the dom element
 * @param {Object} obj.obj
 * @param {string} obj.objAttrName
 * @param {boolean} obj.json - does the attribute contain json data
 * @param {*} defaultReturnValue
 *
 * @returns {?string | Object}
 */
const getFromAttrOrFromObject = ({ elm, attr, obj = {}, objAttrName, json, defaultReturnValue }) => {
  if (elm.getAttribute(attr) &&
    (!json || isJsonString(elm.getAttribute(attr)))
  ) {
    // there is attr in the element and it is json
    return json ? JSON.parse(elm.getAttribute(attr)) : elm.getAttribute(attr);
  } else if (obj[objAttrName]) {
    return obj[objAttrName];
  }
  return defaultReturnValue;
};

/**
 *
 * @param {string} url
 * @param {Object} params
 * @returns {string}
 */
function createUrl(url = window.location.href, params = {}) {
  const parametrize = (key, value) => {
    if (typeof value !== 'object') {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }
    if (value.constructor === Array) {
      return value.map(v => parametrize(`${key}[]`, v))
        .join('&');
    }
    return Object.keys(value)
      .map(k => parametrize(`${key}[${k}]`, value[k]))
      .join('&');
  };

  const baseUrl = url.indexOf('?') === -1 ? `${url}?` : `${url}&`;

  const queryString = Object.keys(params)
    .map(key => parametrize(key, params[key]))
    .join('&');

  return `${baseUrl}${queryString}`;
}

/**
 * Mini Mustache parser
 *
 * @example
 * miniMustache({string: 'hi [[name]] [[lastname]]', replacements:{name:'daan',lastname:'schaeffer'} });
 * // returns "hi daan schaeffer"
 *
 * if html is set it returns the object so you can use dangerouslySetInnerHTML
 * https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
 *
 * @param {Object} obj
 * @param {string} obj.string - A string with interpolation keys
 * @param {Object[]} obj.replacements - key: value - set of interpolation values
 * @param {boolean} obj.html - if the returned value contains html
 *
 * @returns {string | Object}
 */
const miniMustache = function ({ string = '', replacements, html = false }) {
  if (!replacements) {
    return html ? ({ __html: string }) : string;
  }
  const replaced = string.replace(/\[\[([^\[\]]*)]]/g, (match, matchVal) => {
    const replaceText = replacements[matchVal];
    return replaceText ? replaceText : match;
  });
  return html ? ({ __html: replaced }) : replaced;
};

/**
 * Get text for addToCartButton
 *
 * @param {string} productType - The product type that you want to get the correct text for.
 * @returns {string} - The proper string for the product type.
 */
const getTextForAddToCartButton = (productType) => {
  const prodTypeTransMap = {
    'Blu-Ray': { pronoun: 'dit', type: 'product' },
    CD: { pronoun: 'dit', type: 'product' },
    DVD: { pronoun: 'deze', type: 'film' },
    Dwarsligger: { pronoun: 'dit', type: 'boek' },
    eBook: { pronoun: 'dit', type: 'eBook' },
    Hardcover: { pronoun: 'dit', type: 'boek' },
    'Microsoft XBox 360': { pronoun: 'deze', type: 'game' },
    'Microsoft XBox One': { pronoun: 'deze', type: 'game' },
    'Nintendo Switch': { pronoun: 'deze', type: 'game' },
    'Nintendo Wii U': { pronoun: 'deze', type: 'game' },
    Paperback: { pronoun: 'dit', type: 'boek' },
    'PC CD-DVD': { pronoun: 'deze', type: 'game' },
    'Sony PlayStation 4': { pronoun: 'deze', type: 'game' },
    default: { pronoun: 'dit', type: 'product' },
  };
  const defaultObject = { pronoun: 'dit', type: 'product' };

  const product = prodTypeTransMap[productType] || defaultObject;
  return `${product.pronoun} ${product.type}`;
};

/**
 * Return all params from url
 *
 * @param {string} urlParameter - The url you want to get the query params from, defaults to current window location
 * @returns {Object} - All the query params converted to an Object.
 */
const getParametersFromUrl = (urlParameter = '') => {
  const url = urlParameter;
  const cleanUrl = url.split('#')[0];
  const queryString = cleanUrl.split('?')[1];
  let queryObj = {};
  if (queryString) {
    queryObj = queryStringUtil.parse(queryString);
    stripHTMLByValue(queryObj);
  }
  return queryObj;
};

/**
 * Format unix timestamp (epoch) to dd-mm-yyyy string
 *
 * @param {number} timestamp
 * @returns {string}
 */
const unixToDate = (timestamp = null) => {
  return new Date(timestamp).toLocaleDateString(LOCALE);
};

/**
 *
 * Format unix timestamp (epoch) to mm-yyyy string
 * @param {number} timestamp
 * @returns {string}
 */
const unixToMJDate = (timestamp = null) => {
  return new Date(timestamp).toLocaleDateString(LOCALE, { month: 'long', year: 'numeric' });
};

/**
 *
 * Format unix timestamp (epoch) to day and month written full out as string
 * @param {number} timestamp
 * @returns {string}
 */
const unixToDMDate = (timestamp = null) => {
  return new Date(timestamp).toLocaleDateString(LOCALE, { day: 'numeric', month: 'long' });
};

/**
 *
 * Format unix timestamp (epoch) written full out as string
 * @param {number} timestamp
 * @returns {string}
 */
const unixToFullDate = (timestamp = null) => {
  return new Date(timestamp).toLocaleDateString(LOCALE, { day: 'numeric', month: 'long', year: 'numeric' });
};

/**
 *
 * Return device details
 *
 * @typedef deviceInfo
 * @property {number} height - the height if the device's viewport
 * @property {number} width - the width of the device's viewport
 * @property {boolean} isMobile - if the device is a mobile or not
 *
 * @returns {deviceInfo}
 */
const getDevice = () => {
  if (typeof window === 'undefined') {
    return {};
  }

  const height = (window.innerHeight > 0) ? window.innerHeight : screen.height;
  const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;

  return {
    height,
    width,
    isMobile: width < 768,
    isTablet: width >= 768 && width < 992,
    isLaptop: width >= 992 && width < 1200,
    isDesktop: width >= 1200,
    sm: width >= 768,
    md: width >= 992,
  };
};

/**
 * Function to validate email address
 * only validates format
 *
 * @param {string} email
 * @returns {boolean}
 */
const validateEmail = (email = '') => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email)
    .toLowerCase());
};

const getSiteLocale = () =>  LOCALE;

/**
 * Get text for Credit type
 *
 * @param {string} type - the type of credit
 * @returns {string} - returns the correct translation key
 */
const getTextForCreditType = (type) => {
  let creditType = '';
  switch (type) {
  case 'Nationale Postcode Loterij':
    creditType = 'credit.displayname.npl';
    break;
  case 'Bankgiro Loterij':
    creditType = 'credit.displayname.bgl';
    break;
  case 'Vrienden Loterij':
    creditType = 'credit.displayname.vl';
    break;
  case 'CadeaubonWebsite':
    creditType = 'credit.displayname.giftcertificate';
    break;
  case 'Tegoedbon':
    creditType = 'credit.displayname.voucher';
    break;
  case 'ECIBoekbon':
    creditType = 'credit.displayname.bookvoucher';
    break;
  case 'GiftCardAndCertificate':
    creditType = 'credit.displayname.vipwallet';
    break;
  default:
    creditType = 'credit.displayname.default';
  }
  return creditType;
};

/**
 * Get the offset {top, left} of an element
 * @param {node} element
 * @returns {number{}}
 */
const getOffset = (el) => {
  if (!el) {
    return {};
  }
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

/**
 * Crossbrower alternative to scrollTo()
 * @param {object} options
 * @param {function} callback
 */
const scrollTo = ({ top = 0, behavior } = {}, callback) => {
  jump(document.body, {
    duration: (behavior === 'smooth') ? 500 : 0,
    offset: top,
    callback,
  });
};

/**
 * strips all html for the given string
 *
 * @param {string} html - the string that you want to strip the html from
 * @returns {string}
 */
const stripHTML = (html) => {
  if (!isBrowser) {
    return '';
  }
  const tmp = document.implementation.createHTMLDocument('New').body;
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

/**
 * only strip the html from the values of the given object
 * Caution, this is changing the object by reference !
 *
 * @param {Object} obj
 * @returns {void}
 */
const stripHTMLByValue = (obj) => {
  Object.keys(obj)
    .forEach(key => obj[key] = stripHTML(obj[key]));
};

/**
 * Format seconds into minutes
 * @param {number} duration
 * @returns {string}
 */
const secondsToMinutes = (duration = 0) => {
  const fullMinutes = (duration / 60).toString().split('.')[0];
  const dotSeparatedMinutes = fullMinutes.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `${dotSeparatedMinutes} min.`;
};

/**
 * Format large numbers to include seperators
 */
const formatNumber = (number = 0) => {
  const formattedNumber = parseInt(number, 10);
  return isNaN(formattedNumber) ? '0' : formattedNumber.toLocaleString(LOCALE);
};

/**
 * This converts price strings in the shape "8,99"
 * to 8.99 float number
 * @param {string} price
 */
const priceStringToFloat = (price) => {
  return parseFloat(price.replace(/,/g, '.'));
};

/**
 *  Get Average color in image
 *  @param {node} imgElement
 *  @return {string} rgba
 */
const getDominantBackgroundColor = (imgElement) => {
  const defaultColor = 'hue-default'; // for non-supporting envs
  const canvas = document.createElement('canvas');
  const context = canvas.getContext && canvas.getContext('2d');
  let data;
  const rgb = { r: 0, g: 0, b: 0 };

  if (!context) {
    return defaultColor;
  }
  context.drawImage(imgElement, 0, 0, 1, 1);

  try {
    data = context.getImageData(0, 0, 1, 1);
  } catch (e) {
    /* security error, imgElement on diff domain */
    return defaultColor;
  }

  rgb.r = data.data[0];
  rgb.g = data.data[1];
  rgb.b = data.data[2];

  return _getColorBasedOnHue(rgb);
};

/**
 * Get one of the predefined colors from our pallette (based on hue number or color name)
 * @param {any} colorNameOrHue
 * @returns {string} classname that can be used in scss (theme.scss) map
 */
const getHueClass = (colorNameOrHue) => {
  const typeOfParam = typeof colorNameOrHue;
  const colorName = typeOfParam === 'string' ? colorNameOrHue : null;
  const hueNumber = typeOfParam === 'number' && !!colorNameOrHue ? colorNameOrHue : null;

  // All available color options (could vary per channel)
  const hueBreakPoints = {
    0: 'grey',
    18: 'red',
    42: 'orange',
    64: 'yellow',
    169: 'green',
    266: 'blue',
    289: 'purple',
    343: 'pink',
    361: 'red',
    misc1: 'greyBlue', // not mapped to any hue at the moment.
  };

  if (!hueNumber || !colorNameOrHue) {
    return Object.values(hueBreakPoints).includes(colorName) ? `hue-${colorName}` : 'hue-default';
  }

  const hueNumbers = Object.keys(hueBreakPoints);

  const snapToHueNumber = hueNumbers.filter((mapNumber) => {
    return mapNumber < hueNumber;
  });

  // Hue color often matches multiple entry's. Sort it so the closest hue match is first.
  snapToHueNumber.sort((a, b) => b - a);

  const hueColor = hueBreakPoints[snapToHueNumber[0]];

  const hueName = snapToHueNumber.length > 0 ? hueColor : null;
  return `hue-${hueName ? hueName : 'default'}`;
};

/**
 * Get one of the predefined colors from our pallette
 * @param {{r, g, b}} rgb
 * @returns {string} #color
 */
const _getColorBasedOnHue = (rgb) => {
  const { r: red, g: green, b: blue } = rgb;
  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);
  let hue = 0;

  if (max === red) {
    hue = (green - blue) / (max - min);
  } else if (max === green) {
    hue = 2 + ((blue - red) / (max - min));
  } else {
    hue = 4 + ((red - green) / (max - min));
  }

  hue = hue * 60;
  if (hue < 0) {
    hue = hue + 360;
  }

  hue = Math.round(hue);
  return getHueClass(hue);
};

/**
 * Get feature from channel config and merge data into single object
 * @param {string} key
 * @return {object}
 */
const getFeature = (key = '') => {
  const { active = false, data = {} } = FEATURES[key] || {};
  return {
    isActive: active,
    ...data,
  };
};

/**
 * Checks if a product type contains one of the keywords in the list of shadowTypeParts
 * If it contains a product type (or partially) we should show the cover shadow
 * @param {string} productType
 * @return {boolean}
 */
const coverShadow = (productType) => {
  if (!productType) {
    return false;
  }

  const typeLowercase = productType.toLowerCase();

  // Make array of all words inside the product type so it does not matter if you have cd+dvd / dvd+cd / pc dvd
  const productTypes = typeLowercase.split(/[-+\s]/g);

  const shadowTypeParts = [
    'vinyl',
    'blu',
    'bordspel',
    'cd',
    'dvd',
    'dwarsligger',
    'ebook',
    'hardcover',
    'kartonboekje',
    'lp',
    'luisterboek',
    'xbox',
    'nintendo',
    'playstation',
    'paperback',
    'pc',
    'dvd',
    'pocket',
    'spiraalgebonden',
  ];
  const matches =  productTypes.filter((item) => {
    return shadowTypeParts.includes(item);
  });
  return matches.length > 0;
};

/**
 * Haversine formula to measure distance between coordinates
 * @param {object}
 * @returns {number} km
 */
const getDistance = ({ from, to }) => {
  const { lat: lat1 = 0, lng: lng1 = 0 } = from || {};
  const { lat: lat2 = 0, lng: lng2 = 0 } = to || {};
  const radlat1 = Math.PI * lat1 / 180;
  const radlat2 = Math.PI * lat2 / 180;
  const theta = lng1 - lng2;
  const radtheta = Math.PI * theta / 180;
  // eslint-disable-next-line
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  // Prevent rounding errors. dist should stay between -1 and 1
  if (dist > 1) {
    dist = 1;
  } else if (dist < -1) {
    dist = -1;
  }

  dist = Math.acos(dist);
  dist = dist * 180 / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344;

  return dist;
};

const hasBookFlipProductType = (productType) => {
  const bookFlipProductTypes = [
    'Luisterboek',
    'Dwarsligger',
    'Hardcover',
    'Luisterboek',
    'Paperback',
    'Pocket',
    'Spiraalgebonden',
  ];

  if (!productType) {
    return false;
  }
  return !!bookFlipProductTypes.includes(productType);
};

/*
 * Base64 Encode a string, regardles of the characterset
 */
const encode = (str) => {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    (match, p1) => String.fromCharCode(`0x${p1}`)));
};

/**
 * Decode any base64 encode string
 */
const decode = (str) => {
  return decodeURIComponent(atob(str).split('')
    .map(character => `%${(`00${character.charCodeAt(0).toString(16)}`).slice(-2)}`)
    .join(''));
};

const formatPhoneNumber = (number) => {
  let phoneNumber = number;
  switch (LOCALE) {
  case 'nl-nl':
    phoneNumber = phoneNumber.replace(/^(\+31|0031)/, '0'); // Replaces the values 31 or 0031 with 0
    break;
  case 'nl-be':
    phoneNumber = phoneNumber.replace(/^(\+32|0032)/, '0'); // Replaces the values 32 or 0032 with 0
    break;
  default:
    phoneNumber = phoneNumber.replace(/^(\+31|0031)/, '0');
    break;
  }

  return phoneNumber.replace(/[^0-9]/g, '');
};

/**
 * @param {dateInput}
 * @returns {string}  dd-mm-yyyy
 */
const formatDate = (dateInput) => {
  const date = new Date(dateInput);
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = date.getMonth() < 9 ? `0${date.getMonth() + 1 }` : date.getMonth() + 1;
  const fullDate = `${day}-${month}-${date.getFullYear()}`;
  return fullDate;
};

const validatePhoneNumber = (phoneNumber = '', required) => {
  if (!required && !phoneNumber) {
    return true;
  }
  /*
   * First character should be a 0
   * Second character may not be a 0
   * Phonenumber should be exactly 10 digits
   */
  return phoneNumber.match(/^[0][1-9]{1}[0-9]{8}/g);
};

/**
 * Format telephone number
 * @param {string} number +31 617-771-338
 * @param {boolean} pretty
 * @returns {string} pretty ? 0 617 771 338 : 0617771338
 */
const strippedPhoneNumber = ({ number = '', pretty = false }) => {
  const strippedNumber = number.replace('+31', '0');

  if (pretty) {
    return strippedNumber
      .replace(' ', '')
      .replace('-', ' ')
      .replaceAll('-', ' ');
  }

  return strippedNumber
    .replace(' ', '')
    .replaceAll(' ', '');
};

// Fallback for Firefox
const clipboardFirefox = (id) => {
  const input = document.getElementById(id);
  input.focus();
  input.select();
  document.execCommand('copy');
};

const copyToClipboard = async (text, id) => {
  if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
    clipboardFirefox(id);
  } else {
    const type = 'text/plain';
    const blob = new Blob([text], { type });
    const data = [new ClipboardItem({ [type]: blob })];

    await navigator.clipboard.write(data).then(() => true);
  }
};

const isBrowser = !!BROWSER;

const appStorage = isBrowser ? localStorage : { setItem: () => {}, getItem: () => {}, removeItem: () => {} };

const isHtmlLink = (url = '') => {
  const isExternalLink = url.includes('http');
  const isEmailLink = url.includes('mailto:');
  const isPhoneLink = url.includes('tel:');

  return (isExternalLink || isEmailLink || isPhoneLink);
};

const isValidDate = dateObject => new Date(dateObject).toString() !== 'Invalid Date';

/*
 * We don't want to log message to the console of the server.
 * To log on the server use the logger function in '../ssr/server/logger'.
 * Only use this function for logging useful messages on production,
 * such as error message. Do not use for development.
 */
const log = (...message) => {
  // eslint-disable-next-line no-console
  isBrowser && console.log(...message);
};

/*
 * Filter an array to remove all duplicates:
 * [1,2,3,3,4].filter(removeDuplicatesFromArray) -> [1,2,3,4]
 */
const removeDuplicatesFromArray = (value, index, self) => {
  return self.indexOf(value) === index;
};

/*
 * Returns a randum number between 1 and 1.000.000
 * Can be used to create unique ID's - not 100% sure but since we have a number between 1 and 1.000.000 slight change there are duplicates
 */
const createUniqueId = () => {
  const min = 1;
  const max = 1000000;
  const randomNumber = Math.floor((Math.random() * (max - min)) + min);

  return randomNumber.toString();
};

/**
 * Get the sku from the PDP url
 * @param {string} url
 * @returns {string} sku
 */
const getSkuFromUrl = (url = '') => {
  const parts = url.split('-');
  if (parts.length > 1) {
    return parts.pop();
  }
  // Should never occur, but if it does we can identify it
  return '404';
};

/**
 * Get the value from a cookie
 * @param {string} cookieName
 */
const getCookieValue = (cookieName) => {
  const cookieValue = JSON.parse(document.cookie
    .split('; ')
    .find(row => row.startsWith(`${cookieName}=`))
    ?.split('=')[1]);

  return cookieValue;
};

/**
 * Get last three characters of a string
 *
 * @param {String} string to shorten
 * @returns {String} shortened string e.g. 'string123' -> '...123'
 */
const getShortening = (string) => {
  return `...${string.slice(-3)}`;
};

/**
 *
 * @param {string} string In ISO format e.g. 2022-11-28T09:26:28.081Z'
 * @returns {string} Date without time: 2022-11-28
 */
const getIsoDateFromString = (string) => {
  if (!string) {
    return '';
  }
  const date = new Date(string);
  return date.toISOString().split('T')[0];
};

/**
 * Usage
 * This function will set a cookie on the visitor's browser.
 *
 *  @param {String} name - The name of the cookie.
 *  @param {String} value - The value of the cookie.
 *  @param {Float} exdays - The number of days the cookie should last.
 *  @param {String} domain - The domain on which this cookie should be set and can be read.
 *
 */
const setCookie = (name, value, exdays = 365, domain) => {
  domain = (typeof domain === 'undefined') ? '' : `domain=${  domain  };`;
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);
  const cookieValue = escape(value) + ((exdays == null) ? '' : `; expires=${exdate.toUTCString()}`);
  document.cookie = `${name  }=${  cookieValue  };${  domain  }path=/`;
};

/**
 * This function will return the value of the cookie name passed in as the argument.
 *
 *  @param {String} name - The name of the cookie.
 */
const getCookie = (name) => {
  const match = document.cookie.match(`${name}=([^;]*)`);
  return match ? match[1] : undefined;
};

/**
 * Lodash equivalent of the _.set() function
 * https://lodash.com/docs/4.17.15#set
 *
 * @param {Object} obj
 * @param {string} path
 * @param {string} value
 * @returns {object}
 */
const setDeepAttribute = (obj, path, value) => {
  if (Object(obj) !== obj) {
    return obj;
  }
  if (!Array.isArray(path)) {
    path = path.toString().match(/[^.[\]]+/g) || [];
  }
  path.slice(0, -1).reduce((a, c, i) => (Object(a[c]) === a[c] ? a[c] : a[c] = Math.abs(path[i + 1]) >> 0 === +path[i + 1] ? [] : {}), obj)[path[path.length - 1]] = value; // Finally assign the value to the last key
  return obj;
};

/**
 * Convert semver string to float, so we can use it in a comparisson.
 * @param {string} string // '5.8.1'
 * @returns {float} // 58.1
 */
const getVersionFromString = (string = '') => {
  const floatString = string.replace('.', '');
  const parsed = parseFloat(floatString);
  return parsed;
};

/**
 * Decode url filter values from an encoded string.
 * @returns {object}
 */
const decodeUrlFilterValues = () => {
  const parsedUrl = new URL(window.location.href);
  const stateHash = parsedUrl.searchParams.get('filter');

  let state;
  if (stateHash) {
    const string = decode(stateHash);
    state = JSON.parse(string);
  }

  return state;
};

/**
 * Check if a string is a positive integer.
 * @param {string} string
 * @returns {boolean}
 */
const isPositiveInteger = (string) => {
  const number = Number(string);
  const isInteger = Number.isInteger(number);
  const isPositive = number > 0;

  return isInteger && isPositive;
};

/**
 * Open location in Google maps, if iOS open link in
 * Apple Maps or the iOS version of Google Maps
 * @param {number} lat
 * @param {number} lng
 */
const openMaps = (lat, lng) => () => {
  const { platform: os } = navigator;
  const isApple = os.includes('iPhone') || os.includes('iPad') || os.includes('iPod');
  const protocol = isApple ? 'maps' : 'https';

  window.open(`${protocol}://maps.google.com/maps/dir/?daddr=${lat},${lng}&amp;ll=`);
};

/**
 * Check if two arrays are entirely the same
 * Are both an array
 * Do they have the same length
 * Is each value the same
 * @param {array} array1
 * @param {array} array2
 * @returns {boolean}
 */
const checkIfTwoArraysAreEqual = (array1, array2) => {
  return (
    Array.isArray(array1) && Array.isArray(array2) &&
    array1.length === array2.length &&
    array1.every((val, index) => val === array2[index])
  );
};

/**
 * Check if all values within an object are either undefined or empty string
 * @param {object} object
 * @returns {boolean}
 */
const checkIfAllValuesOfObjectAreUndefinedOrEmpty = (object) => {
  return Object.values(object).every(el => (el === undefined || el === ''));
};

/**
 * Capitalize first character in a string
 * @param {String} string
 * @returns {String}
 */
const capitalize  = (string) => {
  if (!string) {
    return null;
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export {
  hasBookFlipProductType,
  coverShadow,
  scrollTo,
  getOffset,
  checkStatus,
  createUrl,
  getDevice,
  getFromAttrOrFromObject,
  getParametersFromUrl,
  getTextColor,
  getTextForAddToCartButton,
  getTextForCreditType,
  isJsonString,
  miniMustache,
  numFormatToCurrency,
  parseJSON,
  postcodeCleanNL,
  stripHTML,
  stripHTMLByValue,
  unixToDate,
  unixToMJDate,
  unixToDMDate,
  unixToFullDate,
  validateEmail,
  secondsToMinutes,
  formatNumber,
  priceStringToFloat,
  getDominantBackgroundColor,
  getHueClass,
  parseSearchParameter,
  getSiteLocale,
  getFeature,
  getDistance,
  encode,
  decode,
  formatPhoneNumber,
  formatDate,
  validatePhoneNumber,
  appStorage,
  isBrowser,
  isHtmlLink,
  isValidDate,
  strippedPhoneNumber,
  copyToClipboard,
  log,
  removeDuplicatesFromArray,
  createUniqueId,
  getSkuFromUrl,
  getCookieValue,
  getShortening,
  getIsoDateFromString,
  setCookie,
  getCookie,
  setDeepAttribute,
  getVersionFromString,
  decodeUrlFilterValues,
  isPositiveInteger,
  openMaps,
  checkIfTwoArraysAreEqual,
  checkIfAllValuesOfObjectAreUndefinedOrEmpty,
  capitalize,
};
