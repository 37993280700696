import React from 'react';
import { numFormatToCurrency, unixToDate, unixToMJDate, secondsToMinutes, coverShadow, hasBookFlipProductType } from './index.js';
import Link from '@components/base_link/component.jsx';
import store from '@utils/store';
import { IMAGE_BASE_URL, IS_B2B } from '@config';
import { USER_STORAGE } from '@constants';
import { appStorage } from '@utils';

class Product {
  constructor(props = {}) {
    this.populateData(props);
  }

  /*
   * @param {string} name
   * @param {string} url the pdp of the product
   * @param {Object} images
   * @param {string} images.Thumbnail //This is an EAN image of 100px
   * @param {string} images.Image     // This is the EAN image of 200px
   * @param {string} images.backImage //This is a carousel image (front)
   * @param {string} images.frontImage //This is a carousel image (back)
   * @param {string} SKU
   * @param {Object} price
   * @param {string} price.regular
   * @param {Object} person
   * @param {string} person.name
   * @param {string} person.url
   * @param {Array}  persons
   * @param {string} catalog
   * @param {string} type
   * @param {string} shortDescription
   * @param {string} availability
   * @param (boolean) isOnline
   * @param {number} rating
   * @param {string} otherEditions
   * @param {object} quotes
   * @param {number} releaseDate
   * @param {number} series
   * @param {number} numberofparts
   * @param {parentaladvisory} parentaladvisory
   * @param {duration} duration
   * @param {subtitles} subtitles
   * @param {format} format
   * @param {epubversion} epubversion
   * @param {trackdata} trackdata
   * @param {awards} awards
   * @param {screenFormat} screenFormat
   * @param {soundFormat} soundFormat
   * @param {regionCode} regionCode
   * @param {editionType} editionType
   * @param {thema} thema
   * @param {extraFeatures} extraFeatures
   * @param {aviLevel} aviLevel
   * @param {Array} ageRating
   * @param {Array} allowedUsergroups
   *
   */

  populateData({
    name,
    url,
    images = {},
    imageLength,
    imageshadow,
    inlayImage,
    SKU,
    sku,
    price = {},
    person,
    persons = [],
    personsLinks,
    catalog,
    category,
    type,
    annotation,
    shortDescription,
    longDescription,
    availability,
    isOnline,
    expectedDate,
    releaseDate,
    rating,
    otherEditions,
    id,
    tbaItem,
    brand,
    brandUrl,
    tbaproducttype,
    quantity,
    pages,
    language,
    keywords,
    offered,
    primaryCategoryNumber,
    bookFlip,
    width,
    height,
    breadcrumb,
    preview = {},
    quotes = [],
    mediaQuotesRatings = [],
    singleBasePrice = {},
    productLength,
    productWidth,
    productHeight,
    series,
    numberofparts,
    parentaladvisory,
    duration,
    subtitles,
    format,
    epubversion,
    trackData,
    awards,
    screenFormat,
    soundFormat,
    regionCode,
    editionType,
    thema,
    extraFeatures,
    aviLevel,
    ageRating,
    badges,
    otherEditionsJson,
    storePickupAvailability,
    orderQuantity,
    termsAndConditions,
    allowedUsergroups,
    ...rest
  }) {
    this._name = name;
    this._url = url;
    this._SKU = SKU || sku;
    this._id = id;
    this._catalog = catalog;
    this._category = category;
    this._type = type;
    this._annotation = annotation;
    this._shortDescription = shortDescription;
    this._longDescription = longDescription;
    this._availability = availability;
    this._expectedDate = expectedDate;
    this._releaseDate = releaseDate;
    this._rating = +rating;
    this._tbaItem = tbaItem;
    this._brand = brand;
    this._tbaproducttype = tbaproducttype;
    this._pages = pages;
    this._language = language || [];
    this._keywords = keywords || [];
    this._imageLength = imageLength; // @refactor to the image length if the shape of images changes
    this._image = ''; // @NOTE: This is to set an initial value to image?
    this._images = images;
    this._imageshadow = imageshadow;
    this._inlayImage = inlayImage;
    this._singleBasePrice = singleBasePrice;
    this._offered = offered; // @refactor to the image ength if the shape of images changes
    this._primaryCategoryNumber = primaryCategoryNumber;
    this._bookFlip = bookFlip;
    this._width = width;
    this._height = height;
    this._breadcrumb = breadcrumb;
    this._preview = preview;
    this._quotes = quotes;
    this._mediaQuotesRatings = mediaQuotesRatings;
    this._productLength = productLength;
    this._productWidth = productWidth;
    this._productHeight = productHeight;
    this._series = series;
    this._numberofparts = numberofparts;
    this._parentaladvisory = parentaladvisory;
    this._duration = duration;
    this._subtitles = subtitles;
    this._format = format;
    this._epubversion = epubversion;
    this._trackData = trackData;
    this._awards = awards || [];
    this._screenFormat = screenFormat;
    this._soundFormat = soundFormat;
    this._regionCode = regionCode;
    this._editionType = editionType;
    this._thema = thema || [];
    this._extraFeatures = extraFeatures;
    this._aviLevel = aviLevel;
    this._ageRating = ageRating || [];
    this._badges = !!badges ? badges : [];
    this._otherEditionsJson = otherEditionsJson;
    this._isOnline = (isOnline === undefined) ? true : isOnline;
    this._storePickupAvailability = storePickupAvailability;
    this._orderQuantity = orderQuantity;
    this._brandUrl = brandUrl;
    this._termsAndConditions = termsAndConditions;
    this._allowedUsergroups = allowedUsergroups;

    /*
     * get non explicit attributes in the class.
     * be carefull with this, to not cause conflicts with explicit attributes
     */
    Object.assign(this, rest);

    this.price = price; // use the setter
    this.quantity = quantity; // use the setter


    this.otherEditions = otherEditions; // use the setter

    // only add persons if there persons is array and has a length
    if (Array.isArray(persons) && persons.length) {
      this._persons = persons;
    } else {
      // or fill the persons with a single person (or also array)
      if (person) {
        this._persons = Array.isArray(person) ? person : [person];
      } else {
        this._persons = [];
      }
    }

    const disks = [];
    const stored = [];
    !!trackData && trackData.forEach((song) => {
      if (!stored.includes(song.diskNo)) {
        const disk = trackData.filter(v => (v.diskNo === song.diskNo));
        stored.push(song.diskNo);
        disks.push(disk);
      }
    });
    this.trackData = disks;
  }

  // setters
  /**
   * Set badges value
   * @param {*} badges
   */
  set badges(badges) {
    this._badges = badges;
  }

  /**
   * The name of the product
   * @param {string} name
   */
  set name(name) {
    this._name = name;
  }

  /**
   * The url of the pdp
   * @param {string} url
   */
  set url(url) {
    this._url = url;
  }

  /**
   * The SKU of a product
   * @param {string} SKU
   */
  set SKU(SKU) {
    this._SKU = SKU;
  }

  set sku(sku) {
    this.SKU = sku;
  }

  /**
   * The id of a product or line item
   * @param id
   */
  set id(id) {
    this._id = id;
  }

  /**
   * The type of product i.e. book/ cd/ etc
   * @param {string} catalog
   */
  set catalog(catalog) {
    this._catalog = catalog;
  }

  /**
   * The category of product
   * @param {string} category
   */
  set category(category) {
    this._category = category;
  }

  /**
   * The product type i.e. paperback
   * @param {string} type
   */
  set type(type) {
    this._type = type;
  }

  /**
   * The annotation of the product
   * @param {string} annotation
   */
  set annotation(annotation) {
    this._annotation = annotation;
  }

  /**
   * The short description of the product
   * @param {string} shortDescription
   */
  set shortDescription(shortDescription) {
    this._shortDescription = shortDescription;
  }

  /**
   * The short description of the product
   * @param {string} longDescription
   */
  set longDescription(longDescription) {
    this._longDescription = longDescription;
  }

  /**
   * Notes on availability
   * Delivery promise a.k.a. leverbelofte a.k.a. leveringsstatus (alleen aantal dagen zit hierin)
   * Op werkdagen voor 23:00 uur besteld, volgende dag in huis
   *
   * Availability is een string met de leveringsstatus van het product.
   * Dit kan zijn:
   * - Nog niet beschikbaar. Verwacht vanaf: [nothing] + hierachter komt de expectedDate(als deze er is,
   * anders moet het "Wordt nog verwacht" worden)
   *
   * @param {string} availability
   */
  set availability(availability) {
    this._availability = availability;
  }

  set isOnline(isOnline) {
    this._isOnline = (isOnline === undefined) ? true : isOnline;
  }

  set expectedDate(expectedDate) {
    this._expectedDate = expectedDate;
  }

  set releaseDate(releaseDate) {
    this._releaseDate = releaseDate;
  }

  set price(price) {
    this._price = price;
  }

  set singleBasePrice(singleBasePrice) {
    this._singleBasePrice = singleBasePrice;
  }


  /**
   * Person object, can be author, artist, director, depending of the type of product
   * @param {Object} person
   */
  set persons(persons) {
    this._persons = Array.isArray(persons) ? persons : [persons];
  }

  /**
   * Rating should be a number in a scale of 10
   * @param rating
   */
  set rating(rating) {
    this._rating = +rating;
  }

  /**
   * Sets the list of other editions,
   * It can be presented as a comma seperated list of SKU's or an array
   * @param {string|Array} SKUS
   */
  set otherEditions(SKUS = '') {
    if (Array.isArray(SKUS)) {
      this._otherEditions = SKUS;
    } else {
      if (SKUS) {
        this._otherEditions = SKUS.split(',');
      } else {
        this._otherEditions = [];
      }
    }
  }

  set tbaItem(tbaItem) {
    this._tbaItem = tbaItem;
  }

  set brand(brand) {
    this._brand = brand;
  }

  set brandUrl(brandUrl) {
    this._brandUrl = brandUrl;
  }

  set tbaproducttype(tbaproducttype) {
    this._tbaproducttype = tbaproducttype;
  }

  set bookFlip(bookFlip) {
    this._bookFlip = bookFlip;
  }

  set quantity(quantity) {
    if (isNaN(quantity) && quantity && quantity.value) {
      this._quantity = quantity.value;
      return;
    }
    this._quantity = quantity;
  }

  set pages(pages) {
    this._pages = pages;
  }

  set language(language) {
    this._language = language;
  }

  set keywords(keywords) {
    this._keywords = keywords;
  }

  set imageLength(imageLength) { // @todo: refector to the length of the images if the shape changes of images
    this._imageLength = imageLength;
  }

  /**
   * image object
   * @param {{Thumbnail: string, Image: string}|{}} images
   */

  set images(images) {
    this._images = images;
  }

  set image(image) {
    return this._image = image; // This is empty always???
  }

  set imageshadow(imageshadow) {
    this._imageshadow = imageshadow;
  }


  /**
   *
   * what do the different "offered" attributes mean?
   * 0: not available? Not yet available.. Available from...
   * 1: available
   * 2: can only be reserved
   * 3: only available as a second hand product
   * @param offered
   */
  set offered(offered) {
    this._offered = offered;
  }

  set primaryCategoryNumber(primaryCategoryNumber) {
    this._primaryCategoryNumber = primaryCategoryNumber;
  }


  set width(width) {
    this._width = width;
  }

  set height(height) {
    this._height = height;
  }

  /**
   * Breadcrumbs shape should like this
   * [
   * {"name": "Overzicht", "url": "https://bruna.nl"},
   * {"name": "Schrijver(s)", "url": ""},
   * {"name": "Saskia Noort", "url": ""}
   * ]
   * @param breadcrumb
   */
  set breadcrumb(breadcrumb) {
    if (Array.isArray(breadcrumb) && breadcrumb.length) {
      this._breadcrumb = breadcrumb;
    }
  }

  /**
   * Get the preview of a book
   * @param preview {object} should be an either an empty object or filled object
   * @returns {object}
   */
  set preview(preview) {
    this._preview = preview;
  }

  set quotes(quotes) {
    this._quotes = quotes;
  }

  set mediaQuotesRatings(mediaQuotesRatings) {
    this._mediaQuotesRatings = mediaQuotesRatings;
  }

  set productLength(productLength) {
    this._productLength = productLength;
  }

  set productWidth(productWidth) {
    this._productWidth = productWidth;
  }

  set productHeight(productHeight) {
    this._productHeight = productHeight;
  }

  set series(series) {
    this._series = series;
  }

  set numberofparts(numberofparts) {
    this._numberofparts = numberofparts;
  }

  set parentaladvisory(parentaladvisory) {
    this._parentaladvisory = parentaladvisory;
  }

  set duration(duration) {
    this._duration = duration;
  }

  set subtitles(subtitles) {
    this._subtitles = subtitles;
  }

  set format(format) {
    this._format = format;
  }

  set epubversion(epubversion) {
    this._epubversion = epubversion;
  }

  set trackData(trackData) {
    this._trackData = trackData;
  }

  set awards(awards) {
    this._awards = awards;
  }

  set screenFormat(screenFormat) {
    this._screenFormat = screenFormat;
  }

  set soundFormat(soundFormat) {
    this._soundFormat = soundFormat;
  }

  set regionCode(regionCode) {
    this._regionCode = regionCode;
  }

  set editionType(editionType) {
    this._editionType = editionType;
  }

  set thema(thema) {
    this._thema = thema;
  }

  set extraFeatures(extraFeatures) {
    this._extraFeatures = extraFeatures;
  }

  set aviLevel(aviLevel) {
    this._aviLevel = aviLevel;
  }

  set ageRating(ageRating) {
    this._ageRating = ageRating;
  }

  /**
   * The otherEditionsJson for the product.
   * @param {string} name
   */
  set otherEditionsJson(otherEditionsJson) {
    this._otherEditionsJson = otherEditionsJson;
  }

  set storePickupAvailability(storePickupAvailability) {
    this._storePickupAvailability = storePickupAvailability;
  }

  set orderQuantity(orderQuantity) {
    this._orderQuantity = orderQuantity;
  }

  // Getters
  get isGiftcard() {
    const productType = this._tbaproducttype || this.tbaItem.tbaproductType || this.type;
    return productType === 'Digitale cadeaukaart';
  }

  get giftcardDetails() {
    const priceArray = this._price.regular;
    return {
      fixed: priceArray.length === 1,
      min: priceArray?.[1]?.amount,
      max: priceArray?.[0]?.amount,
    };
  }

  get termsAndConditions() {
    return this._termsAndConditions;
  }

  /**
   * Get all badges
   * @returns {*}
   */
  get badges() {
    return this._badges;
  }

  /**
   * The name of the product
   * @returns {string}
   */
  get name() {
    return this._name || '';
  }

  get url() {
    return this._url;
  }

  /**
   * The SKU of a product
   * @returns {string}
   */
  get SKU() {
    return this._SKU;
  }

  get sku() {
    return this.SKU;
  }

  get id() {
    return this._id || this.SKU || 'notSet';
  }

  /**
   * The type of product i.e. book/cd/etc
   * @returns {string}
   */
  get catalog() {
    return this._catalog;
  }

  /**
   * The category of product
   * @returns {string}
   */
  get category() {
    return this._category || this.tbaItem.category || '';
  }

  get brand() {
    return this._brand || this.tbaItem.brand || '';
  }

  get brandUrl() {
    return this._brandUrl;
  }

  /**
   * The product type i.e. paperback
   * @returns {string}
   */
  get type() {
    return this._type || '';
  }

  /**
   * The product type i.e. paperback
   * @returns {string}
   */
  get tbaproducttype() {
    return this._tbaproducttype || this.tbaItem.tbaproductType || this.type;
  }

  get isEbook() {
    return this.tbaproducttype === 'eBook';
  }

  get ebookType() {
    const drmFormats = ['Adobe PDF met Adobe DRM', 'ePub met Adobe DRM'];

    if (this._epubversion === '3') {
      return 'epub3';
    } else if (drmFormats.includes(this._format)) {
      return 'drm';
    }
    return null;
  }

  get quantity() {
    if (isNaN(this._quantity)) {
      return (this._quantity && this._quantity.value) || 1;
    }
    return this._quantity;
  }

  get pages() {
    return this._pages || '';
  }

  get language() {
    return this._language || '';
  }

  get keywords() {
    return this._keywords || '';
  }

  get imageLength() { // this is an ancient thing and can be removed
    return this._imageLength;
  }

  get imageshadow() {
    return this._imageshadow;
  }
  /**
   * The images object of the product
   * @returns {{Thumbnail: string, Image: string}|{}}
   */
  get images() {
    return this._images;
  }
  get backImage() {
    return this.images.backImage;
  }

  get inlayImage() {
    return this._inlayImage;
  }

  get fallbackImage() {
    return require('@active-channel/images/no_image_product_X.png');
  }

  /**
   * The normal (large) image
   * @returns {string}
   */
  get image() {
    /**
     * If null is delivered to a function parameter, the default value is not applied
     * in that way, the reassignment in the next block is necessary.
     *
     * Check if the image url already contains a base url and starts with http.
     * If not, add the base url to the image url
     */
    const image = (this._image || this.images.Image || this.images.frontImage || this.images.Thumbnail) || this.fallbackImage;
    let imageWithBaseUrl = image;
    if (!image.includes('http')) {
      imageWithBaseUrl = IMAGE_BASE_URL + image;
    }
    return imageWithBaseUrl;
  }

  /**
   * The thumbnail
   * @returns {string}
   */
  get thumbnail() {
    return this.images.Thumbnail || this.image;
  }

  get frontImage() {
    return this.images.frontImage || this.image;
  }


  /**
   *
   * what do the different "offered" attributes mean? We use it to fetch the right button text and if we should render it.
   * 0: not available?
   * 1: available
   * 2: can only be reserved
   * 3: only available as a second hand product
   * 4: Only available in the stores
   * 5: Out of stock - Keep me posted
   * @param offered
   */
  get offered() {
    const offered = parseInt(this._offered, 10);
    if (isNaN(offered) || offered > 5) {
      return 1;
    }
    return offered;
  }

  get showAddToCartButton() {
    const offered = parseInt(this._offered, 10);
    return (!!this._SKU && !!this._isOnline && (offered === 1 || offered === 2));
  }

  /*
   * For B2B we need to check the usergroup of the product and the customer
   * Only if one of them matches the product is orderable for that customer
   * When orderable we can show the add to cart button
   */
  get isOrderable() {
    const productUsergroups = this._allowedUsergroups;
    const userData = appStorage.getItem(USER_STORAGE);
    const customerUsergroups = JSON.parse(userData)?.usergroups;
    let isOrderable;
    if (!IS_B2B) {
      isOrderable = true;
    } else if (customerUsergroups && productUsergroups) {
      isOrderable =  productUsergroups?.every(group => customerUsergroups?.includes(group));
    }

    return (!!isOrderable);
  }

  /*
   * Products from CB are only orderable when
   * product has usergroup CG_AUDAX_CB_KLANT and user also has it
   */
  get isOrderableCBProduct() {
    const productUsergroups = this._allowedUsergroups;
    const userData = appStorage.getItem(USER_STORAGE);
    const customerUsergroups = JSON.parse(userData)?.usergroups;
    let isOrderableCBProduct;
    if (!IS_B2B) {
      isOrderableCBProduct = true;
    } else if (customerUsergroups && productUsergroups) {
      const cbProduct = productUsergroups?.includes('CG_AUDAX_CB_KLANT');
      const cbCustomer = customerUsergroups?.includes('CG_AUDAX_CB_KLANT');

      isOrderableCBProduct = cbProduct && cbCustomer;
    }

    return (!!isOrderableCBProduct);
  }

  get isCBProduct() {
    const productUsergroups = this._allowedUsergroups;
    let isCBProduct;
    if (productUsergroups) {
      isCBProduct = productUsergroups?.includes('CG_AUDAX_CB_KLANT');
    }

    return (isCBProduct);
  }

  get isCBBuitenlandsProduct() {
    const productUsergroups = this._allowedUsergroups;
    let isCBBuitenlandsProduct;
    if (productUsergroups) {
      isCBBuitenlandsProduct =  productUsergroups?.includes('CG_AUDAX_CBBUITENLANDS_KLANT');
    }

    return (isCBBuitenlandsProduct);
  }

  get ctaIcon() {
    const offered = parseInt(this._offered, 10);
    let icon = 'ico-shopping-bag';
    switch (offered) {
    case 2:
      icon = 'ico-calendar';
      break;
    case 5:
      icon = 'ico-arrow-right';
      break;
    }
    return icon;
  }

  /**
   * PrimaryCategoryNumber
   * The number of the main category of this product.
   *
   * CATNUM          DESCRIPTION
   * 01              Boeken
   * 02              Muziek
   * 03              Film
   * 04              Games
   * 05              Speelgoed
   * 06              Overig
   * 07              Engelse Boeken
   * 08              eBooks
   * 09              Temporary
   * @returns {*}
   */
  get primaryCategoryNumber() {
    return this._primaryCategoryNumber;
  }

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }
  /**
   * Breadcrubs shape should like this
   * [
   * {"name": "Overzicht", "url": "https://bruna.nl"},
   * {"name": "Schrijver(s)", "url": ""},
   * {"name": "Saskia Noort", "url": ""}
   * ]
   * @param breadcrumb
   */
  get breadcrumb() {
    const crumbsArray = this._breadcrumb.map((crumb) => {
      return { ...crumb, url: crumb.url };
    });
    return crumbsArray;
  }

  get preview() {
    return this._preview;
  }

  get quotes() {
    return this._quotes;
  }

  get mediaQuotesRatings() {
    return this._mediaQuotesRatings;
  }

  get availability() {
    return this._availability || '';
  }

  get isOnline() {
    return this._isOnline;
  }

  get releaseDate() {
    return this._releaseDate ? unixToDate(this._releaseDate) : null;
  }

  get expectedDate() {
    return this._expectedDate ? unixToDate(this._expectedDate) : null;
  }

  get productLength() {
    return this._productLength;
  }

  get productWidth() {
    return this._productWidth;
  }

  get productHeight() {
    return this._productHeight;
  }

  get series() {
    return this._series;
  }

  get numberofparts() {
    return this._numberofparts;
  }

  get parentaladvisory() {
    return this._parentaladvisory;
  }

  get duration() {
    return this._duration;
  }

  get formatedDuration() {
    return secondsToMinutes(this._duration);
  }

  get subtitles() {
    return this._subtitles;
  }

  get format() {
    return this._format;
  }

  get epubversion() {
    return this._epubversion;
  }

  get trackData() {
    return this._trackData;
  }

  get awards() {
    return this._awards;
  }

  get screenFormat() {
    return this._screenFormat;
  }

  get soundFormat() {
    return this._soundFormat;
  }

  get regionCode() {
    return this._regionCode;
  }

  get editionType() {
    return this._editionType;
  }

  get thema() {
    return this._thema;
  }

  get extraFeatures() {
    return this._extraFeatures;
  }

  get aviLevel() {
    return this._aviLevel;
  }

  /**
   * Age ratings separated by comma
   * @returns {string}
   */
  get ageRating() {
    return this._ageRating.join(', ');
  }

  /**
   * The annotation excerpt of the product
   * @returns {string}
   */
  get annotation() {
    return this._annotation;
  }

  /**
   * The short description of the product
   * @returns {string}
   */
  get shortDescription() {
    return this._shortDescription;
  }

  get longDescription() {
    return this._longDescription;
  }

  get otherEditions() {
    return this._otherEditions || [];
  }

  get tbaItem() {
    return this._tbaItem || {};
  }

  get otherEditionsJson() {
    if (this._otherEditionsJson) {
      const { data } = JSON.parse(this._otherEditionsJson);
      return data;
    }
    return { items: [] };
  }

  /*
   *
   * Prices
   *
   */

  get price() {
    return this._price || {};
  }

  /**
   * The regular price array of the product
   * @returns {Array}
   */
  get priceRegular() {
    return this.price.regular || [];
  }

  get storePickupAvailability() {
    return this._storePickupAvailability;
  }

  get orderQuantity() {
    return this._orderQuantity;
  }

  get isBook() {
    return ['Boeken', 'eBooks', 'Engelse Boeken'].includes(this._catalog);
  }

  get languageAlpha2() {
    switch (this.language?.[0]) {
    case 'Nederlands':
      return 'nl';
    case 'Duits':
      return 'de';
    case 'Engels':
      return 'en';
    case 'Frans':
      return 'fr';
    case 'Portugees':
      return 'pt';
    case 'Spaans':
      return 'es';
    default:
      return 'nl';
    }
  }

  // Returns true if the sku of product is already in the active basket, otherwise returns falls
  get isProductInBasket() {
    const productInBasket = store.getBasketProduct(this.sku);
    const inBasket = Object.keys(productInBasket)?.length > 0;
    return inBasket;
  }

  /**
   * Get the label for the price for regular customers (klant)
   * There can be first and secondary price
   *
   * @param {number} i index; should be 0 or 1
   * @returns {string}
   */
  priceRegularLabel({ i = 0 }) {
    return this.priceRegular[i].label || '';
  }

  /**
   * There can be first and secondary price
   *
   * @param {number} i index; should be 0 or 1
   * @returns {string}
   */
  priceRegularAmount({ i = 0, locale }) {
    const amount = this.priceRegular[i].amount || '';
    return amount ? numFormatToCurrency(amount, locale) : '';
  }

  /**
   * Get the amount for the price (by number, not formatted) for regular customers (klant)
   * There can be first and secondary price
   *
   * @param {number} i index; should be 0 or 1
   * @returns {number|null}
   */
  priceRegularAmountNr({ i = 0 }) {
    const amount = this.priceRegular[i].amount || null;
    return amount;
  }

  /**
   * Get the price of a single product
   */
  get singleBasePrice() {
    return this._singleBasePrice ? this._singleBasePrice.value : 0;
  }


  getProductPrice() {
    const priceSet = this.price.regular || [];
    return {
      price1: priceSet[0] ? numFormatToCurrency(priceSet[0].amount) : '',
      label1: priceSet[0] ? priceSet[0].label : '',
      price2: priceSet[1] ? numFormatToCurrency(priceSet[1].amount) : '',
      label2: priceSet[1] ? priceSet[1].label : '',
    };
  }

  get regularPrice() {
    const priceSet = this.price.regular || [];
    const price1 = priceSet[0] ? numFormatToCurrency(priceSet[0].amount) : '';
    const price2 = priceSet[1] ? numFormatToCurrency(priceSet[1].amount) : '';
    return price2 || price1;
  }

  /**
   * END prices
   *
   */


  /**
   * Get the complete persons list with objects
   * @returns {Array}
   */
  get persons() {
    const personsArray = this._persons.map(person => ({ ...person, url: person.url }));
    return personsArray;
  }

  /**
   * Get only the name of the person
   * @param {number} i index of the person in the personlist
   * @returns {string|boolean}
   */
  getPersonName(i = 0) {
    if ((this._persons.length > i) && this._persons[i].name) {
      return this._persons[i].name;
    }
    return '';
  }

  /**
   * Get the names of all the persons comma seperated
   * @returns {*}
   */
  getPersonNames() {
    if (this._persons.length > 0) {
      return this._persons.map(person => person.name)
        .join(', ');
    }
    return '';
  }

  get personsLinks() {
    if (!this._persons.length) {
      return;
    }

    return this.persons.map((person, i) => {
      const isLastAuthor = (i + 1) === this.persons.length;
      return (
        <Link
          key={i}
          href={person.url}
        >
          {person.name}{!isLastAuthor && ', '}
        </Link>
      );
    });
  }

  /**
   * Get only the url of the person
   * @param {number} i index of the person in the personlist
   * @returns {string|boolean}
   */
  getPersonUrl(i = 0) {
    if ((this._persons.length > i) && this._persons[i].url) {
      return this._persons[i].url;
    }
    return false;
  }

  /**
   * Get the urls of the persons
   * seperated by comma
   *
   * @returns {string}
   */
  getPersonsUrls() {
    if (this._persons.length > 0) {
      return this._persons.map(person => person.url).join(', ');
    }
    return '';
  }

  /**
   * Get a person from the person array
   *
   * @param {number} i index of the person in the personlist
   * @returns {boolean}
   */
  getPerson(i) {
    return (this._persons.length > i) ? this._persons[i] : false;
  }

  /**
   * Get a alternative text string of the product
   *
   * @returns {string}
   */
  getAltText() {
    return this._name + (this.getPersonNames() ? ` van ${this.getPersonNames()}` : '');
  }

  /**
   * return the rating
   * @returns {number}
   */
  get rating() {
    return ((isNaN(this._rating)) || this._rating === 0) ? null : this._rating;
  }

  get fullProductAvailability() {
    if (this.availability.includes('vanaf')) {
      return !!this.expectedDate ? `${this.availability} ${this.expectedDate}` : this.availability;
    }
    return this.availability;
  }

  /**
   * Get the product dimensions string, composed by productHeight x productLength x productWidth
   * @returns {string}
   */
  getProductDimensions() {
    if (this._productHeight && this._productLength && this._productWidth) {
      return `${this._productLength} x ${this._productWidth} x ${this._productHeight} mm.`;
    }
  }

  /**
   * Get the full product availability message, including the expected date.
   * @returns {string}
   *
   */
  getFullProductAvailabilityMessage() {
    /*
     * Als availibility "Nog niet verschenen, verwacht vanaf ..." is, gooi er dan een datum achter. Dit is veranderd met PIM
     * If availability is: "Not yet appeared. expected from: ". Put the date behind it. This is new with PIM because the dat is not in this string anymore. Prep on 28 aug 2019
     */
    if (this.availability.includes('vanaf')) {
      if (!this.expectedDate) {
        return this.availability;
      }
      return `${this.availability} ${this.expectedDate}`;
    }
    return this.availability;
  }

  /**
   * Get the product dimensions string, composed by productHeight x productLength x productWidth
   * @returns {string}
   */
  getProductSeries() {
    if (this._series.length > 0) {
      return this._series.join(', ');
    }
  }

  /**
   * Get the product Format string, composed by format & epubversion
   * @returns {string}
   */
  getProductFormat() {
    if (this._format && this._epubversion) {
      return `${this._format} - ${this._epubversion}`;
    }
  }

  /**
   * Get the product languages string, composed by the values of language array
   * @returns {string}
   */
  getProductLanguages() {
    if (this._language.length > 0) {
      return this._language.join(', ');
    }
  }

  getReleaseMJDate() {
    if (this._releaseDate) {
      return unixToMJDate(this._releaseDate);
    }
  }

  getProductSubtitles() {
    if (this._subtitles.length > 0) {
      return this._subtitles.join(', ');
    }
  }

  getParentaladvisory() {
    if (this._parentaladvisory.length > 0) {
      return this._parentaladvisory.join(', ');
    }
  }
  getProductKeywords() {
    if (this._keywords.length > 0) {
      return this._keywords.join(', ');
    }
  }
  getProductThemas() {
    if (this._thema.length > 0) {
      return this._thema.join('; ');
    }
  }
  getProductAwards() {
    if (this._awards.length > 0) {
      return this._awards.join('; ');
    }
  }
  getBookFlip() {
    return (this.frontImage && this.backImage && !this.inlayImage) ? hasBookFlipProductType(this.tbaproducttype) : false;
  }
  getCoverShadow() {
    return coverShadow(this.tbaproducttype);
  }
}

export { Product };
