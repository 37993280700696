import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntersect, useUpdateEffect } from '@utils/hooks';
import { isBrowser } from '@utils';
import { IMAGE_BASE_URL } from '@config';

if (isBrowser) {
  require('intersection-observer');
}

const LazyImage = (props) => {
  const { src = '', onLoad, onError, fallback, style, lazy, offset, ...rest } = props;

  /*
   * If image path is relative, always add the IMAGE_BASE_URL to make it absolute.
   * If url is absolute and image is in images/newsletter or images/active directory,
   * replace the default www. location with IMAGE_BASE_URL to prevent request
   * from going trough the proxy server.
   */
  const getImagePath = (src) => {
    // Early exit if the source is the fallback image
    if (src.includes('no_image_product_X.png')) {
      return src;
    }

    let path = (IMAGE_BASE_URL || '') + src;

    if (src.includes('http')) {
      const matches = src.match(/\/images\/\S+/g);
      if (matches) {
        path = (IMAGE_BASE_URL || '') + matches[0];
      }
    }
    return path;
  };

  const imagePath = getImagePath(src);
  const getInitialSource = () => {
    let initialSrc = imagePath;

    // Ignore lazy loading on server render
    const ignoreLazy = !isBrowser;
    if (lazy && !ignoreLazy) {
      initialSrc = '';
    }
    return initialSrc;
  };

  const initialSource = getInitialSource();
  const [opacity, setOpacity] = useState(initialSource ? 1 : 0);
  const [source, setSource] = useState(initialSource);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [ref, entry] = useIntersect({
    rootMargin: offset && `${offset}px`,
  });

  useUpdateEffect(() => {
    setSource(getImagePath(src));
  }, [src]);

  useEffect(() => {
    if (!!entry.intersectionRatio && !loaded) {
      setSource(getImagePath(src));
      setLoaded(true);
    }
  }, [entry]);

  const onErrorEvent = () => {
    if (!error && fallback && loaded) {
      setSource(fallback);
      setError(true);
    }

    onError && onError();
  };

  /**
   * Prevent the images from being shown until the correct src is set and is loaded
   */
  const onLoadEvent = () => {
    setOpacity(1);
    onLoad && onLoad();
  };

  return (
    <img
      {...rest}
      src={source}
      ref={ref}
      style={{ ...style, opacity }}
      onError={onErrorEvent}
      onLoad={onLoadEvent}
    />
  );
};

LazyImage.defaultProps = {
  alt: 'Afbeelding',
  lazy: false,
  offset: 300,
  style: {},
  className: '',
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  lazy: PropTypes.bool,
  offset: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  fallback: PropTypes.string,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
};
export default LazyImage;
