import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'audamatic-ui';
import { getGenesysConfig, advancedConfig } from './chat.config';
import { useEventListener } from '@utils/hooks';
import { getFeature, getDevice } from '@utils';
import Text from '@text';
import { t } from '@utils/i18n';
import Icon from '@components/base_svg-icon/component';

import './styles.scss';

/**
 * PureCloud Chat widget component
 */
const ChatWidget = ({ store }) => {
  const { isActive, deploymentKey, orgGuid, targetAddress, domain } = getFeature('chat');
  const [loading, setLoading] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [customPurecloudPlugin, setCustomPurecloudPlugin] = useState({});

  // Trigger the actual chat popup
  const startChat = () => {
    // Show an error message if the chat is (suddenly) not available
    if (!shouldShowButton()) {
      store.modal.openModal('popup', 'center', {
        title: t('chat.widget.error.title'),
        component: (
          <div>
            <Text html>chat.widget.error.msg</Text>
            <br/><br/>
            <Button variant="primary" size="large" onClick={() => window.location.href = t('chat.widget.error.button.url')}>
              <Text>chat.widget.error.button.title</Text>
            </Button>
          </div>
        ),
      });
      return setHidden(true);
    }

    setLoading(true);
    const user = store.getBasket().user || {};

    // Set required window variables
    if (!window._gt) {
      window._gt = [];
    }
    if (!window._genesys) {
      window._genesys = getGenesysConfig({
        deploymentKey,
        orgGuid,
        targetAddress,
        navigator,
        document,
        location,
        domain,
      });
    }

    const callback = () => {
      CXBus.configure({
        debug: false,
        pluginsPath: 'https://apps.mypurecloud.ie/widgets/9.0/plugins/',
      });
      CXBus.loadPlugin('widgets-core');
      const customPlugin = CXBus.registerPlugin('Custom');

      customPlugin.before('WebChat.open', (options) => {
        setLoading(false);
        // Still need to return the 'options' object to continue execution
        return options;
      });

      customPlugin.subscribe('WebChatService.started', () => {
        setLoading(false);
        setHidden(true);
      });

      customPlugin.subscribe('WebChatService.ended', () => {
        setHidden(false);
      });

      setTimeout(() => {
        customPlugin.command('WebChat.open', advancedConfig(user));
      }, 1000);

      setCustomPurecloudPlugin(customPlugin);
    };
    const script = document.createElement('script');
    const scriptId = 'purecloud-script';
    script.setAttribute('src', 'https://apps.mypurecloud.ie/widgets/9.0/cxbus.min.js');
    script.onload = callback;
    script.id = scriptId;
    if (!document.getElementById(scriptId)) {
      document.head.appendChild(script);
    } else {
      customPurecloudPlugin.command('WebChat.open', advancedConfig(user));
    }
  };

  // Check if the chat button should show on the page or not
  const shouldShowButton = () => {
    if (!isActive) {
      return false;
    }
    const { enabled, openingHours } =  window?.audaxChat || {};
    let withinOpeningHours = false;

    const now = new Date();
    const dayOfWeek = now.getDay(); // sunday = 0, saturday = 6
    const hourOfDay = now.getHours(); // 24h notation

    const isWeekday = (dayOfWeek >= 1 && dayOfWeek <= 5); // monday to friday
    const weekdayAndOnTime = (isWeekday && (hourOfDay >= openingHours.week.start && hourOfDay < openingHours.week.end));

    const isSaturday = (now.getDay() === 6);
    const saturdayAndOnTime = (isSaturday && (hourOfDay >= openingHours.saturday.start && hourOfDay < openingHours.saturday.end));

    if (weekdayAndOnTime || saturdayAndOnTime) {
      withinOpeningHours = true;
    }

    return enabled && withinOpeningHours;
  };

  // Listen to a custom event 'triggerChatWidget' which is triggered by GTM
  const showChat = () => {
    const isHidden = !shouldShowButton();
    setHidden(isHidden);
  };
  useEventListener('triggerChatWidget', showChat);


  const [hasMounted, setHasMounted] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    setHasMounted(true);

    const { isMobile } = getDevice();
    isMobile && setMobileView(isMobile);
  }, []);


  if (!hasMounted || mobileView) {
    return null;
  }

  return (
    <div className="chat-widget-container">
      {!hidden && (
        <Button
          variant="primary"
          size="large"
          iconLeft={<Icon name="ico-comment" size={150} className="no-margin"/>}
          onClick={startChat}
          loading={loading}
        />
      )}
    </div>
  );
};

ChatWidget.propTypes = {
  store: PropTypes.object,
};

export default ChatWidget;
