/**
 * Get the ship to address
 *
 * @param basket
 * @returns {*}
 */
const getShipToAddress = (basket) => {
  if (!basket?.shippingBuckets?.length) {
    return {};
  }
  const excludedShippingMethods = ['EveningDelivery', 'DigitalDelivery'];
  const nonEbookDelivery = basket.shippingBuckets.find(bucket => !excludedShippingMethods.includes(bucket.shippingMethod?.id));

  if (nonEbookDelivery) {
    return nonEbookDelivery.shipToAddress || {};
  }
  return basket.invoiceToAddress || {};
};

const getInvoiceToAddress = (basket) => {
  if (!basket || !basket.invoiceToAddress) {
    return {};
  }
  return basket.invoiceToAddress;
};

/**
 * Receives an address obbject and asserts if it is the user's
 * prefered shipping address.
 *
 * @param {Object} address
 * @returns {Boolean}
 */
const isPreferedShippingAddress = (address) => {
  return !!address.preferredShippingAddress;
};

/**
 * Get the line items out of the shippings buckets in the basket
 *
 * @param basket
 * @returns {Array}
 */
const getLineItems = (basket) => {
  if (!basket || !basket.shippingBuckets) {
    return [];
  }
  const lineItems = [];
  basket.shippingBuckets.forEach((bucket) => {
    !!bucket.lineItems && bucket.lineItems.forEach((lineItem) => {
      lineItems.push(lineItem);
    });
    // lineItems.push(...bucket.lineItems); // Why use spread operator
  });
  return lineItems;
};

/**
 * Get all shipping methods from all shipping buckets
 * There is an option to exclude some shipping methods by id name
 *
 * @param basket
 * @returns {Array}
 */
const getShippingMethods = ({ basket,  exclude = []  }) => {
  if (!basket || !basket.shippingMethods) {
    return [];
  }
  return basket.shippingMethods.filter(shippingMethod => !exclude.includes(shippingMethod.id));
};

/**
 * Get the current shippingMethod
 *
 * @param basket
 * @param from
 * @returns {Object}
 */
const getCurrentShippingMethodBuckets = ({ basket,  from = []  }) => {
  let currentShippingMethod = {};
  if (!basket || !basket.shippingBuckets) {
    return currentShippingMethod;
  }

  basket.shippingBuckets.forEach((item) => {
    if (from.includes(item.shippingMethod?.id)) {
      currentShippingMethod = item.shippingMethod;
    }
  });

  return currentShippingMethod;
};


export {
  getShipToAddress,
  getCurrentShippingMethodBuckets,
  getLineItems,
  getShippingMethods,
  isPreferedShippingAddress,
  getInvoiceToAddress,
};
