import React from 'react';
import PropTypes from 'prop-types';
import { Product } from '@utils/product';
import PurchaseDiscountPercentage from '@components/ui_purchase-discount-percentage/component';
import { priceStringToFloat, getFeature, numFormatToCurrency } from '@utils';
import './styles/styles.scss';

/**
 * Generic price block component
 * A single component for every priceblock render on ankeilers, pdp or anywhere else
 */
const PriceBlock = (props) => {
  const { product, variant, originalPrice, priceToPay, size = 'medium', onePrice, noStyling } = props;

  const item = product || new Product();
  const showLabel = getFeature('priceLabel')?.isActive;
  const isFixedGiftcard = item.isGiftcard && item.giftcardDetails.fixed;
  const isVariableGiftcard =  item.isGiftcard && !item.giftcardDetails.fixed;
  const prices = item.getProductPrice();
  const { price1, price2, label1, label2 } = prices;
  const hasDiscount =  priceStringToFloat(price2) && (priceStringToFloat(price2) < priceStringToFloat(price1));

  const defaultPrice =  {
    value: price1 || 0,
    label: label1,
  };

  const reducedPrice = hasDiscount ? {
    value: price2 || 0,
    label: label2,
  } : defaultPrice;

  const variants = { [variant || 'simple']: true };

  // Will render price block if variant = small
  const renderSmallBlock = () => {
    const { percentage } = variants;

    /**
     * Render a single price, the lowest price
     */
    if (!!onePrice) {
      return (
        <div className={`small price-block one-price`}>
          <div>
            <div className={`colored heavy`}>{reducedPrice.value || numFormatToCurrency(priceToPay || 0)}</div>
          </div>
        </div>
      );
    }

    // Render custom priceblock for blackhawk giftcard products
    if (isFixedGiftcard || isVariableGiftcard) {
      return (
        <div className={`small price-block`}>
          <div>
            <div className={`colored heavy`}>{reducedPrice.label}</div>
          </div>
          <div>
            <div className={`colored heavy`}>{reducedPrice.value}</div>
          </div>
        </div>
      );
    }

    /*
     * Render price block if percentual discount is present
     * // TODO: fix label: {defaultPrice.label}
     */
    if (percentage) {
      return (
        <div className={`small price-block`}>
          <div>
            {reducedPrice.label && (
              <>
                {showLabel ? (
                  <div>{reducedPrice.label}&zwnj;</div>
                ) : (
                  <div>&zwnj;</div>
                )}
              </>
            )}
            <div className={`colored heavy`}>Jouw korting</div>
          </div>
          <div>
            {reducedPrice.value && (
              <div>{reducedPrice.value}</div>
            )}
          </div>
        </div>
      );
    }

    // By default render regular price block w/o discount
    return (
      <div className={`small price-block`}>
        {showLabel && (
          <div>
            {!!hasDiscount && (
              <div>&zwnj;</div>
            )}
            <div className={`colored heavy`}>{reducedPrice.label}</div>
          </div>
        )}
        <div>
          {!!hasDiscount && (
            <div className={`striked`}>{defaultPrice.value}</div>
          )}
          <div className={`colored heavy`}>{reducedPrice.value}</div>
        </div>
      </div>
    );
  };

  // Will render price block by default or if variant = medium
  const renderMediumBlock = () => {
    const { percentage, basket } = variants;

    /**
     * Render a single price, the lowest price
     */
    if (!!onePrice && !noStyling) {
      return (
        <div className={`price-block one-price`}>
          <div>
            <div className={`colored heavy`}>{reducedPrice.value}</div>
          </div>
        </div>
      );
    }

    if (!!onePrice && noStyling) {
      return (
        <div className={`price-block one-price`}>
          <div>
            <div>{reducedPrice.value}</div>
          </div>
        </div>
      );
    }

    // Render custom priceblock for blackhawk giftcard products
    if (isFixedGiftcard || isVariableGiftcard) {
      if (basket) {
        return (
          <div className={`medium price-block`}>
            <div>&zwnj;</div>
            <div>
              <div className={'heavy'}>{priceToPay}</div>
            </div>
          </div>
        );
      }

      /**
       * Always show the label, ither in the first div or as part of the price.
       * Position depends on the showLabel feature flag.
       */
      return (
        <div className={`medium price-block`}>
          {showLabel && (
            <div>
              <div className={`colored heavy`}>{reducedPrice.label}</div>
            </div>
          )}
          <div>
            <div className={`colored heavy`}>{(!showLabel && hasDiscount) ? `${reducedPrice.label} ` : ''}{reducedPrice.value}</div>
          </div>
        </div>
      );
    }


    /**
     * Render price block specifically for basket ankeiler
     * Will always have priority
     */
    if (basket) {
      return (
        <div className={`medium price-block`}>
          <div>
            {originalPrice ? (
              <div className={`light striked thin`}>{originalPrice}</div>
            ) : (
              <div>&zwnj;</div>
            )}
          </div>
          <div>
            <div className={'heavy'}>{priceToPay}</div>
          </div>
        </div>
      );
    }
    /*
     * Render price block if percentual discount is present
     * // TODO: fix label: {defaultPrice.label}
     */


    if (percentage) {
      return (
        <div className={`medium price-block`}>
          <div>
            {reducedPrice.label && (
              <>
                {showLabel ? (
                  <div>{reducedPrice.label}&zwnj;</div>
                ) : (
                  <div>&zwnj;</div>
                )}
              </>
            )}
            <div className={`colored heavy`}>Jouw korting</div>
          </div>
          <div>
            <div>{reducedPrice.value}</div>
          </div>
        </div>
      );
    }

    // By default render regular price block w/o discount
    return (
      <div className={`medium price-block`}>
        {showLabel && (
          <div>
            {!!hasDiscount && (
              <div>&zwnj;</div>
            )}
            <div className={`colored heavy`}>{reducedPrice.label}</div>
          </div>
        )}
        <div>
          {!!hasDiscount && (
            <div className={`striked`}>{defaultPrice.value}</div>
          )}
          <div className={`colored heavy`}>{reducedPrice.value}</div>
        </div>
      </div>
    );
  };

  // Will render price block if variant = large
  const renderLargeBlock = () => {
    const { percentage } = variants;
    // Render custom priceblock for blackhawk giftcard products
    if (isFixedGiftcard || isVariableGiftcard) {
      return (
        <div className={`large price-block`}>
          <div>
            <div className={`huge colored heavy`}>
              {hasDiscount ? `${reducedPrice.label} ` : ''}
              {reducedPrice.value}
            </div>
          </div>
        </div>
      );
    }

    /*
     * Render price block if percentual discount is present
     * !! Contains localised texts, but this variant be deprecated soon !!
     * !! and I do not want to introduce the t() method !!
     */
    if (percentage) {
      return (
        <div className={`large price-block`}>
          <div>
            {showLabel && (
              <div className="light">{reducedPrice.label}</div>
            )}
            <div className={`huge thin light`}>{reducedPrice.value}</div>
          </div>
          <div data-test-id="price-dicount">
            <div className="colored heavy"> Jouw korting</div>
            <div className="side-text">korting in je winkelmand</div>
          </div>
        </div>
      );
    }

    // By default render simple price block w/o discount
    return (
      <>
        <div className={`large price-block`}>
          {!!hasDiscount && (
            <div>
              {showLabel && (
                <div className={`light`}>&zwnj;</div>
              )}
              <div className={`huge light striked thin`}>{defaultPrice.value}</div>
            </div>
          )}
          <div>
            {showLabel && (
              <div className={`colored heavy`}>{(!hasDiscount) && reducedPrice.label} &zwnj;</div>
            )}
            <div className={`huge colored heavy`}>
              {reducedPrice.value}
              <div id="dt-price-microcopy"/>
            </div>
          </div>
        </div>
        <PurchaseDiscountPercentage product={item} />
      </>
    );
  };

  if (!item.isOnline) {
    return;
  }

  return {
    small: renderSmallBlock(),
    medium: renderMediumBlock(),
    large: renderLargeBlock(),
  }[size];
};

PriceBlock.propTypes = {
  store: PropTypes.object.isRequired,
  product: PropTypes.object,
  variant: PropTypes.string,
  size: PropTypes.string,
  /** Price to pay, only shows in basket */
  priceToPay: PropTypes.string,
  /** Original price (if discount is applied), only shows in basket */
  originalPrice: PropTypes.string,
  /** Only show the price-to-pay (only for small block) */
  onePrice: PropTypes.bool,
  /** No styling on th price (only on one-price elements) */
  noStyling: PropTypes.bool,
};

export default PriceBlock;
