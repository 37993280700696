class HomePageModel {
  constructor(props = []) {
    const homePage = props.length ? props[0] : {};
    const { components, ...rest } = homePage;

    this.components = components || [];

    Object.assign(this, rest);
  }
}

export default HomePageModel;
