import React, { useContext } from 'react';
import Typeset from '../../base_typeset/component';
import HeaderContext from '../HeaderContext';

import './styles.scss';

const SaleBar = () => {
  const ctx = useContext(HeaderContext);
  const { title, url } = ctx.CMSData.saleBar;

  if (!title) {
    return null;
  }

  return (
    <a  href={url} className={`header-sale-bar  ${!url ? 'no-link' : ''}`}>
      <div className="container">
        <Typeset tag="div" tight bold>{title}</Typeset>
      </div>
    </a>
  );
};

export default SaleBar;
