import { checkFetchReturnStatus, restCall } from './fetch.js';
import restapi from './index.js';

class restapiMyEnv extends restapi {
  constructor() {
    super();
    this.basketId = false;
  }

  getSubscriptions() {
    const url = `${this.baseUrl}tbasubscriptions`;
    return restCall(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  setSubscriptions(subscriptions = {}) {
    const url = `${this.baseUrl}tbasubscriptions`;
    return restCall(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'omit',
      body: JSON.stringify(subscriptions),
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Update a user's password using the current password
   * Use current password for basic authentication
   */
  updateUserPassword(formData) {
    const url = `${this.baseUrl}tbacustomers/-/credentials/password`;
    const token = window.btoa(`${formData.email.toLowerCase()}:${formData.currentPassword}`);
    const body = {
      password: formData.password,
    };

    return restCall(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${token}`,
      },
      credentials: 'omit',
      body: JSON.stringify(body),
    }, false)
      .then(res => checkFetchReturnStatus(res, true)) // status check
      .catch(this.handleError);
  }

  /**
   * Get logged-in user data
   */
  getLoggedInUser(uuid = '-') {
    const url = `${this.baseUrl}tbacustomers/${uuid}`;
    return restCall(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * quick HEAD request to verify is the user is logged in
   */
  pingLoggedInUser(uuid = '-') {
    const url = `${this.baseUrl}tbacustomers/${uuid}`;
    return restCall(url, {
      method: 'HEAD',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .catch(this.handleError);
  }


  /**
   * Get a list of all modules to display in my environment
   */
  getMainhubModules() {
    const url = `${this.baseUrl}getuserdata/`;
    return restCall(url, {
      method: 'GET',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Get a list of links to orders of logged-in user
   */
  getAllOrders({ amount, offset }) {
    return restCall(`${this.baseUrl}orders/tba?amount=${amount}&offset=${offset}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  getOrder({ endpoint }) {
    return fetch(endpoint, {
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Get all users eBooks
   */
  getEbooks({ amount, offset, sortBy }) {
    const [val, dir] = sortBy.split('-');
    const sorting = `${val}&sortdirection=${dir}`;
    const endpoint = `${this.baseUrl}tbacustomers/-/ebooks?amount=${amount}&offset=${offset}&sortby=${sorting}`;

    return restCall(endpoint, {
      method: 'GET',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(response => response.json())  // translate to json
      .catch(this.handleError);
  }

  /**
   * Check if user is loged in
   */
  getLoginVallidation() {
    const endpoint = `${this.baseUrl}tbacustomers/-/`;
    return restCall(endpoint, {
      method: 'GET',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(response => response.json())  // translate to json
      .catch(this.handleError);
  }

  /**
   * Update user login (emnail address)
   */
  updateUserLogin(payload) {
    return restCall(`${this.baseUrl}tbacustomers/-/credentials/login`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .catch(this.handleError);
  }

  /**
   * Get a list of all credits assignes to the logged-in user
   */
  getGiftcards() {
    const url = `${this.baseUrl}tbapayments/giftcard`;
    return restCall(url, {
      method: 'GET',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Assign a giftcard to the logged-in user
   * @param {string} code
   */
  setGiftcard({ code, securityCode, token }) {
    const url = `${this.baseUrl}tbapayments/giftcard?code=${code}&securityCode=${securityCode}`;
    return restCall(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'form-token': token,
      },
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /*
   * Get all invoices available to user
   */
  getInvoices() {
    const url = `${this.baseUrl}invoice`;
    return restCall(url, {
      method: 'GET',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Get the link for a invoice given an invoice number
   *
   * @param {number} documentNumber
   */
  getInvoiceData(documentNumber) {
    const url = `${this.baseUrl}invoices/pdf?documentNumber=${documentNumber}`;
    return restCall(url, {
      method: 'GET',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Get all B2B orders
   *
   * @param {object} payload
   */
  getB2BOrders(payload) {
    const url = `${this._odsSearchBaseUrl}orders`;
    return restCall(url, {
      method: 'POST',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      ods: true,
      interShopBaseUrl: this._baseUrl,
    }, false)
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Get all B2B shipments
   *
   * @param {object} payload
   */
  getB2BShipments(payload) {
    const url = `${this._odsSearchBaseUrl}shipments`;
    return restCall(url, {
      method: 'POST',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      ods: true,
      interShopBaseUrl: this._baseUrl,
    }, false)
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Get all B2B returns
   *
   * @param {object} payload
   */
  getB2BReturns(payload) {
    const url = `${this._odsSearchBaseUrl}returns`;

    return restCall(url, {
      method: 'POST',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      ods: true,
      interShopBaseUrl: this._baseUrl,
    }, false)
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Get all B2B invoices
   *
   * @param {object} payload
   */
  getB2BInvoices(payload) {
    const url = `${this._odsBaseUrl}resources/invoice`;
    return restCall(url, {
      method: 'POST',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      ods: true,
      interShopBaseUrl: this._baseUrl,
    }, false)
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }
  /**
   * Get all B2B mancos
   *
   * @param {object} payload
   */
  getB2BMancos(payload) {
    const url = `${this._odsBaseUrl}/mancos?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`;
    return restCall(url, {
      method: 'GET',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      ods: true,
      interShopBaseUrl: this._baseUrl,
    }, false)
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Get products for an array of SKU's
   *
   * @param {array} skus
   */
  getProductsBySkus(skus) {
    const url = `${this.baseUrl}productaggr`;
    return restCall(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'omit',
      body: JSON.stringify(skus),
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  // Send return request with array of product skus and quantity
  sendReturnRequest(returnsArray) {
    const url = `${this.baseUrl}returnrequest`;
    return restCall(url, {
      method: 'POST',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(returnsArray),
    })
      .then(checkFetchReturnStatus) // status check
      .catch(this.handleError);
  }

  /**
   * Get all shipments which are allowed to be submitted as manco.
   *
   * @param {string} query
   */
  getEligableMancos(query) {
    let url = `${this._odsSearchBaseUrl}/manco/shipments`;
    if (query) {
      url = `${url}?query=${query}`;
    }
    return restCall(url, {
      method: 'GET',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      ods: true,
      interShopBaseUrl: this._baseUrl,
    }, false)
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  submitAllMancos(payload) {
    const url = `${this.baseUrl}mancorequest`;
    return restCall(url, {
      method: 'POST',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(checkFetchReturnStatus) // status check
      .catch(this.handleError);
  }

  /**
   * Place a bulk order for B2B.
   *
   * @param {array} products
   */
  placeBulkOrder(products) {
    const url = `${this.baseUrl}b2border`;
    return restCall(url, {
      method: 'POST',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(products),
    })
      .then(checkFetchReturnStatus) // status check
      .catch(this.handleError);
  }

  /**
   * Get all B2B signup campaigns
   *
   */
  getB2BSignupCampaigns() {
    const url = `${this._odsBaseUrl}/signupcampaigns`;
    return restCall(url, {
      method: 'GET',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      ods: true,
      interShopBaseUrl: this._baseUrl,
    }, false)
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Get all B2B signup campaign products
   *
   *  @param {id} campaign id
   */
  getB2BSignupCampaignProducts(id) {
    const url = `${this._odsBaseUrl}/signupcampaigns/${id}/products`;
    return restCall(url, {
      method: 'GET',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      ods: true,
      interShopBaseUrl: this._baseUrl,
    }, false)
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Get all Accounts belonging to a customer id
   *
   */
  getAccountsByCustomerId(id) {
    const url = `${this.baseUrl}tbacustomers/b2b?searchTerm=${id}`;
    return restCall(url, {
      method: 'GET',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Select the acount of another customer
   */
  selectCustomerAccount(businessPartnerNo) {
    return restCall(`${this.baseUrl}tbacustomers/-/salesrep?customerno=${businessPartnerNo}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      // body: JSON.stringify(payload),
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }
}

export default restapiMyEnv;
