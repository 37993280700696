import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HEADER } from '@config';
import Link from '../../base_link/component';
import Icon from '../../base_svg-icon/component';
import Text from '@text';
import { t } from '@utils/i18n';
import SiteRating from '../../ui_site-rating/component';
import { getDevice } from '@utils';

import './styles.scss';

const UspBar = ({ links }) => {
  const [showUspBar, setShowUspBar] = useState(false);

  useEffect(() => {
    const { isLaptop, isDesktop } = getDevice();

    if (isLaptop || isDesktop) {
      setShowUspBar(true);
    }
  }, []);

  const renderUspList = () => {
    return (
      <div className="usp-list">
        {HEADER.usp.regular.map((usp, index) => {
          const { text, icon, link } = usp;
          if (!t(text)) {
            return null;
          }
          return (
            <Link key={index} className="usp-list-item" to={t(link)}>
              {icon && (<Icon name={icon} />)}
              <span>
                <Text>{text}</Text>
              </span>
            </Link>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {showUspBar && (
        <div className="usp-bar">
          <div className="container">
            {renderUspList()}
            <div className="additional-links">
              <SiteRating />
              {links.map(link => <Link key={link.url} to={link.url} data-test-id={`usp${link.title}`}>{link.title}</Link>)}
            </div>
          </div>
        </div>
      )}
    </>

  );
};

UspBar.propTypes = {
  links: PropTypes.array,
};

export default UspBar;
