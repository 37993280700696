class BlogOverviewModel {
  constructor(props = []) {
    const blogOverview = props.length ? props[0] : {};
    const components = blogOverview.elements[0].components || [];

    this.components = components.map((component) => {
      const { 'component-id': id, title } = component;
      const url = id.replace('blog_', '/blog/');
      return { ...component, url, name: title };
    });
    Object.assign(this, blogOverview);
  }
}

export default BlogOverviewModel;

