const deprecatedRoutes = [
  '/muziek-top-10',
  '/film-tv-serie-top-10',
  '/games-top-10',
  '/muziek-top-10/:slug',
  '/film-tv-serie-top-10/:slug',
  '/games-top-10/:slug',
  '/categorie/muziek/:slug/:id',
  '/categorie/film/:slug/:id',
  '/categorie/games/:slug/:id',
  '/actie/film/3-films-voor-25',
  '/actie/film/4k-blu-rays-2-voor-35',
  '/actie/film/4k-blu-rays-nu-14-99',
  '/actie/film/disney-pixar-festival',
  '/actie/film/film-tv-sale',
  '/actie/film/marvel-4k-blu-rays-in-prijs-verlaagd',
  '/actie/film/star-wars-marvel-met-korting',
  '/actie/film/tv-series-2-voor-30',
  '/actie/games/topgames-met-hoge-korting',
  '/actie/muziek/2-cd-s-voor-16',
  '/actie/muziek/muziek-dvd-s-en-cd-s-nu-vanaf-8-99',
  '/actie/spel-speelgoed/black-friday-voordeelpakketten',
  '/actie/spel-speelgoed/puzzels-in-prijs-verlaagd',
  '/actie/spel-speelgoed/ravensburger-puzzels-2-voor-25',
  '/film',
  '/film-tv-serie-top-10',
  '/film-tv-serie-top-10/3d-blu-ray-top-10',
  '/film-tv-serie-top-10/4k-blu-ray-top-10',
  '/film-tv-serie-top-10/beste-films-top-10',
  '/film-tv-serie-top-10/beste-tv-series-top-10',
  '/film-tv-serie-top-10/blu-ray-top-10',
  '/film-tv-serie-top-10/dvd-top-10',
  '/film-tv-serie-top-10/film-tv-serie-2021-top-10',
  '/film-tv-serie-top-10/kinder-dvd-top-10',
  '/film/cabaret-theater/cabaret',
  '/film/cabaret-theater/kind-jeugd',
  '/film/cabaret-theater/theater',
  '/film/documentaire/algemeen',
  '/film/documentaire/biografie',
  '/film/documentaire/geschiedenis',
  '/film/documentaire/landen-reizen',
  '/film/documentaire/natuur',
  '/film/documentaire/oorlog',
  '/film/documentaire/sport',
  '/film/documentaire/vrije-tijd-hobby',
  '/film/muziek-dvd-s-blu-rays/algemeen',
  '/film/muziek-dvd-s-blu-rays/amusement',
  '/film/muziek-dvd-s-blu-rays/dance-hip-hop-rap',
  '/film/muziek-dvd-s-blu-rays/jazz-blues-country-folk',
  '/film/muziek-dvd-s-blu-rays/klassiek',
  '/film/muziek-dvd-s-blu-rays/musical',
  '/film/muziek-dvd-s-blu-rays/pop',
  '/film/muziek-dvd-s-blu-rays/r-b-soul',
  '/film/muziek-dvd-s-blu-rays/religieus',
  '/film/muziek-dvd-s-blu-rays/rock-alternatief',
  '/film/muziek-dvd-s-blu-rays/speelfilm',
  '/film/speelfilm/actie-avontuur',
  '/film/speelfilm/algemeen',
  '/film/speelfilm/cult',
  '/film/speelfilm/drama',
  '/film/speelfilm/erotiek',
  '/film/speelfilm/familie',
  '/film/speelfilm/filmhuis',
  '/film/speelfilm/kind-jeugd',
  '/film/speelfilm/komedie',
  '/film/speelfilm/muziek-dans',
  '/film/speelfilm/oorlog',
  '/film/speelfilm/romantiek',
  '/film/speelfilm/sf-fantasy-horror',
  '/film/speelfilm/thriller-crime',
  '/film/speelfilm/western',
  '/film/tekenfilms-animatie/actie',
  '/film/tekenfilms-animatie/algemeen',
  '/film/tekenfilms-animatie/disney',
  '/film/tekenfilms-animatie/drama',
  '/film/tekenfilms-animatie/familie',
  '/film/tekenfilms-animatie/kind-jeugd',
  '/film/tekenfilms-animatie/komedie',
  '/film/tekenfilms-animatie/romantiek',
  '/film/tekenfilms-animatie/sf-fantasy-horror',
  '/film/tekenfilms-animatie/thriller',
  '/film/tv-series/actie-avontuur',
  '/film/tv-series/algemeen',
  '/film/tv-series/drama',
  '/film/tv-series/familie',
  '/film/tv-series/kind-jeugd',
  '/film/tv-series/komedie',
  '/film/tv-series/sf-fantasy-horror',
  '/film/tv-series/thriller-crime',
  '/film/tv-series/volwassenen',
  '/games',
  '/games-top-10',
  '/games-top-10/nintendo-switch-games-top-10',
  '/games-top-10/pc-games-top-10',
  '/games-top-10/ps4-games-top-10',
  '/games-top-10/ps5-games-top-10',
  '/games-top-10/xbox-one-games-top-10',
  '/games-top-10/xbox-series-x-games-top-10',
  '/games/diversen/hardware-accessoires',
  '/games/nintendo-ds/hardware-accessoires',
  '/games/nintendo-switch/actie',
  '/games/nintendo-switch/avontuur',
  '/games/nintendo-switch/entertainment',
  '/games/nintendo-switch/hardware-accessoires',
  '/games/nintendo-switch/race',
  '/games/nintendo-switch/simulatie',
  '/games/nintendo-switch/sport',
  '/games/nintendo-switch/strategie',
  '/games/nintendo-switch/vecht',
  '/games/nintendo-wii-u/hardware-accessoires',
  '/games/nintendo-wii/hardware-accessoires',
  '/games/pc-games/actie',
  '/games/pc-games/avontuur',
  '/games/pc-games/entertainment',
  '/games/pc-games/hardware-accessoires',
  '/games/pc-games/race',
  '/games/pc-games/sport',
  '/games/pc-games/strategie',
  '/games/pc-software/anti-virus-beveiliging',
  '/games/playstation-3/hardware-accessoires',
  '/games/playstation-4/actie',
  '/games/playstation-4/avontuur',
  '/games/playstation-4/hardware-accessoires',
  '/games/playstation-4/race',
  '/games/playstation-4/shooter',
  '/games/playstation-4/sport',
  '/games/playstation-4/strategie',
  '/games/playstation-4/vecht',
  '/games/playstation-5/avontuur',
  '/games/playstation-5/hardware-accessoires',
  '/games/playstation-5/race',
  '/games/playstation-5/simulatie',
  '/games/playstation-5/sport',
  '/games/playstation-5/strategie',
  '/games/spel-speelgoed/puzzels',
  '/games/xbox-one/avontuur',
  '/games/xbox-one/hardware-accessoires',
  '/games/xbox-one/race',
  '/games/xbox-one/sport',
  '/games/xbox-series-x/avontuur',
  '/games/xbox-series-x/race',
  '/games/xbox-series-x/sport',
  '/games/xbox-series-x/strategie',
  '/kenmerk/film/3d-blu-ray',
  '/kenmerk/film/4k-blu-ray',
  '/kenmerk/film/alles-van-frozen-2',
  '/kenmerk/film/blu-ray',
  '/kenmerk/film/dvd',
  '/kenmerk/film/te-reserveren-toptitels',
  '/kenmerk/games/far-cry-6',
  '/kenmerk/games/fortnite',
  '/kenmerk/games/headsets',
  '/kenmerk/games/just-dance-2022',
  '/kenmerk/games/mario-en-vrienden-games',
  '/kenmerk/games/nieuwe-games-voor-kids',
  '/kenmerk/games/pokemon-violet-scarlet',
  '/kenmerk/games/pre-order-games-najaar-2022',
  '/kenmerk/games/race-games',
  '/kenmerk/games/sales-games',
  '/kenmerk/muziek/cult-legends',
  '/kenmerk/muziek/de-muziektoppers-van-dit-moment',
  '/kenmerk/muziek/de-topreleases-van-het-najaar',
  '/kenmerk/muziek/muziek-op-cd',
  '/kenmerk/muziek/muziek-op-lp-vinyl',
  '/kenmerk/spel-speelgoed/alles-van-friends',
  '/kenmerk/spel-speelgoed/alles-van-frozen',
  '/kenmerk/spel-speelgoed/alles-van-harry-potter',
  '/kenmerk/spel-speelgoed/alles-van-monopoly',
  '/kenmerk/spel-speelgoed/alles-van-peppa-pig',
  '/kenmerk/spel-speelgoed/alles-van-star-wars',
  '/kenmerk/spel-speelgoed/alles-van-studio-100',
  '/kenmerk/spel-speelgoed/alles-voor-de-pokemon-fan',
  '/kenmerk/spel-speelgoed/boekenlampjes',
  '/kenmerk/spel-speelgoed/challenge-puzzels',
  '/kenmerk/spel-speelgoed/de-leukste-spellen-voor-2-personen',
  '/kenmerk/spel-speelgoed/de-leukste-spellen-voor-de-hele-familie',
  '/kenmerk/spel-speelgoed/de-leukste-spellen-voor-de-zomervakantie',
  '/kenmerk/spel-speelgoed/disney-puzzels',
  '/kenmerk/spel-speelgoed/escape-puzzels',
  '/kenmerk/spel-speelgoed/geomag-supercolor',
  '/kenmerk/spel-speelgoed/gezien-in-de-inspiratiegids',
  '/kenmerk/spel-speelgoed/giftboxen',
  '/kenmerk/spel-speelgoed/gravitrax',
  '/kenmerk/spel-speelgoed/ik-leer-ontdekken',
  '/kenmerk/spel-speelgoed/jan-van-haasteren',
  '/kenmerk/spel-speelgoed/jan-van-haasteren-feestdagen',
  '/kenmerk/spel-speelgoed/klassieke-spellen',
  '/kenmerk/spel-speelgoed/la-casa-de-papel',
  '/kenmerk/spel-speelgoed/marble-racetrax',
  '/kenmerk/spel-speelgoed/mario-friends',
  '/kenmerk/spel-speelgoed/metalen-drinkflessen',
  '/kenmerk/spel-speelgoed/nerf-blasters',
  '/kenmerk/spel-speelgoed/play-doh',
  '/kenmerk/spel-speelgoed/playmobil',
  '/kenmerk/spel-speelgoed/populaire-puzzels',
  '/kenmerk/spel-speelgoed/puzzels-landen-en-beroemde-plekken',
  '/kenmerk/spel-speelgoed/schilderen-op-nummer',
  '/kenmerk/spel-speelgoed/spelen-leren',
  '/kenmerk/spel-speelgoed/spellen-gebaseerd-op-films-en-series',
  '/kenmerk/spel-speelgoed/spellen-voor-de-wintermaanden',
  '/kenmerk/spel-speelgoed/superhelden-gadgets',
  '/kenmerk/spel-speelgoed/wasgij-puzzels',
  '/muziek',
  '/muziek-top-10',
  '/muziek-top-10/cd-top-10',
  '/muziek-top-10/klassieke-muziek-top-10',
  '/muziek-top-10/lp-vinyl-top-10',
  '/muziek-top-10/muziek-dvd-blu-ray-top-10',
  '/muziek-top-10/muziek-in-2021-top-10',
  '/muziek-top-10/nederlandstalige-muziek-top-10',
  '/muziek-top-10/nieuwe-muziek-top-10',
  '/muziek/amusement/algemeen',
  '/muziek/amusement/chansons',
  '/muziek/amusement/instrumentaal',
  '/muziek/amusement/kerst',
  '/muziek/amusement/kind-jeugd',
  '/muziek/amusement/luisterliedjes',
  '/muziek/amusement/nederlandstalig',
  '/muziek/amusement/religieus',
  '/muziek/amusement/volksmuziek',
  '/muziek/dance-house-hip-hop-rap/dance',
  '/muziek/dance-house-hip-hop-rap/electronic-music',
  '/muziek/dance-house-hip-hop-rap/hip-hop-rap',
  '/muziek/jazz-blues-country-folk/blues',
  '/muziek/jazz-blues-country-folk/country',
  '/muziek/jazz-blues-country-folk/folk',
  '/muziek/jazz-blues-country-folk/gospel',
  '/muziek/jazz-blues-country-folk/jazz',
  '/muziek/klassiek/algemeen',
  '/muziek/klassiek/opera-operette',
  '/muziek/klassiek/religieus',
  '/muziek/musical-soundtrack-cabaret/cabaret',
  '/muziek/musical-soundtrack-cabaret/musical',
  '/muziek/musical-soundtrack-cabaret/soundtracks',
  '/muziek/muziekaccessoires/koptelefoons-microfoons',
  '/muziek/muziekaccessoires/portable-audio',
  '/muziek/muziekaccessoires/speakers',
  '/muziek/pop/algemeen',
  '/muziek/pop/instrumentaal',
  '/muziek/pop/kerst',
  '/muziek/pop/kind-jeugd',
  '/muziek/pop/nederlandstalig',
  '/muziek/r-b-soul/funk',
  '/muziek/r-b-soul/r-b',
  '/muziek/r-b-soul/soul',
  '/muziek/rock-metal/alternatief',
  '/muziek/rock-metal/hardrock-heavy-metal',
  '/muziek/rock-metal/rock',
  '/muziek/verzamel-cd-s/amusement',
  '/muziek/verzamel-cd-s/klassiek',
  '/muziek/verzamel-cd-s/pop',
  '/muziek/wereldmuziek/algemeen',
  '/muziek/wereldmuziek/keltisch',
  '/muziek/wereldmuziek/latin',
  '/muziek/wereldmuziek/new-age',
  '/muziek/wereldmuziek/reggae',
  '/spel-speelgoed-top-10',
  '/spel-speelgoed-top-10/bordspellen-top-10',
  '/spel-speelgoed-top-10/dobbelspellen-top-10',
  '/spel-speelgoed-top-10/educatieve-spellen-top-10',
  '/spel-speelgoed-top-10/kaartspellen-top-10',
  '/spel-speelgoed-top-10/puzzel-top-10',
  '/spel-speelgoed-top-10/speelgoed-top-10',
  '/spel-speelgoed-top-10/spellen-top-10',
  '/thema/filmhuis/aziatische-films',
  '/thema/filmhuis/boekverfilmingen',
  '/thema/filmhuis/franse-films',
  '/thema/filmhuis/gouden-palm-winnaars',
  '/thema/filmhuis/lgtb-films',
  '/thema/filmhuis/oscarwinnaars',
  '/thema/sint/film-tv-tips-van-sinterklaas',
  '/thema/sint/games-sinterklaas',
  '/thema/sint/muziektips-van-sinterklaas',
];

export default deprecatedRoutes;
