import React from 'react';
import PropTypes from 'prop-types';
import TypeSet from '../../base_typeset/component';
import Link from '../../base_link/component';
import Image from '../../base_image/component';

import './styles.scss';

const MediaBlock = ({ onClickEvent = () => {}, title, url, linkText, image }) => {
  return (
    <>
      <div className="menu-media">
        <Link to={url}>
          <Image src={image}/>
        </Link>
      </div>
      <div className="menu-list-media media-link-text">
        <ul>
          <li>
            <Link to={url} onClick={onClickEvent}>
              <TypeSet scale="1">{linkText || title}</TypeSet>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

MediaBlock.propTypes = {
  onClickEvent: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.string,
  linkText: PropTypes.string,
  image: PropTypes.string,
};

export default MediaBlock;
