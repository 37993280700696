class MenuItemModel {
  constructor(props = {}) {
    const { components = [], ...rest } = props;
    this.mediaBlocks = [];
    const linkLists = [];

    components.forEach((component) => {
      const { url, title, components, image } = component;
      // check if component is a media block or link list
      if (image) {
        this.mediaBlocks.push(component);
      } else if (components) {
        const links = components.map(({ title, url }) => ({ title, url }));
        linkLists.push({ url, title, links });
      }
    });

    this.mainLinkList = linkLists.length ? linkLists[0] : null;
    this.otherLinkLists = (linkLists.length > 1) ? linkLists.slice(1, 99) : [];

    Object.assign(this, rest);
  }
}

export default MenuItemModel;
