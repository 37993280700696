import { numFormatToCurrency } from '.';
import { Product } from './product';
import store from './store.js';

/**
 * Remove all single- and double quotes from string
 *
 * @param {string} string
 * @returns {string}
 */
const sanitizeString = string => string.replace(/['"]/gi, '');

/**
 * Replace all comma's with dot
 *
 * @param {string} string
 * @returns {string}
 */
const noCommas = string => string.replace(',', '.');


const productPrices = (product) => {
  const { price1, price2 } = product.getProductPrice();
  const hasDiscount = price2 && (parseFloat(price2) < parseFloat(price1));
  const lineItemPrice = product.singleBasePrice || 0;

  return {
    defaultPrice: (price1 && noCommas(price1)) || lineItemPrice || 'N/A',
    reducedPrice: (hasDiscount ? noCommas(price2) : noCommas(price1)) || lineItemPrice || 'N/A',
    discount: hasDiscount ? (parseFloat(price1) - parseFloat(price2)).toFixed(2) : '0.00',
  };
};

/**
 * Function that returns an object with data
 * of a given product shaped for Google Tag
 *
 * @param {Object|Product} product
 * @returns {Object}
 */
const productGoogleTag = (product) => {
  const productInstance = (product instanceof Product) ? product : new Product(product);
  const { reducedPrice } = productPrices(productInstance);
  return {
    name: sanitizeString(productInstance.name),
    id: productInstance.sku,
    price: reducedPrice,
    category: sanitizeString(productInstance.category) || 'N/A',
    brand: sanitizeString(productInstance.brand) || 'N/A',
    dimension1: sanitizeString(productInstance.getPersonNames()) || 'N/A',
    dimension2: productInstance.tbaproducttype || 'N/A',
    quantity: productInstance.quantity || 'N/A',
  };
};

/**
 * Function that returns an object with data
 * of a given product in the 'productListing' object
 * shaped for Google Tag
 *
 * @param {Object} obj
 * @param {Product} obj.product
 * @param {string} obj.list
 * @param {number} obj.position
 *
 */
const listProductGoogleTag = ({ product, list, position }) => {
  const productInstance = (product instanceof Product) ? product : new Product(product);
  const { reducedPrice } = productPrices(productInstance);

  return {
    name: sanitizeString(productInstance.name) || 'N/A',
    id: productInstance.id,
    price: reducedPrice,
    category: sanitizeString(productInstance.category) || 'N/A',
    brand: sanitizeString(productInstance.brand) || 'N/A',
    dimension1: sanitizeString(productInstance.getPersonNames()) || 'N/A',
    list,
    position,
  };
};

/**
 * Function that returns an object with data
 * of a given product shaped for Google Tag
 *
 * @param {Object|Product} product
 * @returns {Object}
 */
const extendedProductGoogleTag = (product) => {
  const productInstance = (product instanceof Product) ? product : new Product(product);
  const { reducedPrice, defaultPrice, discount } = productPrices(productInstance);

  return {
    name: sanitizeString(productInstance.name) || 'N/A',
    id: productInstance.sku,
    price: reducedPrice,
    category: sanitizeString(productInstance.category) || 'N/A',
    brand: sanitizeString(productInstance.brand) || 'N/A',
    dimension1: sanitizeString(productInstance.getPersonNames()) || 'N/A', // product_author
    dimension2: productInstance.tbaproducttype || 'N/A', // product_type
    metric3: discount,
    metric4: reducedPrice, // product_price_member, price for members
    metric5: defaultPrice, // product_price_full, This is the price as dislayed before the possible discount
    dimension7: productInstance.getPersonsUrls(), // product_author_id TODO: should be id
    dimension8: productInstance.pages, // product_book_pages
    dimension9: '', // product_delivery_time_minimum in days
    dimension10: '', // product_delivery_time_maximum in days
    dimension11: productInstance.language, // product_language
    dimension12: productInstance.keywords, // product_tags, comma separated tags set for the product
    dimension16: '', // product_detail_leesstuk, product excerpt shown on product detail page
    dimension17: '', // product_status, status of the product in the store, values are selling / to-be-released / sold-out
    dimension18: '', // product_detail_comparison, product comparison set on product detail page, true/false
    dimension19: '', // product_detail_flipbook, product image boek omdraaien functionality available on page, true/false
    dimension20: productInstance.imageLength, // product_detail_imagecount, number of thumbnail images for product
    dimension21: '', // product_detail_freedelivery, Free delivery image added to product detail page, true/false
    dimension22: '', // product_detail_expertreview, expert review is shown on product detail page, true/false
    dimension23: '', // product_detail_recommendations, product suggesties sectie is set on the product detail page, true/false
    dimension24: '', // product_secondhand, Tweede hands aanwezig, true/false
    dimension25: '', // product_price_discount_friends, Vriendenkorting actief, true/false
    quantity: productInstance.quantity, // mag verwijderd worden
  };
};

/**
 * Function that returns an object with data
 * of a given product shaped for Google Tag
 *
 * @param {Object} promotion
 * @param {number=} position
 * @returns {Object}
 */
const promotionGoogleTag = (promotion, position = 1) => {
  const pageType = document.body.id.toLowerCase() || 'other';
  const id = [pageType, 'promotion', promotion['component-type'], promotion.catalog, promotion.variant, position].filter(Boolean)
    .join('-');
  return {
    id,
    name: promotion.title || promotion.name || promotion.displayName || 'N/A',
    creative: promotion.backgroundImage || promotion.creative || promotion.leftImage || promotion.image || (promotion.images ? promotion.images.Image : ''),
    position,
    url: promotion.url,
  };
};

/**
 * Function that returns a list of objects
 * with data of products from the basket
 * shaped for Google Tags
 *
 * @param {Product[]| Object[]} productList
 * @param {boolean=} extendedProduct
 */
const productListGoogleTag = (productList, extendedProduct = false) => {
  const products = [];
  for (const product of productList) {
    const productInstance = (product instanceof Product) ? product : new Product(product);
    products.push(extendedProduct ? extendedProductGoogleTag(productInstance) : productGoogleTag(productInstance));
  }
  return products;
};

/**
 * Function that returns a list of objects
 * with data of products from the basket
 * but concatenated to create only one object per product
 * shaped for Google Tags
 *
 * @param {Product[]| Object[]} productList
 * @returns {Array}
 */
const summaryProductListGoogleTag = (productList) => {
  const storedBookarangAdvice = JSON.parse(localStorage.getItem('bookarangAdvice') || '{}');

  // First map all products to increment quantity
  const map = {};
  for (const product of productList) {
    const productInstance = (product instanceof Product) ? product : new Product(product);
    const formattedProduct = productGoogleTag(productInstance);
    if (map[formattedProduct.id]) {
      map[formattedProduct.id].quantity += 1;
    } else {
      map[formattedProduct.id] = formattedProduct;
    }

    // Check if product is stored and append advice || null
    map[formattedProduct.id].adviceId = storedBookarangAdvice[formattedProduct.id] || null;
  }

  const products = [];
  for (const product of Object.values(map)) {
    products.push(product);
  }

  return products;
};

/**
 * Function that returns a
 * Google Tag object
 *
 * @param {string} event
 * @param {Object} tag
 *
 * @returns {Object}
 */
const genericGoogleTag = (event, tag) => {
  return {
    event,
    ecommerce: tag,
  };
};

/**
 * Function that returns a addToCart Google Tag
 * with data of added product
 *
 * @param {Product|Object} product
 * @returns {Object}
 */
const addToCartGoogleTag = product => genericGoogleTag('addToCart', {
  currencyCode: product?.price?.currencyMnemonic || 'EUR',
  add: {
    products: productListGoogleTag([product]),
  },
});

/**
 * Receives the step and the checkout state/step option
 * and returns a GTM checkout object
 *
 * @param {number} step
 * @param {string} checkoutOption
 * @param {Product[]} products
 * @param {boolean=} summary
 * @returns {Object}
 */
const checkoutGoogleTag = (step, checkoutOption, products, summary = false) => genericGoogleTag('checkout', {
  checkout: {
    actionField: {
      step,
      option: checkoutOption,
      action: 'checkout',
      summary,
    },
    products: summary ? summaryProductListGoogleTag(products) : productListGoogleTag(products),
  },
});

/**
 * Receives a product and returns
 * a GTM removeFromCart object
 *
 * @param {Product} product
 * @returns {Object}
 */
const removeFromCartGoogleTag = product => genericGoogleTag('removeFromCart', {
  currencyCode: product.price.currencyMnemonic,
  remove: {
    products: [productListGoogleTag([product])],
  },
});

/**
 * Receives an order and a list of products and  returns
 * a GTM purchase Google Tag object
 *
 * @param {Object} orderData
 * @param {Product[]} products
 * @param {boolean=} summary
 *
 * @returns {Object}
 */
const purchaseGoogleTag = (orderData, products, summary = true, email) => {
  const { user = {} } = store.getBasket();
  const userEmail = email || user.email;
  const hasPaymentMethod = (!!orderData.payments && !!orderData.payments.length);
  const couponCodes = [];

  // Add discount codes on whole order level to the couponCodes array
  orderData?.valueRebates?.forEach(rebate => couponCodes.push(rebate.code));

  // Add discount codes on product level to the couponCodes array
  const lineItemsOrderData = [];
  orderData?.shippingBuckets?.forEach(shippingBucket => lineItemsOrderData.push(shippingBucket.lineItems));
  const rebatesPerItem = [];
  lineItemsOrderData[0]?.forEach(lineItem => rebatesPerItem.push(lineItem.valueRebates));
  rebatesPerItem?.map(rebates => rebates?.forEach(rebate => couponCodes.push(rebate.code)));

  // Add discount codes for shipping costs to the couponCodes array
  orderData?.shippingRebates?.forEach(rebate => couponCodes.push(rebate.code));

  const tag = genericGoogleTag('purchase', {
    purchase: {
      actionField: {
        id: orderData.documentNo,
        revenue: numFormatToCurrency((orderData.totals.orderTotal.value || 0), 'en-US'),
        tax: numFormatToCurrency((orderData.totals.taxTotal.value || 0), 'en-US'),
        shipping: numFormatToCurrency((orderData.totals.shippingTotal.value || 0), 'en-US'),
        paymentMethod: hasPaymentMethod ? orderData.payments[0].displayName : 'n/a',
        action: 'purchase',
        couponCode: couponCodes.length > 0 ? couponCodes : 'none',
      },
      products: summary ? summaryProductListGoogleTag(products) : productListGoogleTag(products),
      profile: {
        email: userEmail,
      },
    },
  });
  clearStoredAdvice();
  return tag;
};

/**
 * Receives a product and returns
 * a GTM product click Google Tag object
 *
 * @param {Product} product
 * @param {number=} position
 * @returns {Object}
 */
const productClickGoogleTag = ({ product, position = 0, adviceId = null }) => {
  if (!!adviceId) {
    const productInstance = (product instanceof Product) ? product : new Product(product);
    setStoredAdvice({ sku: productInstance.sku, adviceId });
  }
  const list = getEventList({ adviceId });
  return genericGoogleTag('productClick', {
    click: {
      actionField: { list },
      products: [{ ...productGoogleTag(product), position, adviceId }],
    },
  });
};

/**
 * Receives a product and returns
 * a GTM addToWishlist object
 *
 * @param {Product} product
 * @returns {Object}
 */
const addToWishlistGoogleTag = (product) => {
  return genericGoogleTag('add_to_wishlist', {
    currencyCode: product?.price?.currencyMnemonic || 'EUR',
    add: {
      products: productListGoogleTag([product]),
    },
  });
};

/**
 * Receives userdata as object and returns
 * it as a GTM  object
 *
 * @param {object}
 * @returns {Object}
 */
const loginGoogleTag = (user) => {
  return genericGoogleTag('login', {
    user,
  });
};

/**
 * Get the list name based on various 'custom' parameters.
 *
 * @param {object}
 * @returns {string}
 */
const getEventList = ({ adviceId }) => {
  let list = 'N/A';
  if (!!adviceId) {
    list = 'giftpicker';
  }
  if (document.body.id === 'SearchResults') {
    list = 'SearchResults';
  }
  return list;
};

/**
 * Store sku's with the corresponding adviceId in the localStorage
 * @param {{string, string}} { sku, adviceId }
 */
const setStoredAdvice = ({ sku, adviceId = null }) => {
  const storedAdvice = JSON.parse(localStorage.getItem('bookarangAdvice') || '{}');
  storedAdvice[sku] = adviceId;
  localStorage.setItem('bookarangAdvice', JSON.stringify(storedAdvice));
  return;
};

const clearStoredAdvice = () => {
  localStorage.removeItem('bookarangAdvice');
};

/**
 * Receives a promotion and returns
 * a GTM promotion click Google Tag object
 *
 * @param {Object} promotion
 * @returns {Object}
 */
const promotionClickGoogleTag = (promotion = {}) => {
  const promoImage = (promotion.images ? promotion.images.Image : '');
  const images = [promotion.backgroundImage, promotion.creative, promotion.leftImage, promotion.image, promoImage].filter(Boolean);
  if (!!images.length && !!promotion.url) {
    return genericGoogleTag('promotionClick', {
      promoClick: {
        promotions: [promotionGoogleTag(promotion)],
      },
    });
  }
  return false;
};

/**
 * Function that returns a addToCart Google Tag
 * with data of added product
 *
 * @param {Product|Object} product
 * @returns {Object}
 */
const productDetailGoogleTag = product => genericGoogleTag('productDetail', {
  detail: {
    products: productListGoogleTag([product], true),
  },
});

/**
 * Function that returns a promotion list Google Tag
 * with data of added product
 *
 * @param {Object[]} promotions
 * @returns {Object}
 */
const promotionListGoogleTag = (promotions) => {
  // Only return promotions with an image and url
  const filteredPromotions = promotions.filter((promotion) => {
    const promoImage = (promotion.images ? promotion.images.Image : '');
    const images = [promotion.backgroundImage, promotion.creative, promotion.leftImage, promotion.image, promoImage].filter(Boolean);
    return !!images.length && !!promotion.url;
  });

  if (!!filteredPromotions.length) {
    return genericGoogleTag('promotionImpressions', {
      promoView: {
        promotions: filteredPromotions,
      },
    });
  }
};

const productsViewGoogleTag = promotions => genericGoogleTag('productListing', {
  impressions: {
    promotions,
  },
});

/**
 * Function that returns a 'productListing' object
 * for Google tag manager
 *
 * @param {Object} obj
 * @param {Object[]} obj.productList
 * @param {string} obj.list
 * @returns {Object}
 */
const productListingGoogleTag = ({ productList, list }) => {
  const products = [];
  for (const [index, product] of productList.entries()) {
    const position = index + 1;
    const listName = list || product.list || 'N/A';
    products.push(listProductGoogleTag({ product, list: listName, position }));
  }
  return genericGoogleTag('productListing', {
    impressions: products,
  });
};

// #region Fixed Variables

/**
 * Function that returns a
 * platform version tag object
 *
 * @param {string} version
 * @returns {Object}
 */
const platformVersionTag = (version) => {
  return {
    platform_template_version: version,
  };
};

/**
 * Function that returns a
 * platform environment tag object
 *
 * @param {string} platform_environment
 * @returns {Object}
 */
const platformEnvironmentTag = (platform_environment) => {
  return {
    platform_environment,
  };
};

/**
 * Function that returns a
 * pagination google tag object
 *
 * @param {string} url
 * @returns {Object}
 */
const paginationTag = (url) => {
  return {
    event: 'virtual_page',
    page_virtual: url,
  };
};

/**
 * Create object for virtual page view
 *
 * @param {string} url
 * @param {string} title
 * @returns {object}
 */
const routeChange = ({ url = 'N/A', title = 'N/A', id = 'Unknown' }) => {
  return {
    pageType: id,
    pageUrl: url,
    pageTitle: title,
    event: 'routeChange',
  };
};

export {
  genericGoogleTag,
  productListGoogleTag,
  productGoogleTag,
  extendedProductGoogleTag,
  promotionGoogleTag,
  addToCartGoogleTag,
  checkoutGoogleTag,
  removeFromCartGoogleTag,
  purchaseGoogleTag,
  productClickGoogleTag,
  promotionClickGoogleTag,
  productDetailGoogleTag,
  promotionListGoogleTag,
  productsViewGoogleTag,
  addToWishlistGoogleTag,
  loginGoogleTag,
  platformVersionTag,
  platformEnvironmentTag,
  summaryProductListGoogleTag,
  productListingGoogleTag,
  paginationTag,
  setStoredAdvice,
  clearStoredAdvice,
  routeChange,
};
