import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Api from '@utils/api/MyEnvironment';
import store from '@utils/store';
// import { getCookieValue } from '@utils'; // uncommend when api data is used for recently viewed

const AppContext = createContext(null);

const AppProvider = ({ i18n, children, wishlist: initialWishlist = [] }) => {
  const [previousUrl, setPreviousUrl] = useState('/');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setWishlist(initialWishlist);
  }, [initialWishlist]);

  /**
   * Fetch all wishlist items from the user's account
   */
  const [wishlist, setWishlist] = useState(initialWishlist);
  const fetchWishlist = () => {
    const api = new Api();
    api.getWishlistSkus()
      .then((response) => {
        store.events.publish('fetchedWishlist', response);
        setWishlist(response);
      });
  };

  /**
   * If anywhere in the app the message 'updatedWishlist' is published, we
   * will fetch the wishlist again to update the context.
   */
  useEffect(() => {
    store.events.subscribe('updatedWishlist', fetchWishlist);
    store.events.subscribe('fetchedWishlist', setWishlist);
  }, []);

  /*
   * When api is used to get product data
   * Refactor to just saving skus here for recently viewed and not entire product data
   * First get the skus from the existing cookie and add the new product to those
   */

  /*
   * All recently viewed products including the current product
   * State can be removed when cookie is used
   */
  const [recentlyViewed, setRecentlyViewed] = useState([]);

  const updateRecentlyViewed = (newProduct) => {
    /*
     * Uncommend this and use these skus when api data is used
     * Otherwise we will just overwrite the existing cookie and products will not really be saved
     * So we need to add the new product sku to the skus from cookie
     * const existingRecentlyViewedSkus = getCookieValue('recentlyViewed');
     */

    const recent = removeDuplicatesFromRecentlyViewed(recentlyViewed);
    let recentWithNewProduct = [];
    /*
     * RecentlyViewed state has max 5 products
     * Always contains last (4) visited products + current product;
     * If recenltyViewed already contains 5 products we remove the first one.
     */
    if (recent.length <= 4) {
      recentWithNewProduct = [...recent, newProduct];
    } else if (recent.length === 5) {
      recent.shift();
      recentWithNewProduct = [...recent, newProduct];
    }
    setRecentlyViewed(recentWithNewProduct);
    /*
     * Set the recently viewed in a cookie to store them over a session
     * Cookie contains array with skus
     * These can be used to trigger api call to get all product data on pdp
     * Max age of 14 days. Needs to be set in seconds.
     */
    const recentlyViewedSkus = recentWithNewProduct.map(product => product.SKU);
    const maxAge = 60 * 60 * 24 * 14;
    document.cookie = `recentlyViewed=${JSON.stringify(recentlyViewedSkus)}; max-age=${maxAge}`;
  };

  const removeDuplicatesFromRecentlyViewed = (recentlyViewed) => {
    /*
     * First use slice to not change the originial array which reverse will otherwise do
     * Reverse the array to keep the last item of the duplicate product in place
     */
    const flippedRecentlyViewed = recentlyViewed.slice().reverse();

    /*
     * Filter out the duplicates
     * Create array with unique skus. If sku is already in there filter that product out of the array
     */
    const uniqueSkus = [];
    const recentlyViewedArray = flippedRecentlyViewed.slice().filter((product) => {
      const isDuplicate = uniqueSkus.includes(product.SKU);
      if (!isDuplicate) {
        uniqueSkus.push(product.SKU);
        return true;
      }
      return false;
    });

    // Reverse the order to restore original order and return the filtered array
    return recentlyViewedArray.slice().reverse();
  };

  const context = {
    previousUrl,
    setPreviousUrl,
    categories,
    setCategories,
    wishlist,
    setWishlist,
    i18n,
    recentlyViewed,
    setRecentlyViewed,
    updateRecentlyViewed,
  };

  return (
    <AppContext.Provider value={context}>
      { children }
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node,
  i18n: PropTypes.object,
  wishlist: PropTypes.array,
};

export default AppProvider;

const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
};

export { useApp, AppContext };
