import React,  { useContext } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../base_svg-icon/component';
import Typeset from '../../base_typeset/component';
import HeaderContext from '../HeaderContext';

import './styles.scss';

const NotificationBar = ({ className }) => {
  const ctx = useContext(HeaderContext);
  const { title, url } = ctx.CMSData.notificationBar;

  if (!title) {
    return null;
  }

  return (
    <a href={url} className={`header-notification-bar ${className} ${!url ? 'no-link' : ''}`}>
      <div className="container">
        <Typeset scale="0" tag="span" tight>{title}</Typeset>
        {url && (<Icon name="ico-arrow-right" size={87} />)}
      </div>
    </a>
  );
};

NotificationBar.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
};

export default NotificationBar;
