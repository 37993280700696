import React from 'react';
import PropTypes from 'prop-types';

/*
 * Uses an SVG that is available in the DOM via a sprite. (appendIconSprite)
 */
const SVG = (props) => {
  const id = `#symbol-defs_svg__${props.id}`;
  return (
    <svg>
      <use xlinkHref={id}/>
    </svg>
  );
};

SVG.propTypes = {
  id: PropTypes.string,
};

export default SVG;
