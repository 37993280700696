import fetch from 'isomorphic-fetch';
import { checkFetchReturnStatus, restCall } from './fetch.js';
import Restapi from './index.js';
import { CMP_HEADER, CMP_FOOTER } from '@constants';
class CMSApi extends Restapi {
  constructor({ apiUrl } = {}) {
    super();
    this.basketId = false;
    if (apiUrl) {
      this.baseUrl = `${apiUrl}/INTERSHOP/rest/WFS/${this._siteDomain}/-/`;
    }
  }

  /**
   * Fetch product by SKU (PDP)
   * @param {string} sku
   * @returns {object} product
   */
  componentExists = ({ id = '' }) => {
    const endpoint = `${this.baseUrl}tbacontent/components?componentIds=${id}`;
    return fetch(endpoint, { method: 'HEAD' });
  };

  fetchCategoryByPath({ path = '', includeComponent = false, isTopPage = false }) {
    const pathname = path.split('/')
      .filter(Boolean)
      .join('/');
    const endpoint = `${this.baseUrl}tbacontent?categoryPath=${pathname}&isTopPage=${isTopPage}&includeComponent=${includeComponent}`;
    return restCall(endpoint,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentails: 'omit',
      })
      .then(checkFetchReturnStatus)
      .then(response => response.json())
      .catch(this.handleError);
  }

  /**
   * Fetch product by SKU (PDP)
   * @param {string} sku
   * @returns {object} product
   */
  fetchProduct({ sku = '' }) {
    const endpoint = `${this.baseUrl}products/${sku}/tba`;
    return restCall(endpoint,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentails: 'omit',
      })
      .then(checkFetchReturnStatus)
      .then(response => response.json())
      .catch(this.handleError);
  }

  /**
   * Fetch product by SKU (PDP)
   * @param {string} sku
   * @returns {object} product
   */
  fetchSearchResults({ searchTerm = '*' }) {
    const pageSize = 24;
    const endpoint = `${this.baseUrl}advfilter/getFilters?pageSize=${pageSize}&pageRequest=1&term=${searchTerm}`;
    return restCall(endpoint,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentails: 'omit',
      })
      .then(checkFetchReturnStatus)
      .then(response => response.json())
      .catch(this.handleError);
  }

  /**
   * Fetch component by component id
   * @param {string} id
   * @param {string} categoryId
   * @returns {object} product
   */
  fetchComponentById({ id = '', categoryId }) {
    let endpoint = `${this.baseUrl}tbacontent/components?componentIds=${id}`;
    if (categoryId) {
      endpoint += `&categoryId=${categoryId}`;
    }
    return restCall(endpoint,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentails: 'omit',
      })
      .then(checkFetchReturnStatus)
      .then(response => response.json())
      .catch(this.handleError);
  }

  fetchLayout = () => {
    const id = `${CMP_HEADER},${CMP_FOOTER}`;
    return this.fetchComponentById({ id });
  };

  /**
   * Fetch header data
   */
  fetchHeaderData = () => {
    const id = CMP_HEADER;
    return this.fetchComponentById({ id });
  };

  /**
   * Fetch footer data
   */
  fetchFooterData = () => {
    const id = CMP_FOOTER;
    return this.fetchComponentById({ id });
  };

  /**
   * Fetch component by component id
   * @param {string} id
   * @returns {object} product
   */
  fetchComponent({ id = '', categoryId }) {
    let endpoint = `${this.baseUrl}tbacontent/components?componentIds=${id}`;
    if (categoryId) {
      endpoint += `&categoryId=${categoryId}`;
    }
    return restCall(endpoint,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentails: 'omit',
      })
      .then(checkFetchReturnStatus)
      .then(response => response.json())
      .catch(this.handleError);
  }

  fetchCategory({ id = '' }) {
    const endpoint = `${this.baseUrl}categories/id/${id}`;
    return restCall(endpoint,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentails: 'omit',
      })
      .then(checkFetchReturnStatus)
      .then(response => response.json())
      .catch(this.handleError);
  }

  checkForRedirect({ source }) {
    const endpoint = `${this.baseUrl}url?shorturl=${source}`;
    return restCall(endpoint,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentails: 'omit',
      })
      .then(checkFetchReturnStatus)
      .then(response => response.json())
      .catch(this.handleError);
  }

  /**
   * Fetch entity by entity id
   * @param {string} id
   * @returns {object} entity
   */
  fetchEntityById({ id = '' }) {
    const endpoint = `${this.baseUrl}tbacontent/entity/${id}`;
    return restCall(endpoint,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentails: 'omit',
      })
      .then(checkFetchReturnStatus)
      .then(response => response.json())
      .catch(this.handleError);
  }


  /**
   * Fetch blogs
   * @param {number} amount
   * @param {number} offset
   * @returns {object} blogOverview
   */
  fetchBlogComponents({ amount = 1000, offset = 0 } = {}) {
    const endpoint = `${this.baseUrl}tbacontent/blog?maxLevel=1&amount=${amount}&offset=${offset}`;
    return restCall(endpoint,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentails: 'omit',
      })
      .then(checkFetchReturnStatus)
      .then(response => response.json())
      .catch(this.handleError);
  }
}

export default CMSApi;
