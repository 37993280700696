import fetch from 'isomorphic-fetch';
import { appStorage, getCookie, isBrowser, setCookie, getVersionFromString, getParametersFromUrl } from '@utils';
import { TOKEN_STORAGE, VERSION_HEADER, APP_VERSION } from '@constants';

const responseStatusCheck = response => (((response.status >= 200 && response.status < 300) || response.status === 302) && response.ok);

// token based fetch
const setToken = (response) => {
  const tokenFromResponse = response.headers.get(TOKEN_STORAGE);
  if (!!tokenFromResponse && !tokenFromResponse.includes('AuthenticationTokenInvalid')) {
    appStorage.setItem(TOKEN_STORAGE, tokenFromResponse);
  }

  return response;
};

const gettoken = () => appStorage.getItem(TOKEN_STORAGE);

/*
 * refresh the jwt token when it is expired
 * get new token from customer data
 * send new request with the updated jwt token
 */
const refreshToken = (url, requestOptions, interShopBaseUrl) => {
  // The call to intershop to get customer data needs a different method than the calls to ods
  const requestOptionsForGettingCustomer = {
    method: 'GET',
    headers: {},
  };

  // get the authentication token to send along with the api call to intershop instead of the authentication bearer for ods
  if (gettoken()) {
    requestOptionsForGettingCustomer.headers[TOKEN_STORAGE] = gettoken();
  }

  return fetch(`${interShopBaseUrl}tbacustomers/-/`, requestOptionsForGettingCustomer)
    .then(response => response.json())
    .then((user) => {
      setCookie('jwt', user.token);
      if (user.token) {
        requestOptions.headers.Authorization = `Bearer ${user.token}`;
      }
    })
    .then(() => {
      return fetch(url, requestOptions)
        .then(setToken);
    });
};

// when ods api call returns with status 400 and the body contains expired we try to refresh the token
const tryRefetch = async ({ response, url, requestOptions, interShopBaseUrl, ods }) => {
  const { status } = response || {};
  if (status === 400 && ods) {
    const jsonBody = await response.json();
    const { expired } = jsonBody;
    if (expired) {
      return refreshToken(url, requestOptions, interShopBaseUrl);
    }
  }
  return response;
};

/**
 * Check the response header, to see if it contains a version number.
 * If so, check if it is newer than the known version.
 * If so, relead the page to force the new version.
 *
 * @param {Promise} response
 * @returns
 */
const validateVersion = (response) => {
  const versionFromResponse = response.headers.get(VERSION_HEADER);
  if (versionFromResponse) {
    const currentVersion = appStorage.getItem(APP_VERSION);
    // Set version, if version has not yet been set
    if (!currentVersion) {
      appStorage.setItem(APP_VERSION, versionFromResponse);
      return response;
    }

    const actualVersion = getVersionFromString(versionFromResponse);
    const knownVersion = getVersionFromString(currentVersion);

    if (actualVersion > knownVersion) {
      appStorage.setItem(APP_VERSION, versionFromResponse);
      window.location.reload();
    }
  }

  return response;
};


const restCall = (url, options = {}, token = true) => {
  // Fetch cannot handle relative paths on the server
  if (!isBrowser && (url.indexOf('http://') !== 0 && url.indexOf('https://') !== 0)) {
    return Promise.reject('Tried to fetch relative url: ', url);
  }

  const { ods, interShopBaseUrl, ...rest } = options;
  const requestOptions = rest;

  if (ods) {
    if (!requestOptions.headers) {
      requestOptions.headers = {};
    }
    const jwt = getCookie('jwt');
    if (jwt) {
      requestOptions.headers.Authorization = `Bearer ${jwt}`;
    }
  }

  if (gettoken() && token) {
    if (!requestOptions.headers) {
      requestOptions.headers = {};
    }
    requestOptions.headers[TOKEN_STORAGE] = gettoken();
  }

  return fetch(url, requestOptions)
    .then(validateVersion)
    .then(setToken)
    .then(response => tryRefetch({ response, url, requestOptions, interShopBaseUrl, ods }));
};
// END token based fetch

const translateToJson = (response) => {
  const nativeFetchThenable = response.json();
  /*
   * In MS Edge, fetch().json() returns a non-Promise thenable, which cannot be polyfilled with .finally().
   * Therefore, we wrap the thenable by resolving a new (polyfilled) Promise with it,
   * so we can call .finally() on the Promise:
   */
  const wrappedFetchPromise = Promise.resolve(nativeFetchThenable);

  return wrappedFetchPromise;
};

const checkFetchReturnStatus = (response, ignoreAuthError) => {
  let type;
  let msg;
  let faultyProduct;
  if (response.headers.get('error-key') || response.headers.get('error-type')) {
    type = response.headers.get('error-key') || response.headers.get('error-type');
    msg = response.headers.get('error-message');
    faultyProduct = response.headers.get('error-not-accessible-product');
  } else {
    msg = response.statusText;
    type = 'communication';
  }
  if (response.headers.get('refresh-required') === 'true') {
    const error = new Error(msg);
    error.type = 'refresh';
    error.response = response;
    error.refresh = response.headers.get('refresh-required');
    throw error;
  } else if (response.status === 401) {
    // unauthorized

    // Dispatch event to update the UI, unless ignoreAuthError param is passed (reset password)
    if (isBrowser && !ignoreAuthError) {
      const event = new CustomEvent('authentication-error');
      window.dispatchEvent(event);
    }

    const error = new Error(msg);
    error.type = 'unauthorized';
    error.response = response;
    throw error;
  } else if (!responseStatusCheck(response)) {
    const error = new Error(msg);
    error.response = response;
    error.type = type;
    error.faultyProduct = faultyProduct;
    throw error;
  }
  return response;
};

/**
 * Encode an object in an urlencoded way so it can be passed to fetch
 *
 * @param sourceObj
 * @returns {string}
 */
const formBodyUrlEncode = (sourceObj) => {
  const destObj = {};
  flattenObject(sourceObj, destObj);

  removeUndefinedItemsFromObjects(destObj);

  return Object.keys(destObj)
    .map((key) => {
      return `${encodeURIComponent(key)  }=${  encodeURIComponent(destObj[key])}`;
    })
    .join('&');
};

/**
 * Generate options to pass to a fetch call
 *
 * @param sourceObj
 * @param method
 * @returns {Object}
 */
const generateOptionsUrlEncoded = ({ sourceObj, method = 'post' }) => {
  return ({
    credentials: 'include',
    method,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: sourceObj ? formBodyUrlEncode(sourceObj) : null,
  });
};

const post = ({ path = location.href, params, method = 'post' }) => {
  const form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);

  // Params can be set in url or as a parmater
  const urlParams = getParametersFromUrl(path);
  const allParams = (method === 'post') ? params : {
    ...urlParams,
    ...params,
  };

  for (const key in allParams) {
    const hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', key);
    hiddenField.setAttribute('value', allParams[key]);

    form.appendChild(hiddenField);
  }

  document.body.appendChild(form);
  form.submit();
};

export {
  checkFetchReturnStatus,
  formBodyUrlEncode,
  generateOptionsUrlEncoded,
  post,
  responseStatusCheck,
  restCall,
  translateToJson,
};


// ** helper functions below ** //


function flattenObject(srcObj, destObj) {
  Object.keys(srcObj)
    .forEach((srcKey) => {
      if (srcObj[srcKey] && srcObj[srcKey].constructor === Object) {
        flattenObject(srcObj[srcKey], destObj);
      } else {
        destObj[srcKey] = srcObj[srcKey];
      }
    });
  return this;
}

function removeUndefinedItemsFromObjects(obj) {
  Object.keys(obj)
    .forEach((objKey) => {
      if (obj[objKey] === undefined) {
        delete obj[objKey];
      }
    });
  return this;
}
