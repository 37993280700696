import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Col } from '../../util_grid/grid.js';
import Icon from '../../base_svg-icon/component';
import { Button } from 'audamatic-ui';

import './styles/styles.scss';

class Sidepanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasScrolled: false,
    };
    this.contentRef = createRef();
  }

  componentDidMount() {
    this.contentRef.current.addEventListener('scroll', this.checkScrollPosition);
  }

  componentWillUnmount() {
    this.contentRef.current.removeEventListener('scroll', this.checkScrollPosition);
  }

  checkScrollPosition = () => {
    if (this.state.hasScrolled && this.contentRef.current.scrollTop === 0) {
      this.setState({ hasScrolled: false });
    }
    if (!this.state.hasScrolled && this.contentRef.current.scrollTop > 0) {
      this.setState({ hasScrolled: true });
    }
  };

  get toggled() {
    return this.props.toggled || false;
  }

  render() {
    const { className = '', title = '', location = '', component = null, style = null, toggled = false } = this.props;
    const { hasScrolled } = this.state;
    const CompNotRenderedJet = this.props.componentNotRendered;
    return (
      <Col
        xs={12}
        id="page-content-wrapper"
        className={`nooverflow ${toggled ? 'sidepanel-open' : ''}`}
      >
        <div className={`sidepanel ${location}`}>
          <div className={`sidepanel-content ${className}`} style={style}>
            <div className={`sidepanel-header ${hasScrolled ? 'scrolled' : ''}`}>
              <h2>
                <span>{title}</span>
                <Button
                  variant="link"
                  size="large"
                  iconLeft={<Icon name="ico-error"/>}
                  onClick={this.props.closeModal}
                  data-test-id="closeSidepanel"
                />
              </h2>
            </div>
            <div ref={this.contentRef} className="sidepanel-component">
              {!!CompNotRenderedJet ? (
                <CompNotRenderedJet {...this.props}> </CompNotRenderedJet>
              ) : (
                component
              )}
            </div>
          </div>
          <span onClick={this.props.closeModal} className="sidepanel-shade"/>
        </div>
      </Col>
    );
  }
}


Sidepanel.propTypes = {
  /**
   * Boolean if the sidepanel is shown or not
   */
  toggled: PropTypes.bool,
  /**
   * Give the location where the sidepanel needs to be rendered,
   * default set to 'left'
   */
  location: PropTypes.string,
  /**
   * Title that needs to be rendered inside the sidepanel
   */
  title: PropTypes.string,
  /**
   * Component that needs to be rendered inside the sidepanel
   * e.g. <Dummy title='Test title' />
   */
  component: PropTypes.object,
  /**
   * Component that needs to be rendered inside the sidepanel
   * But which is not redenred jet.
   *
   * e.g. Dummy
   */
  componentNotRendered: PropTypes.any,
  closeModal: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
};


export default Sidepanel;
