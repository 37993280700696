import React from 'react';
import PropTypes from 'prop-types';
import TypeSet from '../../base_typeset/component';
import Link from '../../base_link/component';

import './styles.scss';

const LinkList = ({ title, url, onClickEvent = () => {}, links = [], collapsible, expanded, last }) => {
  const classNames = [
    'menu-list',
    expanded && 'expanded',
    last && 'last',
    collapsible && 'collapsible',
  ].filter(Boolean).join(' ');

  return (
    <div className={classNames}>
      <ul>

        {(collapsible && url) && (
          <li className="extra-mobile-link">
            <Link to={url} onClick={onClickEvent}>
              <TypeSet scale="0" tight>{title}</TypeSet>
            </Link>
          </li>
        )}

        {links.map((link, index) => (
          <li key={index}>
            <Link to={link.url} onClick={onClickEvent}>
              <TypeSet scale="0" tight>{link.title}</TypeSet>
            </Link>
          </li>
        ))}

      </ul>
    </div>
  );
};

LinkList.propTypes = {
  onClickEvent: PropTypes.func,
  links: PropTypes.array,
  title: PropTypes.string,
  url: PropTypes.string,
  collapsible: PropTypes.bool,
  last: PropTypes.bool,
  expanded: PropTypes.bool,
};

export default LinkList;
