class ContentModel {
  constructor(props = []) {
    const content = props.length ? props[0] : {};
    Object.assign(this, content);

    // Components. If components include a dynamicHeader, exclude it to place it at the top of the page.
    let components = content.components || [];
    const customComponent = components.find(component => component.layout === 'custom-layout') || {};
    const dynamicPageHeader = customComponent?.components?.find(component => component['component-type'] === 'DynamicPageHeader');
    if (dynamicPageHeader) {
      const filteredComponents = components.filter(component => component.layout !== 'custom-layout');

      components = filteredComponents;
      this.dynamicHeaderComponent = {
        layout: 'custom-layout',
        stick: true,
        components: [
          { ...dynamicPageHeader },
          { 'component-type': 'breadcrumb', items: [] },
        ],
      };
    }
    this.components = components;
  }
}

export default ContentModel;
