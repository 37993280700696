const textVariables = {
  channelName: 'ReadShop',
  phoneNumber: '0881338432',
  phoneNumberDisplay: '088 - 133 84 32',
  facebookProfileUrl: 'https://www.facebook.com/TheReadShop',
  twitterProfileUrl: 'https://twitter.com/thereadshop',
  whatsappUrl: '',
  kiyohUrl: 'https://www.kiyoh.com/reviews/1066077/the_read_shop',
  linkedinUrl: '',
  instagramUrl: '',

  // Customer service urls
  customerServiceUrl: 'https://klantenservice.readshop.nl',
  csLinkToB2B: 'https://klantenservice.readshop.nl',
  csEmailAddress: 'helpdesk@readshop.nl',
  csLinkToMembership: 'https://klantenservice.readshop.nl',
  csLinkToContact: 'https://klantenservice.readshop.nl',
  csLinkToEbooks: 'https://klantenservice.readshop.nl/search/ebooks',
  csLinkToFaq: 'https://klantenservice.readshop.nl/topic/vxE7e48dXnbrjRz9g',
};

module.exports = textVariables;
