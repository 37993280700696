import { BRAND } from '@config';
import { log } from '@utils';
import { BUILD_VERSION } from '@constants';

// Fancy log for version number...
const logBrand = () => {
  const logStyle = 'font-size: 20px; font-weight: 500; font-family: Rockwell,Palatino,Big Caslon,Georgia,serif; margin: 12px 0;';
  switch (BRAND) {
  case 'bruna':
    log('%cbruna' + `%c v${BUILD_VERSION}`,
      `color: #ed3325; ${logStyle}; font-family: sans-serif; font-weight: bold;`, // bruna
      'color: #777');
    break;
  case 'readshop':
    log('%cREAD' + '\n' + '%cSHOP' + `%c v${BUILD_VERSION}`,
      `margin: 12px 0 0 20px; padding: 3px 0 0; border-top: 1px solid #00aa13; color: #00aa13; font-size: 20px; font-weight: bold; font-family: sans-serif;`, // READ
      `margin: 0 0 12px; padding: 0 0 1px; border-bottom: 1px solid #00aa13; color: #00aa13; font-size: 20px; font-weight: 200; font-family:sans-serif;`, // SHOP
      'color: #777');
    break;
  default:
    log('%cAudax' + `%c v${BUILD_VERSION}`,
      `color: #222; ${logStyle}; font-family: sans-serif;`, // Audax
      'color: #777');
    break;
  }
};

export default logBrand;
