import React from 'react';
import PropTypes from 'prop-types';
import { getDevice } from '@utils';
import { Badge } from 'audamatic-ui';
import Icon from '../../../base_svg-icon/component';
import Link from '../../../base_link/component';

import './styles.scss';


const Action = (props) => {
  const {
    icon,
    title,
    preTitle,
    url,
    badgeNumber,
    children,
    onClick,
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    hasChildren,
    showIcon = true,
    hideOnMobile,
    testId = 'masthead-action',
  } = props;

  const { isMobile, isTablet } = getDevice();
  const handleOnClick = (e) => {
    if (onClick || !url) {
      e.preventDefault();
      onClick && onClick();
    }
  };

  return (
    <div
      className={`masthead-link ${hideOnMobile ? 'hide-for-mobile' : ''}`}
      onMouseEnter={isMobile || isTablet ? () => (false) : onMouseEnter}
      onMouseLeave={isMobile || isTablet ? () => (false) : onMouseLeave}
      data-test-id={testId}
    >
      <Link onClick={handleOnClick} to={url} rel="nofollow">
        <div
          className={`masthead-action ${showIcon ? 'has-desktop-icon' : ''} ${!!badgeNumber ? 'highlighted' : ''}`}
        >
          {icon && <Icon name={icon} size={150}/>}
          <div className="labels">
            {preTitle && (
              <div className="action-pre-title">{preTitle}</div>
            )}
            <div className="action-title">
              <span>{title}</span>
              {hasChildren && <Icon name="ico-arrow-down" size={87}/>}
            </div>
          </div>
          {(badgeNumber > 0) && (
            <div className="badge-container">
              <Badge number={badgeNumber} size="tiny"/>
            </div>
          )}
        </div>
      </Link>

      {children && (
        <div className="triggered-component" onClick={handleOnClick}>{children}</div>
      )}
    </div>

  );
};


Action.propTypes = {
  store: PropTypes.object,
  icon: PropTypes.string,
  showIcon: PropTypes.bool, // on desktop only
  title: PropTypes.node,
  preTitle: PropTypes.any,
  badgeNumber: PropTypes.number,
  url: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  hasChildren: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
  testId: PropTypes.string,
};

export default Action;
